import type { ErrorEvent } from '@sentry/types';
import type { AxiosError } from 'axios';

import { SentrySibling } from '../plugins/sentry';

import { logWarn, logErr, logInfo } from './logger';

import { RequirementsEnum, ResponseErrorTypeEnum } from '@/enums';
import { useRequirementsHelper, useToasts } from '@/helpers';
import { useAppStore, useMessengerStore, useNetworkStore } from '@/store';
import type { ResponseErrorModel } from '@/types';

export type IErrors = {
  /**
   * Current error object
   */
  currentError: CurrentErrorEvent;
  handleError: (
    showUser: boolean,
    error: AxiosError<ResponseErrorModel> | undefined | any,
    message?: string
  ) => ResponseErrorTypeEnum | string;
  resetCurrentError: () => void;
  setSentryEvent: (event: ErrorEvent) => Promise<void>;
  handleSuccessfulSubmit: () => void;
};

export type CurrentErrorEvent = {
  id: string;
  message: string;
  serverDetails: ResponseErrorModel | null;
  sentryDetails: ErrorEvent | null;
  showUser?: boolean;
};

let instance: IErrors | null = null;
export function useErrors(): IErrors {
  if (instance) return instance;

  /**
   * --------------------------------------------------------------------------------
   * Stores
   * --------------------------------------------------------------------------------
   */

  const networkStore = useNetworkStore();
  const appStore = useAppStore();
  const messengerStore = useMessengerStore();

  /**
   * --------------------------------------------------------------------------------
   * Helpers
   * --------------------------------------------------------------------------------
   */

  const { showSonnerToast } = useToasts();

  /**
   * --------------------------------------------------------------------------------
   * Variables
   * --------------------------------------------------------------------------------
   */

  let currentError: CurrentErrorEvent = {
    id: '',
    message: '',
    serverDetails: null,
    sentryDetails: null,
    showUser: false,
  };

  /**
   * --------------------------------------------------------------------------------
   * Private methods
   * --------------------------------------------------------------------------------
   */

  // ...private methods

  /**
   * --------------------------------------------------------------------------------
   * Error Handlers
   * --------------------------------------------------------------------------------
   */

  const _handleBadRequest = (error: CurrentErrorEvent): void => {
    logErr('Error type is BadRequest or ApiNotValidModel', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleUnauthorized = (error: CurrentErrorEvent): void => {
    logErr('Error type is Unauthorized', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleAccessDenied = (error: CurrentErrorEvent): void => {
    networkStore.isNetworkAvailable = false;
    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
    // TODO: Handle access denial, possibly log out the user.
  };

  const _handleInvalidAcceptPolicy = async (error: CurrentErrorEvent): Promise<void> => {
    logErr('Error type is InvalidAcceptPolicy', error);

    appStore.isLoading = false;
    networkStore.isLoading = false;
    messengerStore.isLoading = false;
    await useRequirementsHelper().check(RequirementsEnum.UsageRules);
  };

  /**
   * !: Temporary disabled
  const _handleNotFound = (error: CurrentErrorEvent): void => {
    logErr('Error type is NotFound', error);

    showSonnerToast(error.id, error.message, false, error.sentryDetails, error.serverDetails);
  };
  */

  const _handleRequestTimeout = (error: CurrentErrorEvent): void => {
    logErr('Error type is RequestTimeout', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleConflict = (error: CurrentErrorEvent): void => {
    logErr('Error type is Conflict', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleInternalServerError = (error: CurrentErrorEvent): void => {
    logErr('Error type is InternalServerError', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleBadGateway = (error: CurrentErrorEvent): void => {
    logErr('Error type is BadGateway', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleServiceUnavailable = (error: CurrentErrorEvent): void => {
    logErr('Error type is ServiceUnavailable', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleGatewayTimeout = (error: CurrentErrorEvent): void => {
    logErr('Error type is GatewayTimeout', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleUnknownError = (error: CurrentErrorEvent): void => {
    logErr('Error type is UnknownError', error);

    const errorType = (error.serverDetails?.errorType as ResponseErrorTypeEnum) || ResponseErrorTypeEnum.UnknownError;
    let message: string = error.message;
    if (errorType === ResponseErrorTypeEnum.UnknownError && !message && error.sentryDetails) {
      const sentryMsg = error.sentryDetails?.message as any;
      if (!sentryMsg) {
        message = 'Unknown error';
      }

      message = `
          status: ${sentryMsg.status ?? 'Unknown status'}
          statusText: ${sentryMsg.statusText ?? 'Unknown status text'}
          event_id: ${error.sentryDetails?.event_id ?? 'Unknown event id'}
          time: ${sentryMsg.time ?? new Date().toISOString()}
        `;
    }
    showSonnerToast(
      message || 'Internal error. Please try again later or contact support.',
      false,
      error.id,
      error.sentryDetails,
      error.serverDetails
    );
  };

  const _handleTurnedOffTypes = (error: CurrentErrorEvent): void => {
    logWarn(`This type of error is currently turned off: ${error.serverDetails?.errorType}`);
  };

  const _errorHandlers: Record<ResponseErrorTypeEnum, (error: CurrentErrorEvent) => Promise<void> | void> = {
    [ResponseErrorTypeEnum.BadRequest]: _handleBadRequest,
    [ResponseErrorTypeEnum.ApiNotValidModel]: _handleBadRequest,
    [ResponseErrorTypeEnum.Unauthorized]: _handleUnauthorized,
    [ResponseErrorTypeEnum.InvalidAcceptPolicy]: _handleInvalidAcceptPolicy,
    [ResponseErrorTypeEnum.AccessDenied]: _handleTurnedOffTypes, //! DISABLED
    [ResponseErrorTypeEnum.AccessDeniedForUser]: _handleTurnedOffTypes, //! DISABLED
    [ResponseErrorTypeEnum.Forbidden]: _handleTurnedOffTypes, //! DISABLED
    [ResponseErrorTypeEnum.NotFound]: _handleTurnedOffTypes, //! DISABLED
    [ResponseErrorTypeEnum.MethodNotAllowed]: _handleAccessDenied,
    [ResponseErrorTypeEnum.RequestTimeout]: _handleRequestTimeout,
    [ResponseErrorTypeEnum.Conflict]: _handleConflict,
    [ResponseErrorTypeEnum.InternalServerError]: _handleInternalServerError,
    [ResponseErrorTypeEnum.BadGateway]: _handleBadGateway,
    [ResponseErrorTypeEnum.ServiceUnavailable]: _handleServiceUnavailable,
    [ResponseErrorTypeEnum.GatewayTimeout]: _handleGatewayTimeout,
    [ResponseErrorTypeEnum.UnknownError]: _handleUnknownError,

    //TODO: Additional server unavailable error types
    [ResponseErrorTypeEnum.ERR_FR_TOO_MANY_REDIRECTS]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_BAD_OPTION_VALUE]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_BAD_OPTION]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_NETWORK]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_DEPRECATED]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_BAD_RESPONSE]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_BAD_REQUEST]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_NOT_SUPPORT]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_INVALID_URL]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_CANCELED]: _handleUnknownError,
    [ResponseErrorTypeEnum.ECONNABORTED]: _handleUnknownError,
    [ResponseErrorTypeEnum.ETIMEDOUT]: _handleUnknownError,
  };

  const _showErrorEvent = async (error: CurrentErrorEvent): Promise<void> => {
    const errorType = (error.serverDetails?.errorType as ResponseErrorTypeEnum) || ResponseErrorTypeEnum.UnknownError;
    const handler = _errorHandlers[errorType];

    if (handler) {
      await handler(error);
    } else {
      logErr('No handler for error type:', errorType);
      await _handleUnknownError(error);
    }
  };

  /**
   * --------------------------------------------------------------------------------
   * Public methods
   * --------------------------------------------------------------------------------
   */

  const handleError = (
    showUser: boolean,
    error: AxiosError<ResponseErrorModel | any> | undefined,
    message?: string
  ): ResponseErrorTypeEnum | string => {
    const errorMessages = error?.response?.data?.errorMessages;
    const errorMessage = errorMessages?.[0]?.errors?.[0] || message || error?.cause?.message || '';

    const statusCode =
      error?.response?.data?.statusCode || //NOTE: Our custom prop - statusCode from ResponseErrorModel
      error?.response?.status || //NOTE: Axios error status
      error?.code || //NOTE: If no response, then Axios error code
      'unknown statusCode';

    const traceId =
      error?.response?.data?.traceId || //NOTE: Our custom prop - traceId from ResponseErrorModel
      error?.response?.config?.url || //NOTE: Axios error config
      error?.config?.url || //NOTE: If no response, then Axios error config
      'unknown traceId';

    const errorType =
      error?.response?.data?.errorType || //NOTE: Our custom prop - errorType from ResponseErrorModel
      error?.response?.statusText || //NOTE: Axios error statusText
      error?.code || //NOTE: If no response, then Axios error code
      ResponseErrorTypeEnum.UnknownError;

    currentError = {
      id: '',
      message: errorMessage,
      serverDetails: {
        statusCode,
        traceId,
        errorType,
        errorMessages: errorMessages || [],
      },
      sentryDetails: null,
      showUser,
    };
    // logErr('currentError', currentError); //! DEBUG

    SentrySibling.captureException(error);
    return errorType;
  };

  const resetCurrentError = (): void => {
    logInfo('Resetting currentError...'); //! DEBUG

    Object.assign(currentError, {
      id: '',
      message: '',
      serverDetails: null,
      sentryDetails: null,
      showUser: false,
    });
  };

  const setSentryEvent = async (event: ErrorEvent): Promise<void> => {
    currentError.id = event?.event_id || 'unknown event_id';
    currentError.sentryDetails = event;

    if (currentError.showUser) {
      await _showErrorEvent(currentError);
    }
  };

  const handleSuccessfulSubmit = (): void => {
    showSonnerToast(
      'We received your report and already working on it!', //TODO: Translate message t('apiErrors.reportReceived')
      true,
      currentError.id,
      currentError.sentryDetails,
      currentError.serverDetails
    );
  };

  instance = {
    currentError,
    handleError,
    resetCurrentError,
    setSentryEvent,
    handleSuccessfulSubmit,
  };

  return instance;
}
