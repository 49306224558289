import { alertController, modalController } from '@ionic/vue';
import type { HubConnection } from '@microsoft/signalr';
import type {
  AudioTrack,
  LocalParticipant,
  LocalTrack,
  LocalTrackPublication,
  Participant,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  Track,
  VideoTrack,
} from 'twilio-video';
import { connect, createLocalAudioTrack, createLocalVideoTrack } from 'twilio-video';
import type { Ref } from 'vue';
import { reactive, ref } from 'vue';

import { logInfo } from './logger';

import { MeetStatusEnum, PermissionTypes } from '@/enums';
import { useToasts } from '@/helpers';
import { isNativeMobile } from '@/helpers/helper';
import { componentMeetCallModal, componentMeetRoom } from '@/helpers/modalComponents';
import { useI18n } from '@/i18n';
import { useAppStore, useMeetStore, useMessengerStore } from '@/store';
import type { CallResultSignalRModel, CallSignalRModel, MessageChainEntity } from '@/types';

interface IUseMeet {
  registerEventsVideo(connection: HubConnection | undefined): void;
  requestPermissions(): Promise<boolean>;
  showDeviceBlockedAlert(type: PermissionTypes): Promise<void>;
  connectToRoom(withVideo: boolean, roomId: string): Promise<boolean>;
  connectToDevices(roomId: string, type: PermissionTypes): Promise<void>;
  disconnectFromRoom(roomId?: string): Promise<void>;
  changeAudio(isEnabled: boolean, roomId: string): Promise<void>;
  changeVideo(isEnabled: boolean, roomId: string): Promise<void>;
  showCallModal(chain: MessageChainEntity | null): Promise<MeetStatusEnum>;
  tracks: LocalTrack[];
  localMedias: HTMLMediaElement[];
  remoteMedias: Map<number, HTMLMediaElement[]>;
  participantCount: Ref<number>;
}

let instance: IUseMeet | null = null;

export function useMeet(): IUseMeet {
  if (instance) {
    return instance;
  }

  const appStore = useAppStore();
  const meetStore = useMeetStore();
  const callAudio = new Audio(appStore.getAppCallSoundPath);
  callAudio.loop = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let dominantSpeaker: RemoteParticipant;
  let localMedias = reactive<HTMLMediaElement[]>([]);
  let activeRoom: Room | null = null;
  let tracks: LocalTrack[] = [];
  let localUserId = 0;

  const { t } = useI18n();
  const { showSonnerToast } = useToasts();

  const participantCount = ref<number>(0);
  const remoteMedias = reactive<Map<number, HTMLMediaElement[]>>(new Map<number, HTMLMediaElement[]>());

  const showDeviceBlockedAlert = async (type: PermissionTypes): Promise<void> => {
    const existingAlert = await alertController.getTop();
    if (
      (type === PermissionTypes.Audio && existingAlert && existingAlert.id === 'audio-blocked') ||
      (type === PermissionTypes.Camera && existingAlert && existingAlert.id === 'video-blocked')
    ) {
      return;
    }
    const alert = await alertController.create({
      id: type === PermissionTypes.Camera ? 'video-blocked' : 'audio-blocked',
      header:
        type === PermissionTypes.Camera ? t('meet.meetRoom.videoBlockedTitle') : t('meet.meetRoom.audioBlockedTitle'),
      subHeader:
        type === PermissionTypes.Camera
          ? t('meet.meetRoom.videoBlockedMessage')
          : t('meet.meetRoom.audioBlockedMessage'),
      buttons: [
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          role: 'confirm',
        },
      ],
    });
    await alert.present();
  };

  const _isAttachable = (track: Track): track is AudioTrack | VideoTrack => {
    return !!track && ((track as AudioTrack).attach !== undefined || (track as VideoTrack).attach !== undefined);
  };

  const _isDetachable = (track: Track): track is AudioTrack | VideoTrack => {
    return !!track && ((track as AudioTrack).detach !== undefined || (track as VideoTrack).detach !== undefined);
  };

  const _createAudioTrack = async (tracks: LocalTrack[]): Promise<void> => {
    try {
      const audioTrack = await createLocalAudioTrack();
      tracks.push(audioTrack);
    } catch (error: any) {
      meetStore.addError('audioTrack', 'NotAllowedError');
      console.error(`Unable to audio: ${error}`);
    }
  };

  const _createVideoTrack = async (tracks: LocalTrack[]): Promise<void> => {
    try {
      const videoTrack = await createLocalVideoTrack({
        frameRate: 24,
        height: 720,
        width: 1280,
      });
      tracks.push(videoTrack);
    } catch (error: any) {
      meetStore.addError('videoTrack', 'NotFoundError');
      console.error(`Unable to video: ${error}`);
    }
  };

  const changeAudio = async (isEnabled: boolean, roomId: string): Promise<void> => {
    const id = +(activeRoom?.localParticipant?.identity || 0);
    activeRoom?.localParticipant.audioTracks.forEach((publication) => publication.track.enable(isEnabled));
    meetStore.userVoiceMute(id, isEnabled, roomId);
  };

  const changeVideo = async (isEnabled: boolean, roomId: string): Promise<void> => {
    const id = +(activeRoom?.localParticipant?.identity || 0);
    activeRoom?.localParticipant.videoTracks.forEach((publication) => publication.track.enable(isEnabled));
    meetStore.userVideoMute(id, isEnabled, roomId);
  };

  const _createMediaTracks = async () => {
    meetStore.clearErrors();

    if (!tracks.some((track) => track.kind === 'audio')) {
      await _createAudioTrack(tracks);
    }
    if (!tracks.some((track) => track.kind === 'video')) {
      await _createVideoTrack(tracks);
    }
  };

  const requestPermissions = async (): Promise<boolean> => {
    await _createMediaTracks();
    if (!tracks.some((track) => track.kind === 'audio')) {
      await showDeviceBlockedAlert(PermissionTypes.Audio);
    }
    if (!tracks.some((track) => track.kind === 'video')) {
      await showDeviceBlockedAlert(PermissionTypes.Camera);
    }
    return tracks.length > 0;
  };

  const _attachRemoteTrack = (identity: number, track: RemoteTrack) => {
    if (!_isAttachable(track)) {
      console.error('Track is not attachable'); //! DEBUG
      return;
    }

    track.on('enabled', (item: RemoteTrack) => {
      if (item.kind === 'audio') {
        meetStore.userVoiceMute(identity, true);
      }
      if (item.kind === 'video') {
        meetStore.userVideoMute(identity, true);
      }
    });
    track.on('disabled', (item: RemoteTrack) => {
      if (item.kind === 'audio') {
        meetStore.userVoiceMute(identity, false);
      }
      if (item.kind === 'video') {
        meetStore.userVideoMute(identity, false);
      }
    });

    const element = track.attach();
    element.id = track.sid;
    const userTracks = remoteMedias.get(identity);
    if (userTracks !== undefined) {
      userTracks.push(element);
      remoteMedias.set(identity, userTracks);
    } else {
      remoteMedias.set(identity, [element]);
    }

    if (track.kind === 'audio') {
      meetStore.userVoiceMute(identity, track.isEnabled);
    }
    if (track.kind === 'video') {
      meetStore.userVideoMute(identity, track.isEnabled);
    }
  };

  const _detachRemoteTrack = (identity: number, track: RemoteTrack) => {
    if (!_isDetachable(track)) {
      console.error('Track is not detachable'); //! DEBUG
      return;
    }
    track.detach().forEach((el) => {
      el.remove();
    });
    const userTracks = remoteMedias.get(identity);

    if (userTracks !== undefined) {
      remoteMedias.delete(identity);
    }
  };

  const _subscribeRemote = (identity: number, publication: RemoteTrackPublication | any) => {
    if (!publication?.on) {
      console.error('Publication is not defined'); //! DEBUG
      return;
    }

    publication.on('subscribed', (track: RemoteTrack) => {
      _attachRemoteTrack(identity, track);
    });
    publication.on('unsubscribed', (track: RemoteTrack) => _detachRemoteTrack(identity, track));
  };

  const _registerRemoteEvents = (participant: RemoteParticipant) => {
    if (!participant) {
      console.error('Remote participant is not defined'); //! DEBUG
      return;
    }

    participant.tracks.forEach((publication: RemoteTrackPublication) =>
      _subscribeRemote(+participant.identity, publication)
    );

    participant.on('trackPublished', (publication: RemoteTrackPublication) =>
      _subscribeRemote(+participant.identity, publication)
    );

    participant.on('trackUnpublished', (publication?: RemoteTrackPublication) => {
      if (publication?.track) {
        _detachRemoteTrack(+participant.identity, publication.track);
      }
    });
  };

  const _initialize = (items: Map<Participant.SID, RemoteParticipant>) => {
    if (items) {
      items.forEach((participant) => _registerRemoteEvents(participant));
    }
  };

  const _attachLocalTrack = (track: LocalTrack) => {
    if (!_isAttachable(track)) {
      console.error('Track is not attachable'); //! DEBUG
      return;
    }

    track.on('enabled', (item: RemoteTrack) => {
      if (item.kind === 'audio') {
        meetStore.userVoiceMute(localUserId, true);
      }
      if (item.kind === 'video') {
        meetStore.userVideoMute(localUserId, true);
      }
    });
    track.on('disabled', (item: RemoteTrack) => {
      if (item.kind === 'audio') {
        meetStore.userVoiceMute(localUserId, false);
      }
      if (item.kind === 'video') {
        meetStore.userVideoMute(localUserId, false);
      }
    });

    const element = track.attach();
    element.id = track.id;
    localMedias.push(element);

    if (track.kind === 'audio') {
      meetStore.userVoiceMute(localUserId, track.isEnabled);
    }
    if (track.kind === 'video') {
      meetStore.userVideoMute(localUserId, track.isEnabled);
    }
  };

  const _detachLocalTrack = (track: LocalTrack) => {
    if (!_isDetachable(track)) {
      console.error('Track is not detachable'); //! DEBUG
      return;
    }

    track.stop();
    track.detach().forEach((el) => {
      el.remove();
    });
  };

  const _subscribeLocal = (publication: LocalTrackPublication | any) => {
    if (!publication?.on) {
      console.error('Publication is not defined'); //! DEBUG
      return;
    }

    _attachLocalTrack(publication.track);
    publication.on('subscribed', (track: LocalTrack) => _attachLocalTrack(track));
    publication.on('unsubscribed', (track: LocalTrack) => _detachLocalTrack(track));
  };

  const _registerLocalEvents = (participant: LocalParticipant) => {
    if (!participant) {
      console.error('Local participant is not defined'); //! DEBUG
      return;
    }

    participant.tracks.forEach((publication: LocalTrackPublication) => _subscribeLocal(publication));

    participant.on('trackPublished', (publication: LocalTrackPublication) => _subscribeLocal(publication));
    participant.on('trackUnpublished', (publication?: LocalTrackPublication) => {
      if (publication?.track) {
        _detachLocalTrack(publication.track);
      }
    });
  };

  const _add = async (participant: RemoteParticipant, roomId: string) => {
    if (!participant) {
      console.error('Remote participant is not defined'); //! DEBUG
      return;
    }
    const user = meetStore.getUser(+participant.identity, roomId);
    logInfo(`_add: ${user}`); //! DEBUG
    _registerRemoteEvents(participant);

    participantCount.value += 1;

    showSonnerToast(t('meet.meetRoom.userConnected', { user: user?.fullName }), true);
  };

  const _remove = async (participant: RemoteParticipant, roomId: string) => {
    if (participant) {
      const user = meetStore.getUser(+participant.identity, roomId);
      logInfo(`_remove: ${user}`); //! DEBUG

      participantCount.value -= 1;

      showSonnerToast(t('meet.meetRoom.userDisconnected', { user: user?.fullName }), true);
    }
  };

  const _loudest = (participant: RemoteParticipant) => {
    dominantSpeaker = participant;
  };

  const _registerRoomEvents = (roomId: string) => {
    if (activeRoom !== null) {
      activeRoom
        .on('disconnected', (room: Room) =>
          room.localParticipant.tracks.forEach((publication: LocalTrackPublication) =>
            _detachLocalTrack(publication.track)
          )
        )
        .on('participantConnected', async (participant: RemoteParticipant) => {
          await _add(participant, roomId);
        })
        .on('participantDisconnected', async (participant: RemoteParticipant) => {
          await _remove(participant, roomId);
        })
        // Not in use at the moment - 02.06.2024
        .on('dominantSpeakerChanged', (dominantSpeaker: RemoteParticipant) => _loudest(dominantSpeaker));
    }
  };

  const connectToRoom = async (withVideo: boolean, roomId: string): Promise<boolean> => {
    const appStore = useAppStore();
    localUserId = appStore.userId ?? 0;

    await requestPermissions();

    try {
      const currentCallToken = meetStore.callToken(roomId);

      activeRoom = await connect(currentCallToken, {
        name: roomId,
        tracks,
        dominantSpeaker: true,
      });
    } catch (error: any) {
      console.error(`Unable to connect to Room: ${error}`);
    } finally {
      if (activeRoom) {
        await changeAudio(false, roomId);
        await changeVideo(withVideo, roomId);
        _initialize(activeRoom.participants);

        _registerLocalEvents(activeRoom.localParticipant);

        _registerRoomEvents(roomId);

        logInfo(`localMedias: ${localMedias}`); //! DEBUG
        logInfo(`localTracks: ${tracks}`); //! DEBUG
        logInfo(`remoteMedias: ${remoteMedias}`); //! DEBUG

        participantCount.value = activeRoom.participants.size;
      }
    }

    return activeRoom !== null;
  };

  const connectToDevices = async (roomId: string, type: PermissionTypes): Promise<void> => {
    await _createMediaTracks();
    if (type === PermissionTypes.Audio && !tracks.some((track) => track.kind === 'audio')) {
      await showDeviceBlockedAlert(PermissionTypes.Audio);
      return;
    }
    if (type === PermissionTypes.Camera && !tracks.some((track) => track.kind === 'video')) {
      await showDeviceBlockedAlert(PermissionTypes.Camera);
      return;
    }
    if (activeRoom) {
      await activeRoom.localParticipant.publishTracks(tracks);
      _registerLocalEvents(activeRoom.localParticipant);
    }
  };

  const disconnectFromRoom = async (roomId?: string): Promise<void> => {
    const existingModal = await modalController.getTop();
    if (existingModal?.id === 'callUser') {
      await modalController.dismiss(null, 'end', 'callUser');
    }

    localMedias = [];

    if (remoteMedias.size > 0) {
      remoteMedias.clear();
    }

    if (activeRoom !== null) {
      participantCount.value = 0;
      activeRoom.disconnect();
      activeRoom = null;
    }

    const currentRoomId = roomId || meetStore.getCurrentRoomId;

    const chainId = meetStore.getChainIdByRoomId(currentRoomId);

    meetStore.$patch({
      isCallFromPage: false,
      withVideo: false,
    });

    const activeParticipants = chainId ? await meetStore.getActiveParticipants(chainId) : undefined;

    /** комнату можно удалить если в ней не осталось участников в групповом звонке
     * либо если завершен звонок тет-а-тет */
    if (activeParticipants?.length === 0 || !meetStore.isGroupCall()) {
      meetStore.deleteRoom(currentRoomId);
      meetStore.$patch({
        currentRoomId: '',
      });
    } else {
      meetStore.updateRoom(currentRoomId, {
        isRoomActive: false,
        callUserId: null,
        callInProgress: activeParticipants && activeParticipants.length > 0,
      });
    }
    if (currentRoomId !== '' && chainId) {
      await meetStore.reject(chainId);
    }
    tracks = [];
  };

  const _onCall = async (data: CallSignalRModel) => {
    logInfo(`connection.on("call"): ${data}`); //! DEBUG

    const messengerStore = useMessengerStore();

    if (meetStore.getCurrentRoomId === '') {
      meetStore.$patch({
        currentRoomId: data.roomName,
      });
    }
    meetStore.initRoom(data.roomName);

    let existedChain = messengerStore.getChainById(data.from.chatId) || null;
    if (!existedChain) {
      await messengerStore.chainById(data.from.chatId);
      existedChain = messengerStore.getChainById(data.from.chatId) || null;
    }
    if (!existedChain) {
      console.error('Chain is null', existedChain, data.from.chatId);
      return;
    }

    if (!existedChain?.muted && !isNativeMobile) {
      try {
        await callAudio.play();
      } catch (e) {
        console.error(e);
      }
    }

    meetStore.setChainId(data.roomName, existedChain.chainId);
    meetStore.setParticipants(data.roomName, existedChain);

    const meetTitle = data.isGroup ? data.from.title : existedChain?.title || '';
    const meetImage = data.from.image || null;
    const meetAvatarUrl = existedChain?.chainAvatar?.url || '';

    meetStore.updateRoom(data.roomName, {
      isGroupCall: data.isGroup,
    });

    const callModalResult = await componentMeetCallModal(meetTitle, meetImage, meetAvatarUrl);

    callAudio.pause();
    const meetStatus: MeetStatusEnum =
      callModalResult?.data === 'confirm' ? MeetStatusEnum.Accept : MeetStatusEnum.Reject;

    const answerStatus = await meetStore.answerUser(existedChain.chainId, data.roomName, meetStatus);
    if (answerStatus === undefined) {
      await disconnectFromRoom(data.roomName);
      return;
    }

    if (meetStatus === MeetStatusEnum.Accept) {
      if (meetStore.getCurrentRoomId !== data.roomName) {
        /** переход в новый звонок */
        await disconnectFromRoom();
        meetStore.$patch({
          currentRoomId: data.roomName,
        });
      }
      meetStore.updateRoom(data.roomName, {
        isRoomActive: true,
      });
      await componentMeetRoom();
      const requestPermissionsResult = await requestPermissions();
      if (!requestPermissionsResult) {
        await meetStore.answerUser(existedChain.chainId, data.roomName, MeetStatusEnum.Reject);
        await disconnectFromRoom(data.roomName);
        return;
      }
      const connectedToRoom = await connectToRoom(false, data.roomName);
      if (!connectedToRoom) {
        await disconnectFromRoom(data.roomName);
        return;
      }
    }
    if (meetStatus === MeetStatusEnum.Reject) {
      await disconnectFromRoom(data.roomName);
    }
  };

  const _onCallResult = async (data: CallResultSignalRModel) => {
    logInfo(`connection.on("callResult"): ${data}`); //! DEBUG

    if (!meetStore.getUser(data.user.id, data.roomName)) {
      meetStore.addUser(data.user, data.roomName);
    }

    switch (data.result) {
      case MeetStatusEnum.Accept:
        meetStore.updateRoom(data.roomName, {
          isRoomActive: true,
        });
        break;
      case MeetStatusEnum.Reject:
        meetStore.updateRoom(data.roomName, {
          isRoomActive: false,
        });
        break;
      case MeetStatusEnum.Timeout:
        alert('VideoChat_NotAnswer');
        break;
      case MeetStatusEnum.BusyOnAnotherLine:
        alert('VideoChat_Busy');
        break;
      case MeetStatusEnum.Connecting:
        alert('VideoChat_Connecting');
        break;
    }
  };

  const _onCallCancel = async (data: { userId: number; roomName: string }) => {
    logInfo(`connection.on("callCancel"): ${data}`); //! DEBUG
    const appStore = useAppStore();
    const currentUserId = appStore.userId;

    const { roomName: roomId, userId } = data;

    const chainId = meetStore.getChainIdByRoomId(roomId);

    const activeParticipants = chainId ? await meetStore.getActiveParticipants(chainId) : undefined;

    const isGroupCall = meetStore.isGroupCall(roomId);

    if ((!isGroupCall && userId !== currentUserId) || (isGroupCall && !activeParticipants?.length)) {
      const topModal = await modalController.getTop();
      if (topModal && topModal?.id === 'incomingCall') {
        callAudio.pause();
        await modalController.dismiss(null, 'end', 'incomingCall');
      }
      await disconnectFromRoom(roomId);
    }
  };

  // Video Calls - https://gitlab.united-grid.com/intra/intra-ionic/-/issues/122
  const registerEventsVideo = (connection: HubConnection | undefined): void => {
    if (!connection) {
      console.error('Register video events failed - connection is null', connection);
      return;
    }

    connection.on('call', _onCall);

    connection.on('callResult', _onCallResult);

    connection.on('callCancel', _onCallCancel);

    connection.on('joinToRoom', (message: any) => {
      logInfo(`connection.on("joinToRoom"): ${message}`); //! DEBUG
    });

    connection.on('userSetVideo', (data: any) => {
      logInfo(`connection.on("userSetVideo"): ${data}`); //! DEBUG
    });
  };

  const showCallModal = async (chain: MessageChainEntity | null): Promise<MeetStatusEnum> => {
    if (!chain?.muted && !isNativeMobile) {
      try {
        await callAudio.play();
      } catch (e) {
        console.error(e);
      }
    }
    const meetTitle = chain?.title || '';
    const meetAvatarUrl = chain?.chainAvatar?.url || '';
    const callModalResult = await componentMeetCallModal(meetTitle, null, meetAvatarUrl);

    callAudio.pause();
    return callModalResult?.data === 'confirm' ? MeetStatusEnum.Accept : MeetStatusEnum.Reject;
  };

  instance = {
    registerEventsVideo,
    requestPermissions,
    showDeviceBlockedAlert,
    connectToRoom,
    connectToDevices,
    disconnectFromRoom,
    changeAudio,
    changeVideo,
    showCallModal,
    tracks,
    localMedias,
    remoteMedias,
    participantCount,
  };

  return instance;
}
