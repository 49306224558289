import type { GalleryPhotos, PermissionStatus as CameraPermissionStatus, Photo } from '@capacitor/camera';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
// import { Device } from '@capacitor/device';
import type { PermissionStatus as FilePermissionStatus } from '@capacitor/filesystem';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import type { PickedFile } from '@capawesome/capacitor-file-picker';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { PreviewFile } from '@innoline/capacitor-preview-file';
import type { ActionSheetButton } from '@ionic/core';
import { actionSheetController, alertController } from '@ionic/vue';
import { Guid } from 'guid-typescript';
import { documentOutline, imageOutline, imagesOutline, cameraOutline } from 'ionicons/icons';
import type { Ref } from 'vue';
import { ref, watch } from 'vue';

import { logErr, logInfo } from './logger';

import { FileStatusEnum, PermissionTypes, UploadFileTypes } from '@/enums';
import {
  onOpenSettings,
  getUniqueKey,
  useImages,
  usePdfViewer,
  useErrors,
  // useIndexedDbHelper
} from '@/helpers';
import { blobToString, isBlob, isNativeAndroid, isNativeMobile, stringToBlob } from '@/helpers/helper';
import { useI18n } from '@/i18n';
import { $api } from '@/services';
import { FILES_DATA_STORAGE, useFileStore } from '@/store';
import type {
  ErrorMessageModel,
  FileCacheModel,
  FileModel,
  WikiModel,
  FilePickModel,
  ResponseFileModel,
  PostEntity,
  ResponseErrorModel,
} from '@/types';

interface IUseFiles {
  files: Ref<FilePickModel[]>;
  isLoading: Ref<boolean>;
  // Getters
  getByPayload(payload: string | undefined): FilePickModel | undefined;
  getFile(file: PickedFile): Promise<File | undefined>;
  getFilePreviewUrl(file: FileModel): Promise<string | undefined>;
  // Download
  downloadWiki(wiki: WikiModel, blob: Blob): Promise<FileStatusEnum>;
  downloadFile(file: FileModel): Promise<FileStatusEnum>;
  downloadPost(post: PostEntity, blob: Blob): Promise<FileStatusEnum>;
  // Upload
  uploadFile(file: PickedFile): Promise<FileModel | undefined>;
  uploadWeb(file: File): Promise<FileModel | undefined>;
  // Photos
  takePhoto(): Promise<PickedFile | undefined>;
  choosePhoto(): Promise<PickedFile[]>;
  choosePhotos(): Promise<PickedFile[]>;
  chooseFiles(types: UploadFileTypes): Promise<PickedFile[]>;
  // Files
  toFile(file: PickedFile): Promise<FileModel | undefined>;
  openFile(file: FileModel): Promise<FileStatusEnum>;
  readFile(url: string, internal: string | undefined, withLqip: boolean): Promise<string | undefined>;
  saveFile(file: Blob, internal: string, name: string, ext: string, mediaType: string): Promise<string | undefined>;
  pickFiles(types: UploadFileTypes): Promise<PickedFile[]>;
  // Helpers
  isImageFormatSupported(url: string | undefined): Promise<boolean>;
  remove(internal: string): Promise<void>;
  clear(): void;
  unsupportedImageFormats: string[];
  aiSupportedFiles: string[];
  aiSupportedImages: string[];

  fileIsAudio(file: FileModel): boolean;
}

const { t } = useI18n();

const _showSecondPrompt = async (type: PermissionTypes) => {
  const alert = await alertController.create({
    message: type === PermissionTypes.Camera ? t('permissions.cameraAndPhoto') : t('permissions.files'),
    buttons: [
      {
        text: t('cancel'),
        role: 'cancel',
        cssClass: 'custom-alert-buttons',
      },
      {
        text: t('appMenu.settings'),
        cssClass: 'custom-alert-buttons',
        handler: async () => {
          await onOpenSettings();
        },
      },
    ],
  });

  await alert.present();
};

//TODO: Should be moved to either helper or completely removed if would be added to WikiModel on backend
function generateUniqueKey(): string {
  const timestamp = Date.now().toString(36);
  const randomString = Math.random().toString(36).substring(2, 8);
  const uniqueKey = timestamp + randomString;
  return uniqueKey;
}

function useFilesHybrid(): IUseFiles {
  // const { handleError } = useErrors();
  const maxSizeFile: number = +import.meta.env.VITE_APP_MAX_SIZE * 1024 * 1024;
  const files: Ref<FilePickModel[]> = ref<FilePickModel[]>([]);
  const isLoading: Ref<boolean> = ref<boolean>(false);
  const isLoadingPhoto = ref<boolean>(false);
  const hasCameraPermission = ref<boolean>(false);
  const hasFilePermission = ref<boolean>(false);

  const unsupportedImageFormats: string[] = ['tiff', 'pbm', 'tga', 'svg', 'avif'];

  const supportedImagesTypes: string[] = [
    //TODO: 'image/webp' is not supported - #1652 - https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1652
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/x-icon',
  ];
  const supportedVideosTypes: string[] = [
    'video/mp4',
    'video/avi',
    'video/asf',
    'video/dv',
    'video/flv',
    'video/avm2',
    'video/f4v',
    'video/mov',
    'video/wmv',
    'video/3gp',
    'video/mkv',
    'video/3g2',
    'video/m2v',
    'video/m4v',
    'video/mpg',
    'video/mpeg',
    'video/webm',
  ];

  const aiSupportedFiles: string[] = [
    '.c',
    '.cs',
    '.cpp',
    '.doc',
    '.docx',
    '.html',
    '.java',
    '.json',
    '.md',
    '.pdf',
    '.php',
    '.pptx',
    '.py',
    '.rb',
    '.tex',
    '.txt',
    '.css',
    '.js',
    '.sh',
    '.ts',
  ];

  //TODO: Check actual supported images
  const aiSupportedImages: string[] = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg', 'image/webp', 'image/gif'];

  const checkPermissions = async (): Promise<boolean> => {
    if (hasFilePermission.value === false) {
      let status: FilePermissionStatus = await Filesystem.checkPermissions();

      if (status.publicStorage !== 'granted') {
        status = await Filesystem.requestPermissions();
      }

      // If the user has declined to grant permission
      if (status.publicStorage !== 'granted') {
        await _showSecondPrompt(PermissionTypes.Files);

        return false;
      }

      hasFilePermission.value = true;
    }
    return true;
  };

  const takePhoto = async (): Promise<PickedFile | undefined> => {
    if (hasCameraPermission.value === false) {
      let status: CameraPermissionStatus = await Camera.checkPermissions();

      if (status.camera !== 'granted') {
        status = await Camera.requestPermissions();
      }

      // если пользователь отказался давать разрешение
      if (status.camera !== 'granted') {
        await _showSecondPrompt(PermissionTypes.Camera);
        return;
      }

      hasCameraPermission.value = true;
    }

    isLoadingPhoto.value = true;
    let photo: Photo | undefined = undefined;
    try {
      photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 100,
      });
    } catch (e) {
      console.log(e);
    }
    isLoadingPhoto.value = false;

    if (photo === undefined) return;

    return {
      mimeType: 'image/' + photo.format,
      name: new Date().getTime() + '.' + photo.format,
      path: photo.path,
      size: 0,
    } as PickedFile;
  };

  const choosePhoto = async (): Promise<PickedFile[]> => {
    const _files = await loadingPhotos(1);
    return _files.length > 0 ? [_files[0]] : [];
  };

  const choosePhotos = async (): Promise<PickedFile[]> => loadingPhotos(0);

  const loadingPhotos = async (limit: number): Promise<PickedFile[]> => {
    if (hasCameraPermission.value === false) {
      let status: CameraPermissionStatus = await Camera.checkPermissions();

      if (status.photos !== 'granted') {
        status = await Camera.requestPermissions();
      }

      // если пользователь отказался давать разрешение
      if (status.photos !== 'granted') {
        await _showSecondPrompt(PermissionTypes.Camera);

        return [];
      }

      hasCameraPermission.value = true;
    }

    isLoadingPhoto.value = true;
    let gallery: GalleryPhotos | undefined = undefined;
    try {
      gallery = await Camera.pickImages({
        quality: 100,
        limit,
      });
    } catch (e) {
      console.log(e);
    }
    isLoadingPhoto.value = false;

    if (gallery === undefined) {
      return [];
    }

    const _files = [] as PickedFile[];
    for (const photo of gallery.photos) {
      if (photo.path !== undefined) {
        _files.push({
          mimeType: 'image/' + photo.format,
          name: new Date().getTime() + '.' + photo.format,
          path: photo.path,
          size: 0,
        } as PickedFile);
      }
    }
    return _files;
  };

  const chooseMedia = async (): Promise<PickedFile[]> => {
    if (isNativeMobile) {
      if (!(await checkPermissions())) {
        return [];
      }
    }

    try {
      const result = await FilePicker.pickMedia({
        limit: 0,
        readData: false,
      });
      return result.files;
    } catch (error) {
      useErrors().handleError(true, error, t('aiAssistant.attach.images.error'));
      return [];
    }
  };

  const chooseFiles = async (types: UploadFileTypes): Promise<PickedFile[]> => {
    if (isNativeMobile) {
      if (!(await checkPermissions())) {
        return [];
      }
    }

    try {
      let result;

      //NOTE: Although the current version of LIMIT supports Number, it still works as a Boolean.
      //TODO: Track changes, and promptly replace when the plugin supports specific numbers - https://capawesome.io/plugins/file-picker/#pickfilesoptions

      switch (types) {
        case UploadFileTypes.AiSupportedFiles:
          {
            result = await FilePicker.pickFiles({
              limit: 0, //NOTE: 0 or 1
              readData: false,
              types: aiSupportedFiles,
            });
          }
          break;

        case UploadFileTypes.AiSupportedImages:
          {
            result = await FilePicker.pickFiles({
              limit: 0, //NOTE: 0 or 1
              readData: false,
              types: aiSupportedImages,
            });
          }
          break;

        case UploadFileTypes.ManyDifferentFiles:
        case UploadFileTypes.SingleAnyFile:
          {
            result = await FilePicker.pickFiles({
              limit: types === UploadFileTypes.ManyDifferentFiles ? 0 : 1, //NOTE: 0 or 1
              readData: false,
            });
          }
          break;

        case UploadFileTypes.ManyImages:
        case UploadFileTypes.SingleImage:
          {
            result = await FilePicker.pickFiles({
              limit: types === UploadFileTypes.ManyImages ? 0 : 1, //NOTE: 0 or 1
              readData: false,
              types: supportedImagesTypes,
            });
          }
          break;

        case UploadFileTypes.ManyVideos:
        case UploadFileTypes.SingleVideo:
          {
            result = await FilePicker.pickFiles({
              limit: types === UploadFileTypes.ManyVideos ? 0 : 1, //NOTE: 0 or 1
              readData: false,
              types: supportedVideosTypes,
            });
          }
          break;

        default:
          return [];
      }

      return result.files;
    } catch (error) {
      if ((error as Error)?.message?.includes('canceled')) return [];
      useErrors().handleError(true, error, t('aiAssistant.attach.files.error'));
      return [];
    }
  };

  const _uploadFileToServer = async (file: File): Promise<FileModel | undefined> => {
    isLoading.value = true;
    const response = await $api.file.upload(file);
    if (response.statusCode === 200) {
      const model = response as ResponseFileModel;
      isLoading.value = false;
      return model.data;
    }

    if (response.statusCode !== 200) {
      const error = response as ResponseErrorModel;
      let _errors: string[] = [];
      error.errorMessages.forEach(function (m: ErrorMessageModel) {
        _errors = [..._errors, ...m.errors];
      });

      useErrors().handleError(true, error, _errors.join(', '));
      isLoading.value = false;
    }
  };

  /*
  TODO: Implement a IndexedDB caching mechanism - https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1397
  */
  const getFilePreviewUrl = async (file: FileModel): Promise<string | undefined> => {
    try {
      const fileContent: Blob | ResponseErrorModel = await $api.file.media(file.pdfUrl ?? file.apiUrl);
      // console.log('FileContent received:', fileContent); //! DEBUG

      if (!isBlob(fileContent)) {
        logErr('File content is not a Blob:', fileContent);
        return;
      }

      const mimeType = file.pdfUrl ? 'application/pdf' : file.mimeType;
      // console.log('MIME type:', mimeType); //! DEBUG

      const blob = new Blob([fileContent as Blob], { type: mimeType });
      // console.log('Blob created:', blob); //! DEBUG

      const blobUrl = URL.createObjectURL(blob);
      // console.log('Blob URL created:', blobUrl); //! DEBUG

      const response = await fetch(blobUrl);
      if (response.status !== 200) {
        logErr('Failed to load by Blob URL', new Error('Failed to load by Blob URL'));
        URL.revokeObjectURL(blobUrl);
        return;
      }
      return blobUrl;
    } catch (e) {
      logErr('Failed to get preview url', e);
    }
  };

  // Function to create a correctly formatted file name
  const createStyledFileName = (file: FileModel): string => {
    return `${file.name}.${file.mimeType.startsWith('video') ? file.type : 'pdf'}`
      .replace(/\s+/g, '_') // Replace spaces with underscores
      .replace(/[^\w\-.]+/g, '') // Remove all non-word characters except dash and dot
      .toLowerCase(); // Convert to lowercase
  };

  // Main openFile function integrating PDF title modification
  const openFile = async (file: FileModel): Promise<FileStatusEnum> => {
    console.log(`[VERBOSE] openFile function called with file: ${JSON.stringify(file)}`);

    const openAsMedia = file.mimeType.startsWith('video') || fileIsAudio(file);
    const url = openAsMedia ? (file.webUrl ?? file.apiUrl) : (file.pdfUrl ?? file.apiUrl);

    console.log(`[VERBOSE] File URL selected: ${url}`);
    let status: FileStatusEnum = FileStatusEnum.Error;

    try {
      // Fetch the file content from the provided URL
      const fileContent: Blob | ResponseErrorModel = await $api.file.media(url);
      console.log(`[VERBOSE] File content received: ${fileContent instanceof Blob ? 'Blob' : 'Error response'}`);

      if (!isBlob(fileContent)) {
        logErr('[VERBOSE] File content is not a Blob:', fileContent);
        return status;
      }

      // Create a properly formatted file name using the utility function
      const styledFileName = createStyledFileName(file);
      console.log(`[VERBOSE] Styled file name: ${styledFileName}`);

      // Handle mobile use case where we convert the Blob to base64
      if (isNativeMobile) {
        const base64Content = await blobToString(fileContent as Blob);
        await PreviewFile.previewBase64({
          base64: base64Content,
          mimeType: file.mimeType,
          name: styledFileName,
        });
        status = FileStatusEnum.Success;
        return status;
      }

      // Modify the PDF metadata (title) if the file is a PDF
      let blob = fileContent as Blob;
      if (!openAsMedia && file.mimeType === 'application/pdf') {
        logInfo('Modifying PDF metadata...');
        blob = await usePdfViewer().modifyPdfTitle(blob, file.name); // Modify PDF title to match file name
        logInfo('PDF metadata modified successfully');
      }

      // Create a Blob URL and open it in a new tab
      const blobUrl = URL.createObjectURL(blob);
      console.log(`[VERBOSE] Blob URL created: ${blobUrl}`);

      const newWindow = window.open(blobUrl, '_blank');
      if (!newWindow) {
        logErr(
          '[VERBOSE] Failed to open new window for the Blob URL',
          new Error('Failed to open new window for the Blob URL')
        );
        URL.revokeObjectURL(blobUrl); // Release the resources if the window couldn't open
        return status;
      }

      // Set the tab's title (document.title)
      newWindow.addEventListener('load', () => {
        newWindow.document.title = styledFileName;
        console.log(`[VERBOSE] Window title set to: ${styledFileName}`);
      });

      // Release Blob URL resources when the window is closed
      newWindow.addEventListener('beforeunload', () => {
        URL.revokeObjectURL(blobUrl);
        console.log(`[VERBOSE] Blob URL revoked: ${blobUrl}`);
      });

      status = FileStatusEnum.Success;
    } catch (e) {
      logErr('[VERBOSE] Failed to open file:', e);
      useErrors().handleError(true, e, 'Failed to open file:');
    }

    return status;
  };

  const saveFile = async (
    file: Blob,
    internal: string,
    name: string,
    ext: string,
    mimeType: string
  ): Promise<string | undefined> => {
    if (hasFilePermission.value === false) {
      let status: FilePermissionStatus = await Filesystem.checkPermissions();

      if (status.publicStorage !== 'granted') {
        status = await Filesystem.requestPermissions();
      }

      // If the user has declined to grant permission
      if (status.publicStorage !== 'granted') return;

      hasFilePermission.value = true;
    }

    const base64Data = await blobToString(file);
    if (name.length > 120) {
      name = name.slice(0, 120);
    }

    const directoryPath = `${import.meta.env.VITE_APP_NAME}`;

    // Checking if the directory already exists
    try {
      await Filesystem.stat({
        path: directoryPath,
        directory: Directory.Documents,
      });
    } catch (e) {
      // Directory does not exist, proceeding with creating one
      logErr('Directory does not exist', e);
      try {
        await Filesystem.mkdir({
          path: directoryPath,
          directory: Directory.Documents,
          recursive: false,
        });
      } catch (err) {
        logErr('Unable to create directory', err);
        return;
      }
    }

    // Saving the file inside the created or existing directory
    try {
      const savedFile = await Filesystem.writeFile({
        path: `${directoryPath}/${name}.${ext}`,
        data: base64Data,
        directory: Directory.Documents,
      });

      const fileModel: FilePickModel = {
        internal: internal,
        name: name,
        path: savedFile.uri,
        mimeType: mimeType,
        size: file.size,
        type: ext,
        payload: '',
        status: FileStatusEnum.Success,
        media: undefined,
      };

      files.value.push(fileModel);

      const fileStore = useFileStore();
      fileStore.add({
        internal: internal,
        mimeType: mimeType,
        path: savedFile.uri,
        name: name,
        size: file.size,
      } as FileCacheModel);

      return savedFile.uri;
    } catch (e) {
      logErr('Unable to write file', e);
      return;
    }
  };

  /*
    Helper function that handles incoming file models
    TODO: A back fix is needed so that the excessive amount of checks is not needed
  */
  const isImage = (file: FileModel): boolean => {
    switch (true) {
      case !!file?.mimeType && file.mimeType.startsWith('image'):
        return true;
      case !!file?.image && file.image.url !== null:
        return true;
      default:
        return false;
    }
  };

  const isVideo = (file: FileModel): boolean => {
    switch (true) {
      case !!file?.mimeType && file.mimeType.startsWith('video'):
        return true;
      case !!file?.videoPreview && file.videoPreview.url !== null:
        return true;
      default:
        return false;
    }
  };

  const getFileType = (file: FileModel): string => {
    if (file.type !== undefined) {
      return file.type;
    } else if (file.mimeType !== undefined) {
      return file.mimeType.split('/')[1];
    } else {
      return isImage(file) ? 'png' : isVideo(file) ? 'mp4' : '';
    }
  };

  const getFileUrl = (file: FileModel, fileType: 'media' | 'other'): string | undefined => {
    if (fileType === 'media') {
      if (isImage(file)) {
        return file.image?.url ?? undefined;
      } else if (isVideo(file)) {
        return file.videoPreview?.url ?? undefined;
      }
    } else if (fileType === 'other') {
      return file.apiUrl || file.webUrl || file.pdfUrl;
    }
  };

  const downloadFile = async (file: FileModel): Promise<FileStatusEnum> => {
    try {
      const alreadyHasExtension = file.name?.includes('.') || false;
      const fileType = getFileType(file);
      const hasUrl = getFileUrl(file, isImage(file) || isVideo(file) ? 'media' : 'other');

      if (!hasUrl) {
        logErr("File doesn't have a valid URL", file);
        return FileStatusEnum.Error;
      }

      const media: Blob | ResponseErrorModel | undefined = await $api.file.media(hasUrl);

      if (isBlob(media)) {
        if (isNativeMobile) {
          const savedFile = await saveFile(
            media as Blob,
            file.key,
            file.name || `default.${fileType}`,
            fileType,
            file.mimeType
          );
          return savedFile ? FileStatusEnum.Success : FileStatusEnum.Error;
        } else {
          const link = Object.assign(document.createElement('a'), {
            href: URL.createObjectURL(media as Blob),
            download: `${file.name || `default.${fileType}`}${alreadyHasExtension ? '' : '.' + fileType}`,
          });
          link.click();
        }
      } else {
        return FileStatusEnum.Error;
      }

      return FileStatusEnum.Success;
    } catch (error) {
      useErrors().handleError(true, error, 'Error while downloading file');
      return FileStatusEnum.Error;
    }
  };

  const downloadWiki = async (wiki: WikiModel, blob: Blob): Promise<FileStatusEnum> => {
    if (isNativeMobile) {
      if (isBlob(blob)) {
        const key = generateUniqueKey();
        const savedFile = await saveFile(blob as Blob, key, wiki.name, blob.type.split('/')[1], blob.type);

        if (savedFile !== undefined) {
          return FileStatusEnum.Success;
        } else {
          return FileStatusEnum.Error;
        }
      } else {
        return FileStatusEnum.Error;
      }
    } else {
      if (isBlob(blob)) {
        const link = Object.assign(document.createElement('a'), {
          href: URL.createObjectURL(blob as Blob),
          download: `${wiki.name}.${blob.type.split('/')[1] || 'unknown'}`,
        });
        link.click();
      }
    }

    return FileStatusEnum.Success;
  };

  const downloadPost = async (post: PostEntity, blob: Blob): Promise<FileStatusEnum> => {
    if (isNativeMobile) {
      if (isBlob(blob)) {
        const key = generateUniqueKey();
        const savedFile = await saveFile(blob as Blob, key, `post_${post.id}`, blob.type.split('/')[1], blob.type);

        if (savedFile !== undefined) {
          return FileStatusEnum.Success;
        } else {
          return FileStatusEnum.Error;
        }
      } else {
        return FileStatusEnum.Error;
      }
    } else {
      if (isBlob(blob)) {
        const link = Object.assign(document.createElement('a'), {
          href: URL.createObjectURL(blob as Blob),
          download: `post_${post.id}_${new Date().toISOString()}.${blob.type.split('/')[1] || 'unknown'}`,
        });
        link.click();
      }
    }

    return FileStatusEnum.Success;
  };

  const getByPayload = (payload: string | undefined): FilePickModel | undefined =>
    files.value.find((f) => f.payload === payload);

  const getFile = async (file: PickedFile) => {
    return isNativeMobile ? _readNativeFile(file.path, file.name, file.mimeType) : (file.blob as File);
  };

  const isImageFormatSupported = async (url: string | undefined): Promise<boolean> => {
    if (!url) {
      return false;
    }
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(true);
      };
      img.onerror = () => {
        try {
          logErr(`Failed to load image: ${url}`, new Error(`Failed to load image: ${url}`));
        } catch (error: any) {
          useErrors().handleError(true, error, 'Failed to load image');
          return false;
        }
        resolve(false);
      };
      img.src = url;
    });
  };

  const readFile = async (
    url: string,
    internal: string | undefined,
    withLqip: boolean
  ): Promise<string | undefined> => {
    if (!url) {
      logErr('URL is undefined', new Error('URL is undefined'));
      return;
    }

    const key = internal ?? getUniqueKey(url);
    if (!key) {
      logErr(
        `Unable to proceed without internal ${internal} or unique key ${key}`,
        new Error(`Unable to proceed without internal ${internal} or unique key ${key}`)
      );
      return;
    }

    // Check in local files array
    const index = files.value.findIndex((f) => f.internal === key);
    if (~index) {
      const file = files.value[index];
      if (file.media) {
        return file.media;
      }
    }

    // If LQIP is requested, modify the URL
    if (withLqip) {
      const originalUrl = new URL(url);
      const extension = originalUrl.pathname.split('.').pop() ?? '';
      const lqipPath = originalUrl.pathname.replace(/\/[^/]+$/, '/lqip/');
      url = `${originalUrl.origin}${lqipPath}${key}.${extension}`;
    }

    // Handle Native Mobile
    if (isNativeMobile && key) {
      const index = files.value.findIndex((f) => f.internal === key);
      if (~index) {
        const file = files.value[index];
        const blob = await _readNativeFile(file.path, file.name, file.mimeType);
        if (blob && isBlob(blob)) {
          /*
            const urlCreator = window.URL || window.webkitURL;
            const result = urlCreator.createObjectURL(blob);
            return result;
          */
          return URL.createObjectURL(blob);
        } else {
          logErr('Failed to read blob from native mobile', new Error('Failed to read blob from native mobile'));
        }
      }
    }

    // Handle Web via imagesHelper
    try {
      const imageHelper = useImages();
      const k = url.includes('lqip') ? `${key}.lqip` : key;
      const result = await imageHelper.fetchImage(k, async () => {
        const response = await $api.file.media(url);
        if (response instanceof Blob) return response;

        throw new Error('Media request did not return a Blob');
      });

      return result;
    } catch (e) {
      logErr(`Failed to load media from ${url}:`, e);
    }
  };

  const cacheFiles = async () => {
    const fileStore = useFileStore();
    await Preferences.set({
      key: FILES_DATA_STORAGE,
      value: JSON.stringify(fileStore.data),
    });
  };

  const toFile = async (file: PickedFile): Promise<FileModel | undefined> => {
    const newFile: FileModel = {
      id: 0,
      key: '',
      name: file.name,
      createdAt: '',
      author: null,
      editor: null,
      editedAt: '',
      editedBy: null,
      group: null,
      type: '',
      access: [],
      apiUrl: '',
      webUrl: '',
      pdfUrl: '',
      mimeType: file.mimeType,
      size: file.size,
      image: null,
      videoPreview: null,
      payload: Guid.create().toString(),
      parentFolderId: null,
      description: null,
      isOfficial: false,
      isFollowed: false,
    };

    if (isNativeAndroid && file.size > maxSizeFile) return;

    const fileModel: FilePickModel = {
      internal: '',
      name: file.name,
      path: file.path,
      mimeType: file.mimeType,
      size: file.size,
      type: file.mimeType.split('/')[1],
      payload: newFile.payload,
      status: FileStatusEnum.Loading,
      media: undefined,
    };

    files.value.push(fileModel);
    return newFile;
  };

  const pickFiles = async (types: UploadFileTypes): Promise<PickedFile[]> => {
    isLoading.value = true;

    if (!isNativeMobile) {
      const _files = chooseFiles(types);
      isLoading.value = false;
      return _files;
    }

    const { t } = useI18n();

    const icons = {
      image: imageOutline,
      images: imagesOutline,
      document: documentOutline,
      camera: cameraOutline,
    };

    const buttons: ActionSheetButton[] = [];
    const camera = {
      text: t('messenger.messengerSend.photo'),
      icon: icons.camera,
      role: 'photo',
    };

    const gallery = {
      text: t('messenger.messengerSend.media'),
      icon: icons.image,
      role: 'image',
    };

    const media = {
      text: t('messenger.messengerSend.media'),
      icon: icons.images,
      role: 'media',
    };

    const document = {
      text: t('messenger.messengerSend.file'),
      icon: icons.document,
      role: 'document',
    };

    const aiFiles = {
      // text: t('aiAssistant.files.supportedFiles'), //TODO
      text: 'AI Files',
      icon: icons.document,
      role: 'aiFiles',
    };

    const aiImages = {
      // text: t('aiAssistant.files.supportedImages'), //TODO
      text: 'AI Images',
      icon: icons.image,
      role: 'aiImages',
    };

    switch (types) {
      case UploadFileTypes.AiSupportedImages:
        buttons.push(aiImages);
        break;

      case UploadFileTypes.AiSupportedFiles:
        buttons.push(aiFiles);
        break;

      case UploadFileTypes.SingleImage:
        buttons.push(camera, gallery);
        break;

      case UploadFileTypes.ManyImages:
        buttons.push(camera, media);
        break;

      case UploadFileTypes.ManyDifferentFiles:
      case UploadFileTypes.SingleAnyFile:
        buttons.push(camera, media, document);
        break;

      case UploadFileTypes.SingleVideo:
      case UploadFileTypes.ManyVideos:
        buttons.push(document);
        break;

      default:
        break;
    }

    const actionSheet = await actionSheetController.create({
      buttons: buttons,
    });
    await actionSheet.present();

    let _files: PickedFile[] = [];

    const { role } = await actionSheet.onDidDismiss();
    switch (role) {
      case 'photo':
        {
          const file = await takePhoto();
          if (file !== undefined) {
            _files = [file];
          } else {
            _files = [];
          }
        }
        break;

      case 'image':
        _files = await choosePhoto();
        break;

      case 'media':
        _files = await chooseMedia();
        break;

      case 'document':
        _files = await chooseFiles(types);
        break;

      case 'aiFiles':
        _files = await chooseFiles(types);
        break;

      case 'aiImages':
        _files = await chooseFiles(types);
        break;
    }

    isLoading.value = false;
    return _files;
  };

  const uploadFile = async (file: PickedFile): Promise<FileModel | undefined> => {
    if (isNativeAndroid && file.size > maxSizeFile) return;

    let index = files.value.findIndex((f) => f.name === file.name && f.size === file.size);
    if (index < 0) {
      const newFile: FilePickModel = {
        internal: '',
        name: file.name,
        path: file.path,
        mimeType: file.mimeType,
        size: file.size,
        type: file.mimeType.split('/')[1],
        payload: Guid.create().toString(),
        status: FileStatusEnum.Loading,
        media: undefined,
      };
      files.value.push(newFile);
      index = files.value.length - 1;
    }

    return _uploadFile(file, index);
  };

  const uploadWeb = async (file: File): Promise<FileModel | undefined> => {
    const fileModel: FilePickModel = {
      internal: '',
      name: file.name,
      path: undefined,
      mimeType: file.type,
      size: file.size,
      type: file.type,
      payload: Guid.create().toString(),
      status: FileStatusEnum.Loading,
      media: undefined,
    };

    if (isNativeAndroid && file.size > maxSizeFile) {
      fileModel.status = FileStatusEnum.LargeFile;
      return;
    }

    const savedFile = await _uploadFileToServer(file);
    // debugger;
    if (savedFile !== undefined) {
      fileModel.internal = savedFile.key;
      fileModel.name = savedFile.name;
      if (!isNativeMobile) {
        fileModel.path = savedFile.apiUrl;
      }
      fileModel.size = savedFile.size;
      fileModel.mimeType = savedFile.mimeType;
      fileModel.payload = savedFile.payload;
      fileModel.status = FileStatusEnum.Success;

      if (fileModel.mimeType.startsWith('image')) {
        const urlCreator = window.URL || window.webkitURL;
        fileModel.media = urlCreator.createObjectURL(file);
      }

      files.value.push(fileModel);
      return savedFile;
    }
  };

  const _readNativeFile = async (
    path: string | undefined,
    name: string,
    mimeType: string
  ): Promise<File | undefined> => {
    if (path === undefined) return;

    try {
      const localFile = await Filesystem.readFile({
        path,
      });
      return stringToBlob(localFile.data.toString(), name, mimeType);
    } catch (e) {
      logErr('Error while reading file', e);
    }
  };

  const _uploadFile = async (file: PickedFile, index: number): Promise<FileModel | undefined> => {
    const fileModel = files.value[index];

    if (isNativeAndroid && file.size > maxSizeFile) {
      fileModel.status = FileStatusEnum.LargeFile;
      files.value.splice(index, 1, fileModel);
      return;
    }

    const blob = isNativeMobile ? await _readNativeFile(file.path, file.name, file.mimeType) : (file.blob as File);

    if (blob === undefined) return;

    const savedFile = await _uploadFileToServer(blob);
    if (savedFile !== undefined) {
      fileModel.internal = savedFile.key;
      fileModel.name = savedFile.name;
      if (!isNativeMobile) {
        fileModel.path = savedFile.apiUrl;
      }
      fileModel.size = savedFile.size;
      fileModel.type = savedFile.type;
      fileModel.mimeType = savedFile.mimeType;
      fileModel.payload = savedFile.payload;
      fileModel.status = FileStatusEnum.Success;

      if (file.mimeType.startsWith('image')) {
        const urlCreator = window.URL || window.webkitURL;
        fileModel.media = urlCreator.createObjectURL(blob);
      }

      files.value.splice(index, 1, fileModel);

      return savedFile;
    }
  };

  const remove = async (internal: string) => {
    if (!isNativeMobile) return;

    const index = files.value.findIndex((f) => f.internal === internal);
    if (~index) {
      const path = files.value[index].path;
      if (path !== undefined && files.value[index].status === FileStatusEnum.Success) {
        try {
          await Filesystem.deleteFile({
            path,
          });
        } catch (e) {
          console.log(e);
        }
      }

      files.value.splice(index, 1);
    }
  };

  const clear = () => {
    files.value = [];
    isLoading.value = false;
  };

  const fileIsAudio = (file: FileModel): boolean => {
    return file.mimeType.startsWith('audio');
  };

  watch(files, cacheFiles);

  return {
    files,
    isLoading,
    getByPayload,
    readFile,
    isImageFormatSupported,
    saveFile,
    openFile,
    downloadFile,
    downloadWiki,
    downloadPost,
    getFile,
    getFilePreviewUrl,
    choosePhoto,
    choosePhotos,
    takePhoto,
    chooseFiles,
    pickFiles,
    toFile,
    uploadFile,
    uploadWeb,
    remove,
    clear,
    unsupportedImageFormats: unsupportedImageFormats,
    aiSupportedFiles: aiSupportedFiles,
    aiSupportedImages: aiSupportedImages,
    fileIsAudio,
  };
}

let _appLocal: IUseFiles | undefined;

const getApp = () => {
  if (_appLocal === undefined) {
    _appLocal = useFilesHybrid();
  }
  return _appLocal;
};

export const filesHybrid = getApp();
