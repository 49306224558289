import { Capacitor } from '@capacitor/core';
import { getPlatforms } from '@ionic/core';
import type { OverlayEventDetail } from '@ionic/core';
import { toastController } from '@ionic/vue';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import { ResponseErrorTypeEnum } from '@/enums';
import { useUserFlow, useErrors, useToasts } from '@/helpers';
import { logDebug, logErr } from '@/helpers/logger';
import { DEFAULT_LOCALE, useI18n, SUPPORT_LOCALES } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import { resetAllLoadingStates, useAppStore } from '@/store';
import type { ResponseVersionModel } from '@/types';

const { t } = useI18n();

/**
 * Constants
 */
const API_URL: string = import.meta.env.VITE_APP_URL_API;
const RETRY_COUNT = Number(import.meta.env.VITE_AXIOS_RETRY_COUNT);
const RETRY_DELAY = Number(import.meta.env.VITE_AXIOS_RETRY_AFTER_FIRST_ERROR_DELAY);

/**
 * Checks if the error indicates that the server is unavailable.
 *
 * @param error - Axios error
 * @returns {boolean} - true if server is unavailable
 */
const isServerUnavailable = (error: AxiosError): boolean => {
  return (
    axios.isAxiosError(error) &&
    (error.code === ResponseErrorTypeEnum.ERR_NETWORK ||
      error.code === ResponseErrorTypeEnum.ECONNABORTED ||
      error.code === ResponseErrorTypeEnum.ETIMEDOUT ||
      error.message.includes('CORS') ||
      error.message.includes('cors') ||
      [502, 503, 504, 521, 522].includes(error.response?.status ?? -1))
  );
};

/**
 * Aborts the requests
 */
const handleAbort = (): AbortSignal => {
  const controller = new AbortController();
  controller.abort();
  return controller.signal;
};

/**
 * Manages the toast notifications during retry attempts
 */
const handleRetry = async (retryCount: number, error: AxiosError, requestConfig: AxiosRequestConfig): Promise<void> => {
  const errorsHelper = useErrors();

  if (retryCount === 1) {
    const { showDismissingToast } = useToasts();
    const toast = await showDismissingToast(error.message || t('errorResponse'), t('retry'), false);
    try {
      toast.onDidDismiss().then(async (event: OverlayEventDetail) => {
        if (event.role === 'retry') {
          // Adding a delay of 3 seconds before retrying the request
          await new Promise((resolve) => setTimeout(resolve, 3000));
          // Making the same request again after the delay
          await axiosInstance(requestConfig);
        }
      });
    } catch (e) {
      logErr('Reconnection failed', e);
    }
  }

  if (retryCount === RETRY_COUNT) {
    const toast = await toastController.getTop();
    toast && (await toastController.dismiss());
    if (error.response?.status !== 200) {
      const message = 'Error in retry logic';
      logErr(`[${message}] Error:`, error);
      errorsHelper.handleError(true, error, error.message || t('errorResponse'));
    }
  }
};

//* Axios instance
/**
 * Axios instance creation with language headers
 */
const axiosInstance = axios.create({
  headers: {
    common: {
      'Accept-Language': SUPPORT_LOCALES.includes(window.navigator.language.substring(0, 2).toLowerCase())
        ? window.navigator.language.substring(0, 2).toLowerCase()
        : DEFAULT_LOCALE,
      AppHost: window.location.hostname,
    },
  },
});

/**
 * Retry behavior configuration using exponential backoff
 */
const retryDelay = (retryCount: number): number => Math.min(RETRY_DELAY * Math.pow(2, retryCount - 1), 30000);
const retryCondition = (error: AxiosError): boolean => {
  return (
    error.request.responseType !== 'blob' &&
    !error.config?.url?.startsWith('/oauth') &&
    !isServerUnavailable(error) &&
    !!error.status &&
    ![401, 403, 404, 405].includes(error.status)
  );
};
axiosRetry(axiosInstance, {
  retries: RETRY_COUNT,
  retryDelay: retryDelay,
  retryCondition: (error: AxiosError) => retryCondition(error),
  onRetry: async (retryCount: number, error: AxiosError, requestConfig: AxiosRequestConfig) => {
    await handleRetry(retryCount, error, requestConfig);
  },
});

/**
 * Helper function to check if requesting a specific endpoint should NOT trigger session validation
 * @param {string} url - Currently requested URL
 * @returns {boolean} - true if validation should be skipped, false otherwise
 */
const isValidationExcluded = (url: string): boolean => {
  const excludedEndpoints = [
    'oauth/link',
    'oauth/networks',
    'oauth/homeCode',
    'oauth/token',
    'tools/sendToSupport',
    'networks/locate',
    'networks/allowedEmailSuffixes',
    'account/passwordSettings',
    'account/passwordPolicy',
    'account/passwordRestore',
    'account/passwordSave',
    'account/passwordUpdate',
    'account/invite',
    'account/registration/byForm',
    'account/registration/byNetwork',
    'account/registerDevice',
    'account/unRegisterDevice',
  ];
  return excludedEndpoints.some((e) => url.includes(e));
};

/**
 * Helper function to add necessary headers to the request configuration
 * @param {AxiosRequestConfig} config - Axios request configuration
 * @param {string} accessToken - Session (short-lived) access token
 */
const addHeaders = (config: AxiosRequestConfig, accessToken: string) => {
  if (accessToken) {
    config.headers = {
      ...config.headers,
      Authorization: `${import.meta.env.VITE_APP_AUTHORIZATION_KEY} ${accessToken}`,
    };
  }

  config.headers = {
    ...config.headers,
    BrowserPlatforms: getPlatforms(),
    CapacitorPlatform: Capacitor.getPlatform(),
    IsNativePlatform: Capacitor.isNativePlatform() ? 'True' : 'False',
    ApiVersion: import.meta.env.VITE_APP_API,
  };
};

axiosInstance.interceptors.request.use(
  async function onFulfilled(config: any): Promise<any> {
    const appStore = useAppStore();
    const controller = new AbortController();
    config.signal = controller.signal;
    config.baseURL = `${appStore.urlCore}${API_URL}`;

    if (isValidationExcluded(config.url ?? '')) {
      addHeaders(config, appStore.accessToken);
      return config;
    }

    if (!appStore.isAuth()) {
      logDebug('Session is NOT valid. Attempting revalidation...');
      await appStore.token(true);

      if (!appStore.isAuth()) {
        const message = `Session revalidation failed. Redirecting to login.`;
        const error = new Error(message);
        await handleSessionTerminate(error, message);
        return Promise.reject(error);
      }
    }

    addHeaders(config, appStore.accessToken);
    return config;
  },

  async function onRejected(error: AxiosError): Promise<any> {
    logErr(`[Request interceptor] Error:`, error);
    useErrors().handleError(true, error, error.message || t('errorResponse'));

    if (isServerUnavailable(error)) {
      handleAbort();
    }

    resetAllLoadingStates();
    return Promise.reject(error);
  }
);

/**
 * Response interceptor configuration
 */
axiosInstance.interceptors.response.use(
  async function onFulfilled(response: AxiosResponse): Promise<any> {
    return Object.assign(response.data, {
      statusCode: response.status,
    });
  },

  async function onRejected(error: AxiosError): Promise<any> {
    logErr(`[Response interceptor] Error:`, error);
    useErrors().handleError(true, error, error.message || t('errorResponse'));

    if (isServerUnavailable(error)) {
      handleAbort();
    }

    resetAllLoadingStates();
    return Promise.reject(error);
  }
);
export default axiosInstance;

//* Exports
/**
 *  Handles the session termination
 *  @param {any} error - The error object
 *  @param {string} message - The message to display
 *  @param {boolean} silent - If true, the error will handled without showing the toast
 *  - Calls the handleAbort function to abort the requests
 *  @see src/services/axios.ts - handleAbort
 *  - Calls the logOut function to log out the user
 *  @see src/helpers/useUserFlowHelper.ts - logOut
 *  - Calls the router.replace function to redirect the user to the login page
 *  - Calls the handleError function to handle the error
 *  @see src/helpers/useErrorsHelper.ts - handleError
 */
export const handleSessionTerminate = async (
  error: any,
  message = 'Session terminated',
  silent = false
): Promise<void> => {
  handleAbort();
  await Promise.all([useUserFlow().logOut(), router.replace({ name: ROUTES_NAME.LOGIN })]);
  // !silent && useErrors().handleError(true, error, message);
  useErrors().handleError(!silent, error, message);
};

/**
 * Helper function to get the application version to be exported outside of this file
 *
 * @returns {Promise<string>} - application version
 */
export async function getAppVersion(): Promise<string> {
  const appStore = useAppStore();
  let urlApp = '';

  const url = `${appStore.urlCore}${API_URL}/tools/appVersion`;
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ApiVersion: import.meta.env.VITE_APP_API,
      AppHost: window.location.hostname,
    },
    body: JSON.stringify({
      version: appStore.appVersion,
    }),
  })
    .then(async (response: Response) => {
      if (response.ok) {
        await response.json().then((responseToken: ResponseVersionModel) => {
          urlApp = responseToken.data;
        });
      } else {
        logErr('Error in getAppVersion: ', response.status);
      }
    })
    .catch((e: any) => {
      logErr('Error in getAppVersion: ', e);
    });

  return urlApp;
}
