<template>
  <div
    v-if="typeForButtons === FeedTypeForButtonsEnum.Post"
    :class="['post_controls', { has_comments: data?.comments?.count > 0 }]"
  >
    <div class="buttons">
      <ion-button
        v-for="button in postButtons"
        :key="button.value"
        mode="md"
        :disabled="!button.enabled"
        :class="[{ active: button.active }]"
        @click.stop="postAction(button.action)"
      >
        <span v-if="button.value" class="counter">{{ button.value }}</span>
        <ion-icon :slot="button.value ? 'start' : 'icon-only'" :icon="button.icon" />
      </ion-button>
    </div>

    <div v-if="access.includes(ActionAccessEnum.ObserveViewers) && data.usersReadCount > 0" class="read_button">
      <ion-button mode="md" @click.stop="openViewersPopover">
        <ion-icon :icon="icons.eye" />
        <span class="counter">{{ data.usersReadCount }}</span>
      </ion-button>
    </div>
  </div>

  <div v-if="typeForButtons === FeedTypeForButtonsEnum.Comment" class="comment_controls">
    <ion-button
      v-for="button in commentButtons"
      :key="button.value"
      mode="md"
      fill="clear"
      :disabled="!button.enabled"
      :class="button.active ? 'active' : ''"
      @click.stop="commentAction(button.action)"
    >
      <span v-if="button.value" class="counter">{{ button.value }}</span>
      <ion-icon :slot="button.value ? 'start' : 'icon-only'" :icon="button.icon" />
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
import { IonButton, IonIcon } from '@ionic/vue';
import { heartOutline, heart, chatboxOutline, arrowUndoOutline, arrowRedoOutline, eyeOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { ref, computed } from 'vue';

import { ActionAccessEnum, FeedFlagEnum, FeedTypeEnum, FeedTypeForButtonsEnum } from '@/enums';
import { componentFeedViewersPopover, useToasts } from '@/helpers';
import { useI18n } from '@/i18n';
import { usePostStore, useGroupsStore } from '@/store';
import type { PostEntity, CommentsDataModel } from '@/types';

// Props
const props = defineProps({
  data: {
    type: Object as PropType<PostEntity | CommentsDataModel | any>,
    required: true,
  },
  access: {
    type: Array as PropType<ActionAccessEnum[]>,
    required: true,
  },
  onlyCount: {
    type: Boolean,
    default: () => false,
  },
  previewMode: {
    type: Boolean,
    default: () => false,
  },
  disabledButtons: {
    type: Boolean,
    default: () => false,
  },
  typeForButtons: {
    type: String,
    required: true,
  },
  postId: {
    type: Number,
    default: () => 0,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    default: () => FeedFlagEnum.FeedPage,
  },
});

// Helpers
const { t } = useI18n();
const { showSonnerToast } = useToasts();

// Icons
const icons = {
  share: arrowRedoOutline,
  heartOutline,
  heart,
  comments: chatboxOutline,
  arrow: arrowUndoOutline,
  eye: eyeOutline,
};

// Store
const postStore = usePostStore();
const groupStore = useGroupsStore();

// Refs
const isLoading = ref<boolean>(false);

// Computed
const isLiked: ComputedRef<boolean> = computed(() => data.value.likes.isLiked);
const postId: ComputedRef<number | undefined> = computed(() => props.postId);
const data: ComputedRef<PostEntity | CommentsDataModel | any> = computed(() => props.data);
// Hiding likes of post if post's group has enabledLikes setting as false - https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1180
const likesAreEnabledInGroup: ComputedRef<boolean> = computed(() => {
  if ((props.data as PostEntity).group && !props.postId) {
    return groupStore.getGroupById(props.data.group.id).enableLikes;
  }

  if (props.postId) {
    const group = postStore.getPostById(props.postId).group;
    if (!group) return true;

    return groupStore.getGroupById(group.id).enableLikes;
  }

  return true;
});

const postButtons: ComputedRef<any> = computed(() => {
  const buttons = [
    {
      icon: isLiked.value ? icons.heart : icons.heartOutline,
      enabled: !(props.disabledButtons || isLoading.value),
      visible: props.previewMode || (props.access.includes(ActionAccessEnum.Like) && likesAreEnabledInGroup.value),
      action: ActionAccessEnum.Like,
      value: data.value.likes.count,
      active: isLiked.value,
    },
    {
      icon: icons.comments,
      enabled: !props.disabledButtons,
      visible:
        props.previewMode ||
        (props.access.includes(ActionAccessEnum.Comment) && data.value.messageType !== FeedTypeEnum.Task),
      action: ActionAccessEnum.Comment,
      value: data.value.comments.count,
      active: false,
    },
    {
      icon: icons.share,
      enabled: true,
      visible: props.access.includes(ActionAccessEnum.Share) && !props.disabledButtons && false, // temporary hidden - sdvmxm@gmail.com
      action: ActionAccessEnum.Share,
      value: null,
      active: false,
    },
  ];

  return buttons.filter((n) => n.visible);
});

const commentButtons: ComputedRef<any> = computed(() => {
  const buttons = [
    {
      icon: icons.arrow,
      enabled: !props.disabledButtons,
      action: ActionAccessEnum.Comment,
      visible: props.access.includes(ActionAccessEnum.Comment),
      value: null,
      active: false,
    },
    {
      icon: isLiked.value ? icons.heart : icons.heartOutline,
      enabled: !(props.disabledButtons || isLoading.value),
      visible: props.access.includes(ActionAccessEnum.Like) && likesAreEnabledInGroup.value,
      action: ActionAccessEnum.Like,
      value: data.value.likes.count,
      active: isLiked.value,
    },
  ];

  return buttons.filter((n) => n.visible);
});

// Actions
const postAction = async (action: ActionAccessEnum) => {
  switch (action) {
    case ActionAccessEnum.Like:
      await likeChange();
      break;

    case ActionAccessEnum.Comment:
      emit('openCommentField', props.data.id);
      break;

    case ActionAccessEnum.Share:
      // Button "Share" is temporarily hidden - sdvmxm@gmail.com
      break;

    default:
      break;
  }
};

const commentAction = async (action: ActionAccessEnum) => {
  switch (action) {
    case ActionAccessEnum.Like:
      await likeChange();
      break;

    case ActionAccessEnum.Comment:
      commentReply();
      break;

    default:
      break;
  }
};

const likeChange = async () => {
  isLoading.value = true;
  switch (props.typeForButtons) {
    case FeedTypeForButtonsEnum.Post:
      if (data.value.likes.isLiked) {
        if (!(await postStore.postRemoveLike(data.value.id))) {
          showSonnerToast(t('feed.likes.notDeleted'), false);
        }
      } else {
        if (!(await postStore.postAddLike(data.value.id))) {
          showSonnerToast(t('feed.likes.notSet'), false);
        }
      }
      break;
    case FeedTypeForButtonsEnum.Comment:
      if (postId.value !== undefined) {
        if (data.value.likes.isLiked) {
          if (!(await postStore.commentRemoveLike(data.value.id, postId.value))) {
            showSonnerToast(t('feed.likes.notDeleted'), false);
          }
        } else {
          if (!(await postStore.commentAddLike(data.value.id, postId.value))) {
            showSonnerToast(t('feed.likes.notSet'), false);
          }
        }
      }
      break;
  }

  isLoading.value = false;
};

const openViewersPopover = async () => {
  await componentFeedViewersPopover(data.value.id);
};

const commentReply = () => {
  emit('onCommentReply', data.value);
};

// Emits
const emit = defineEmits(['openCommentField', 'onCommentReply']);
</script>

<style scoped lang="scss">
.post_controls {
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .buttons {
    display: flex;
    justify-content: start;
    align-items: center;

    ion-button {
      @include resetStyleFromIonicButton;
      --border-radius: #{app-radius(md)};
      --background: var(--ion-color-light-custom);
      --color: var(--ion-color-medium);
      min-width: calc(app-padding(lg) * 4);
      min-height: calc(app-padding(md) * 5);
      margin-right: app-padding(md);
      ion-icon {
        font-size: 1.4em;
      }

      &.active {
        --background: rgba(var(--ion-color-danger-rgb), 0.15);
        color: rgba(var(--ion-color-danger-rgb), 0.7);
        ion-icon {
          color: var(--ion-color-danger);
        }
      }
    }
  }

  &.has_comments {
    margin-bottom: app-padding(lg);
  }

  .read_button {
    ion-button {
      @include resetStyleFromIonicButton;
      --border-radius: #{app-radius(md)};
      --background: var(--ion-color-light-custom);
      --color: var(--ion-color-medium);
      min-width: calc(app-padding(lg) * 4);
      min-height: calc(app-padding(md) * 5);
      ion-icon {
        margin-inline: calc(app-padding(md) / 2);
      }
    }
  }
}

.comment_controls {
  display: flex;
  justify-content: end;

  ion-button {
    @include resetStyleFromIonicButton;
    --border-radius: #{app-radius(md)};
    --color: var(--ion-color-medium);
    --padding-end: 0;
    --padding-start: #{app-padding(md)};
    --background-hover: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --ripple-color: transparent;
    &:nth-child(2) {
      margin-left: app-padding(md);
    }

    ion-icon {
      font-size: 1.1rem;
    }

    &.active {
      color: rgba(var(--ion-color-danger-rgb), 0.7);
      ion-icon {
        color: var(--ion-color-danger);
      }
    }
    &:hover {
      ion-icon {
        opacity: 0.7;
      }
    }
  }
}

.counter {
  font-size: 0.9rem;

  @include respond-to-min-width(2xl) {
    font-size: 1rem;
  }
}
</style>
