import { alertController, modalController } from '@ionic/vue';
import {
  trashOutline,
  eyeOutline,
  addOutline,
  repeatOutline,
  documentTextOutline,
  saveOutline,
  readerOutline,
  refreshOutline,
} from 'ionicons/icons';
import { cloneDeep, debounce, pick } from 'lodash';
import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import DocVersions from '../../assets/icon/doc-versions.svg';
import Download from '../../assets/icon/download.svg';
import GitlabLink from '../../assets/icon/gitlab_link.svg';
import History from '../../assets/icon/history.svg';
import Move from '../../assets/icon/move.svg';
import NotificationsOff from '../../assets/icon/notifications-off.svg';
import Notifications from '../../assets/icon/notifications.svg';
import PencilSquare from '../../assets/icon/pencil-square.svg';
import Remove from '../../assets/icon/remove.svg';
import StarO from '../../assets/icon/star-o.svg';
import Star from '../../assets/icon/star.svg';
import Users from '../../assets/icon/users.svg';

import { docBrowserContextMenuSheet } from './actionSheetComponents';
import { logErr, logInfo } from './logger';
import {
  componentTitleChange,
  componentWikiFollowersModal,
  componentWikiTemplatesModal,
  componentWikiTemplateSaveModal,
  wikiActionsMenu,
} from './modalComponents';

import {
  DataViewMode,
  DocumentTypeEnum,
  WikiMenuActionEnum,
  WikiEditControlsEnum,
  FileStatusEnum,
  DocumentExtensionEnum,
  WikiDeleteOptionsEnum,
  WikiDownloadOptionsEnum,
  WikiVersionEnum,
  WikiEditOptionsEnum,
  BasicEditControlsEnum,
  WikiSaveModeEnum,
  WikiMajorFilterEnum,
  EditModeEnum,
  AppIconsEnum,
  UserRoleEnum,
  GroupsAccessEnum,
  ActionAccessEnum,
} from '@/enums';
import {
  filesHybrid,
  isBlob,
  wikiReplaceActionsMenu,
  componentDocsAttachment,
  componentDocsCreateFile,
  componentDocsMoveFile,
  componentWikiCreate,
  componentWikiRelations,
  componentWikiHistoryModal,
  formatDateHelper,
  appDebounce,
  useRichTextEditor,
  wikiTemplatesMenuPopover,
  isNativeMobile,
  useToasts,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { defaultAdvancedWikiParticipantsModel } from '@/models';
import router, { ROUTES_NAME } from '@/router';
import type { WikiState } from '@/store';
import { useDocStore, useWikiStore, useUserStore, useNetworkStore, useGroupsStore, useAppStore } from '@/store';
import type {
  DocEntity,
  WikiModel,
  WikiHistoryModel,
  MediaModel,
  AppActionButton,
  WikiActionsMenuModel,
  EditOptions,
  EditControls,
  WikiDraftModel,
  TreeFolderModel,
  GroupEntity,
  CreateWikiPayload,
  UpdateWikiPayload,
  WikiEditFormModel,
  WhichActionToMakePayload,
  TabCategories,
  WikiTemplateModel,
  UserShortModel,
  CreateWikiTemplateModel,
} from '@/types';

export type HistoricalButtons = {
  left: AppActionButton;
  right: AppActionButton;
};

export type IUseWiki = {
  //* Preview Menu
  getActionsMenuItems: (wiki: WikiModel) => WikiActionsMenuModel[];

  //* Edit Header Options
  getEditOptions(mode: EditModeEnum): EditOptions[];
  editOptionsAction(action: WikiEditOptionsEnum): Promise<void>;

  //* Edit Header Controls
  getEditControls(mode: EditModeEnum): EditControls[];
  editControlsAction(action: BasicEditControlsEnum | WikiEditControlsEnum, ev?: Event): void;
  openActionsMenu: (wiki: WikiModel) => Promise<void>;

  //* Actions
  whichActionToMake: (payload: WhichActionToMakePayload, fromDocBrowser?: boolean) => Promise<void>;
  openWiki: (id: number, versionId?: number) => Promise<void>;
  createWiki: () => Promise<void>;
  deleteWiki: (wiki?: WikiModel, id?: number) => Promise<void>;

  //* Relations
  //TODO: addRelation
  //TODO: removeRelation

  //* History
  //TODO: getHistoryById
  //TODO: getHistoryByDate
  //TODO: updateHistoricalWiki
  getHistoricalButtons: (version: WikiHistoryModel, mode: DataViewMode) => HistoricalButtons;
  getHistoricalModifiedByAvatar: (wiki: WikiHistoryModel) => MediaModel | null;
  compareHistorical: (sourceId?: number, ids?: number[], fromDocBrowser?: boolean) => Promise<void>;
  showHistory: (id: number, fromDocBrowser?: boolean, fromComparePage?: boolean) => Promise<number | undefined>;

  //* Drafts
  debouncedUpdateDraft: () => void;

  //* Templates
  //TODO: getTemplateById
  //TODO: getTemplates
  //TODO: createFromTemplateById
  //TODO: createTemplate
  //TODO: updateTemplateById
  //TODO: deleteTemplateById

  getWikiTemplatesTableHeader: () => any;
  getWikiTemplatesMenuItems: () => TabCategories<WikiEditControlsEnum>[];

  //* Tags
  //TODO: addTag
  //TODO: removeTag

  //* Lock

  //* Contributions
  //TODO: getContributionsByUserId

  //* Utils
  shouldPreventWikiEdit(id?: number, wiki?: WikiModel | null): Promise<boolean>;
  preventSave: () => string | false;
  leaveEditPage: () => void;
  checkIfOutdated: (wiki: WikiModel, date?: string) => Promise<boolean>;
  goToHistoricalWiki: (id: number, date: string) => Promise<void>;

  /**
   * Determines if the user has access to create a wiki.
   *
   * @param {number | null} groupId - The group identifier. If null, checks access for the general feed.
   * @returns {boolean} Returns true if the user has access to create a wiki, otherwise false.
   */
  getWikiCreateAccess: (groupId: number | null) => boolean;
};

export const useWiki = (): IUseWiki => {
  //* Router
  const route = useRoute();
  const routeName: ComputedRef<string> = computed(() => (route.name as string) || '');

  //* Helpers
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();

  //* Store
  const wikiStore = useWikiStore();
  const docStore = useDocStore();
  const userStore = useUserStore();
  const networkStore = useNetworkStore();
  const groupStore = useGroupsStore();

  //* Refs
  const currentSaveMode: Ref<WikiSaveModeEnum> = ref(WikiSaveModeEnum.Major);
  const resetActiveFolderFlag = ref<boolean>(false);

  //* Computed - non wikiStore
  const isAdvancedModeOn: ComputedRef<boolean> = computed(() => networkStore.settings?.isAdvancedWikiesEditor ?? false);
  const selectedFolder: ComputedRef<TreeFolderModel | null> = computed(() => docStore.selectedFolder ?? null);
  const selectedGroup: ComputedRef<GroupEntity | null> = computed(() => docStore.selectedGroup ?? null);

  //* Computed - wikiStore
  const existingWiki: ComputedRef<WikiModel | null> = computed(() => wikiStore.existingWiki || null);
  const historicalWiki: ComputedRef<WikiModel | null> = computed(() => wikiStore.historicalWiki || null);
  const isSimple: ComputedRef<boolean> = computed(() => wikiStore.isSimple);
  const isAdvanced: ComputedRef<boolean> = computed(() => wikiStore.isAdvanced);
  const existingId: ComputedRef<number | null> = computed(() => wikiStore.existingWiki?.id ?? null);
  const draftWiki: ComputedRef<WikiDraftModel | null> = computed(() => wikiStore.draftWiki ?? null);
  const editForm: ComputedRef<WikiEditFormModel> = computed(() => wikiStore.editForm);
  const newId: ComputedRef<number | null> = computed(() => wikiStore.newId);
  const isModalMode: ComputedRef<boolean> = computed(() => wikiStore.isModalMode);
  // const messageFromAi = computed(() => wikiStore.messageFromAi || '');

  //* Icons
  const icons = {
    eye: eyeOutline, // Go to the current version
    plus: addOutline, // Save as template
    arrows: repeatOutline, // Update template
    save: saveOutline, // Update draft
    doc: documentTextOutline, // Delete draft
    trash: trashOutline, // Delete note
    templates: readerOutline, // Templates menu
    refresh: refreshOutline, // Refresh templates
  };

  //* Private methods
  const _confirmDelete = async (wikiId: number | undefined, versionId: number | undefined): Promise<void> => {
    const id = wikiId ?? versionId;

    if (!id) {
      console.error('Wiki id is not defined');
      return;
    }

    const alert = await alertController.create({
      message: `${t('documents.popup.deleteWiki')} <strong>${id}</strong>?`,
      buttons: [
        {
          text: t('no'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('yes'),
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            try {
              const result = await wikiStore.delete(id);
              if (!result) return;

              showSonnerToast(t('documents.popup.deleteSuccess'), true);
              await alertController.dismiss();
              router.currentRoute.value.name === ROUTES_NAME.WIKI_BY_ID && router.back();
            } catch (error) {
              console.error('Failed to confirm wiki deletion', error);
              showSonnerToast(t('documents.popup.deleteError'), false);
            }
          },
        },
      ],
    });

    await alert.present();
  };

  const _replaceFromDocBrowser = async (wiki: WikiModel): Promise<boolean> => {
    try {
      const result = await componentDocsAttachment(null);

      if (result.data !== undefined) {
        const data = result.data as DocEntity[];

        data.forEach(async (element) => {
          if (element.documentType === DocumentTypeEnum.Wiki) {
            await wikiStore.deleteWithReplace(wiki.id, element.data.id, null);
          } else {
            await wikiStore.deleteWithReplace(wiki.id, null, element.data.id);
          }
        });
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const _replaceWithNewWiki = async (wiki: WikiModel): Promise<boolean> => {
    const result = await componentWikiCreate(wiki.group?.id, wiki?.parentFolderId ?? undefined, false);

    if (result) {
      await wikiStore.deleteWithReplace(wiki.id, result, null);
    }

    return false;
  };

  const _replaceWithNewFile = async (wiki: WikiModel): Promise<boolean> => {
    const result = await componentDocsCreateFile();

    if (result !== null) {
      await wikiStore.deleteWithReplace(wiki.id, null, result.id);
    }

    return false;
  };

  const _replace = async (wiki: WikiModel): Promise<boolean> => {
    const result = await wikiReplaceActionsMenu();

    if (result.data !== undefined) {
      switch (result.data) {
        case WikiDeleteOptionsEnum.ReplaceFromDocBrowser:
          await _replaceFromDocBrowser(wiki);
          break;
        case WikiDeleteOptionsEnum.ReplaceWithNewWiki:
          await _replaceWithNewWiki(wiki);
          break;
        case WikiDeleteOptionsEnum.ReplaceWithNewFile:
          await _replaceWithNewFile(wiki);
          break;
      }
    }

    return false;
  };

  const _editTitle = async (): Promise<void> => {
    const result = await componentTitleChange(null, draftWiki.value?.name || existingWiki.value?.name || '');
    if (result.data?.title) {
      wikiStore.$patch((state) => {
        state.hasChanged = true;
        state.editForm.name = result.data?.title ?? '';
      });
    }
  };

  const _showGroup = async () => {
    logInfo('showGroup'); //TODO
  };

  const _showFolder = async () => {
    logInfo('showFolder'); //TODO
  };

  const _showAuthor = async () => {
    logInfo('showAuthor'); //TODO
  };

  const _showLastEditor = async () => {
    logInfo('showLastEditor'); //TODO
  };

  const _goToCurrentVersion = async () => {
    wikiStore.$patch((state: WikiState) => {
      state.historicalWiki = null;
      state.isOutdated = false;
      state.versionId = null;
    });
  };

  const _getPreparedWikiTemplateData = (editForm: WikiEditFormModel): CreateWikiTemplateModel | null => {
    if (isAdvancedModeOn.value && editForm.wikiContent) {
      return {
        name: '',
        content: pick(editForm.wikiContent, ['body', 'head', 'content']),
      };
    } else if (editForm.wikiText) {
      /* return {name: '', text: editForm.wikiText} */
      console.error('Simple-wiki templates is not supported');
      return null;
    } else {
      console.error('No data to create template');
      return null;
    }
  };

  const _saveAsTemplate = async () => {
    const templateData = _getPreparedWikiTemplateData(wikiStore.editForm);
    if (!templateData) return;

    const result = await componentWikiTemplateSaveModal(templateData);

    if (result.data) {
      showSonnerToast(t('wiki.templates.save.success'), true);
    } else if (result.data === false) {
      showSonnerToast(t('wiki.templates.save.error'), false);
    }
  };

  const _updateTemplate = async () => {
    let selectedTemplate: WikiTemplateModel | undefined;
    const templateData = _getPreparedWikiTemplateData(wikiStore.editForm);
    if (!templateData) return;

    const alert = await alertController.create({
      header: t('wiki.templates.update.title'),
      message: t('wiki.templates.update.confirm'),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          handler: () => {
            updateTemplate();
          },
        },
      ],
    });

    const result = await componentWikiTemplatesModal(t('wiki.templates.update.title'));
    if (result.data) {
      selectedTemplate = result.data;
      await alert.present();
    } else {
      selectedTemplate = undefined;
      return;
    }

    const updateTemplate = async () => {
      if (selectedTemplate && templateData) {
        const result = await wikiStore.updateTemplateById({
          ...selectedTemplate,
          ...templateData,
        });
        if (result) {
          showSonnerToast(t('wiki.templates.update.success'), true);
        } else {
          showSonnerToast(t('wiki.templates.update.error'), false);
        }
      } else {
        console.error('Error while updating template');
      }
    };
  };

  const _chooseTemplate = async () => {
    let selectedTemplate: WikiTemplateModel | undefined;

    const alert = await alertController.create({
      header: t('wiki.templates.choose.title'),
      message: t('wiki.templates.choose.confirm'),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          handler: () => {
            applyTemplate();
          },
        },
      ],
    });

    const result = await componentWikiTemplatesModal(t('wiki.templates.choose.title'));
    if (result.data) {
      selectedTemplate = result.data;
      await alert.present();
    } else {
      selectedTemplate = undefined;
    }

    const applyTemplate = async () => {
      if (!selectedTemplate) {
        console.error('Selected template is not defined');
        return;
      }

      selectedTemplate = await wikiStore.templateById(selectedTemplate.id);
      if (selectedTemplate?.wikiContent) {
        wikiStore.setAdvancedWikiContent({
          ...selectedTemplate.wikiContent,
          participants: cloneDeep(defaultAdvancedWikiParticipantsModel),
        });
        showSonnerToast(t('wiki.templates.choose.success'), true);
      } else {
        console.error('Selected template content is not defined');
        showSonnerToast(t('wiki.templates.choose.error'), false);
      }
    };
  };

  const _deleteTemplate = async (template: WikiTemplateModel): Promise<void> => {
    const alert = await alertController.create({
      header: t('wiki.templates.delete.title'),
      message: t('wiki.templates.delete.confirm', { name: template.name }),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          handler: () => {
            deleteTemplate();
          },
        },
      ],
    });

    await alert.present();

    const deleteTemplate = async () => {
      const result = await wikiStore.deleteTemplateById(template.id);
      if (result) {
        showSonnerToast(t('wiki.templates.delete.success'), true);
      } else {
        showSonnerToast(t('wiki.templates.delete.error'), false);
      }
    };
  };

  const _deleteDraft = async (): Promise<void> => {
    const draftId = draftWiki.value?.id ?? existingWiki.value?.tempWikiId ?? null;
    if (!draftId) {
      console.error('Error during deleting temp wiki: no temp wiki id');
      return;
    }

    const isDeleted = await wikiStore.deleteDraftById(draftId);

    if (!isDeleted) {
      console.error('Error during deleting temp wiki');
      return;
    }

    wikiStore.resetEditForm();
    wikiStore.setSimpleWiki('name', existingWiki.value?.name || '');

    if (isSimple.value) {
      const preprocessedBody = await useRichTextEditor().preprocessBody(existingWiki.value?.wikiText ?? '');
      wikiStore.setSimpleWiki('text', preprocessedBody);
      wikiStore.setSimpleContentToBody(preprocessedBody);
    } else {
      if (existingWiki.value?.wikiContent) {
        wikiStore.setAdvancedWiki(existingWiki.value);
      }
    }

    showSonnerToast(t('wiki.editMessages.draftDeletedSuccess'), true);
    wikiStore.$patch((state) => {
      state.isDraftDeleted = false;
    });
  };

  const _updateExistingWiki = async (payload: UpdateWikiPayload): Promise<boolean> => {
    const result = await wikiStore.update({
      wikiId: wikiStore.existingWiki?.id || 0,
      mentionedUserIds: wikiStore.editForm.mentionedUserIds ?? [], //?: Is this correct?
      ...payload,
    });

    if (!result) {
      showSonnerToast(t('wiki.editMessages.updateError'), false);
      return false;
    }

    showSonnerToast(t('wiki.editMessages.updatedSuccess'), true);
    wikiStore.$patch((state) => {
      state.draftWiki = null;
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await leaveEditPage(payload.wikiId!);
    return true;
  };

  const _onNewWikiCreate = async (payload: CreateWikiPayload): Promise<boolean> => {
    const createdId = await wikiStore.create(payload);

    if (!createdId) {
      console.error('Error during creating wiki');
      showSonnerToast(t('wiki.editMessages.createError'), false);
      return false;
    }

    showSonnerToast(t('wiki.editMessages.createSuccess'), true);
    await leaveEditPage(createdId);
    return true;
  };

  const _saveWiki = async (saveOption: WikiSaveModeEnum): Promise<boolean> => {
    if (saveOption === WikiSaveModeEnum.Minor) {
      currentSaveMode.value = WikiSaveModeEnum.Minor;
    }

    wikiStore.$patch((state) => {
      state.saveLoading = true;
    });

    try {
      const reason = preventSave();
      if (reason) {
        showSonnerToast(reason, false);
        return false;
      }

      const advancedContent = {
        head: editForm.value.wikiContent.head,
        content: editForm.value.wikiContent.content,
        body: editForm.value.wikiContent.body,
        participants: {
          name: editForm.value.wikiContent.participants.name,
          participantsIds: editForm.value.participantsIds,
          settings: {
            allUsers: false,
            isVisible: true,
            isDeleted: false,
          },
        },
      };

      const payload: CreateWikiPayload = {
        name: editForm.value.name,
        groupId: selectedGroup.value?.id || editForm.value.groupId,
        folderId: resetActiveFolderFlag.value
          ? selectedFolder.value?.id || null
          : selectedFolder.value?.id || editForm.value.folderId,
        isMajor: currentSaveMode.value !== WikiSaveModeEnum.Minor,
        text: !isAdvancedModeOn.value ? editForm.value.wikiText : null,
        content: isAdvancedModeOn.value ? advancedContent : null,
        participantsIds: editForm.value.participantsIds,
        accessOnlyForGroupId: null,
        // accessOnlyForGroupId: selectedGroup.value?.id || groupId.value, //TODO: Add real functionality
        mentionedUserIds: wikiStore.editForm.mentionedUserIds ?? [], //?: Is this correct?
      };

      if (existingId.value) {
        return await _updateExistingWiki(payload);
      }

      return await _onNewWikiCreate(payload);
    } catch (error) {
      console.error('Failed to save wiki', error);
      return false;
    } finally {
      wikiStore.$patch((state) => {
        state.saveLoading = false;
      });
    }
  };

  const _edit = async (wiki: WikiModel | undefined): Promise<void> => {
    if (!wiki) {
      console.error('Wiki is not defined');
      return;
    }

    if (await shouldPreventWikiEdit()) {
      return;
    }

    await shouldPreventWikiEdit();
    wikiStore.$patch((state: WikiState) => {
      state.editForm.groupId = wiki.group?.id ?? null;
      state.editForm.folderId = wiki.parentFolderId ?? null;
    });

    await router.push({
      name: ROUTES_NAME.WIKI_EDIT,
      params: { id: wiki.id },
    });
  };

  const _move = async (id: number): Promise<void> => {
    try {
      const result = await componentDocsMoveFile(null);
      if (!result.data) {
        console.error('No folder selected');
        return;
      }

      await docStore.moveWiki(result.data.folderId, result.data.groupId, id);

      showSonnerToast(t('wiki.menuActions.move.success'), true);
      await wikiStore.getWikiById(id);
    } catch (error) {
      console.error('Failed to move wiki', error);
      showSonnerToast(t('wiki.menuActions.move.error'), false);
    }
  };

  const _markOfficial = async (id: number): Promise<void> => {
    await wikiStore.markOfficial(id);
  };

  const _showRelations = async (id: number): Promise<void> => {
    await wikiStore.getRelations(id);
    await componentWikiRelations();
  };

  const _follow = async (id: number): Promise<void> => {
    await wikiStore.follow(id);
  };

  const _unfollow = async (id: number): Promise<void> => {
    await wikiStore.unfollow(id);
  };

  const _showFollowers = async (id: number): Promise<void> => {
    await wikiStore.getFollowers(id);
    await componentWikiFollowersModal();
  };

  const _rollbackVersion = async (id: number | undefined, versionId: number | undefined): Promise<void> => {
    if (!id || !versionId) {
      console.error('Failed to rollback wiki: id or versionId is not defined');
      return;
    }

    try {
      await wikiStore.rollback(id, versionId);
      showSonnerToast(t('wiki.menuActions.rollback.success'), true);
    } catch (error) {
      console.error('Failed to rollback wiki', error);
      showSonnerToast(t('wiki.menuActions.rollback.error'), false);
    }
  };

  const _download = async (wiki: WikiModel | undefined, documentExtension: DocumentExtensionEnum): Promise<void> => {
    if (!wiki) {
      console.error('Wiki is not defined');
      return;
    }

    const response = await wikiStore.download(wiki.id, documentExtension);
    if (isBlob(response)) {
      const result = await filesHybrid.downloadWiki(wiki, response as Blob);
      showSonnerToast(t('files.successDownloaded'), result === FileStatusEnum.Success);
    } else {
      console.error('Error response:', response);
    }
  };

  const _updateDraft = async (): Promise<boolean> => {
    if (!wikiStore.hasChanged) {
      return false;
    }

    // We should not block the user from saving the draft with uncompleted fields
    // Only saving the actual wiki should be blocked
    // const reason = preventSave();
    // if (reason) {
    //   // showSonnerToast(reason, false, false, undefined, 'prevent_wiki_save'); //?: Should we show this message?
    //   return false;
    // }

    const advancedContent = {
      head: editForm.value.wikiContent.head,
      content: editForm.value.wikiContent.content,
      body: editForm.value.wikiContent.body,
      participants: {
        name: editForm.value.wikiContent.participants.name,
        participantsIds: editForm.value.participantsIds,
        settings: {
          allUsers: false,
          isVisible: true,
          isDeleted: false,
        },
      },
    };

    const payload: UpdateWikiPayload = {
      wikiId: wikiStore.existingWiki?.id || null,
      tempWikiId: wikiStore.draftWiki?.id || null,
      // revisionId: wikiStore.revisionId || null, //TODO: Add real functionality
      name: editForm.value.name,
      groupId: selectedGroup.value?.id || editForm.value.groupId,
      folderId: resetActiveFolderFlag.value
        ? selectedFolder.value?.id || null
        : selectedFolder.value?.id || editForm.value.folderId,
      isMajor: currentSaveMode.value !== WikiSaveModeEnum.Minor,
      text: !isAdvancedModeOn.value ? editForm.value.wikiText : null,
      content: isAdvancedModeOn.value ? advancedContent : null,
      participantsIds: editForm.value.participantsIds,
      accessOnlyForGroupId: null,
      // accessOnlyForGroupId: selectedGroup.value?.id || editForm.value.groupId, //TODO: Add real functionality
      mentionedUserIds: wikiStore.editForm.participantsIds, //?: Is this correct?
    };

    const result = await wikiStore.updateCurrentDraft(payload);
    if (!result) {
      showSonnerToast(t('wiki.editMessages.updateError'), false);
    }
    return result;
  };

  //* Public methods
  const getActionsMenuItems = (wiki: WikiModel): WikiActionsMenuModel[] => {
    const menuItems = [
      {
        title: t(`wiki.menuActions.edit`),
        value: WikiMenuActionEnum.Edit,
        icon: AppIconsEnum.PencilSquare,
        svgIconPath: PencilSquare,
        disabled: !wiki.access.includes(ActionAccessEnum.Edit),
      },
      {
        title: t(`files.menu.downloadAsPDF`),
        value: WikiDownloadOptionsEnum.DownloadAsPDF,
        icon: AppIconsEnum.Download,
        svgIconPath: Download,
        disabled: false,
      },
      {
        title: t(`files.menu.downloadAsDOCX`),
        value: WikiDownloadOptionsEnum.DownloadAsDOCX,
        icon: AppIconsEnum.Download,
        svgIconPath: Download,
        disabled: false,
      },
      {
        title: wiki.isOfficial ? t(`wiki.menuActions.unmarkOfficial`) : t(`wiki.menuActions.markOfficial`),
        value: WikiMenuActionEnum.MarkOfficial,
        icon: wiki.isOfficial ? AppIconsEnum.Star : AppIconsEnum.StarOutline,
        svgIconPath: wiki.isOfficial ? Star : StarO,
        disabled: !wiki.access.includes(ActionAccessEnum.MarkAsOfficial),
      },
      {
        title: t(`wiki.menuActions.showRelations`),
        value: WikiMenuActionEnum.ShowRelations,
        // icon: AppIconsEnum.Unlink, //TODO: Change icon
        // icon: AppIconsEnum.Link, //TODO: Change icon
        icon: AppIconsEnum.GitlabLink,
        svgIconPath: GitlabLink,
        disabled: true, //TODO: Temporary disabled
      },
      {
        title: wiki.isFollowed ? t(`subscribe.unfollow`) : t(`subscribe.follow`),
        value: wiki.isFollowed ? WikiMenuActionEnum.Unfollow : WikiMenuActionEnum.Follow,
        icon: wiki.isFollowed ? AppIconsEnum.NotificationsOff : AppIconsEnum.Notifications,
        svgIconPath: wiki.isFollowed ? NotificationsOff : Notifications,
        disabled: false,
      },
      {
        title: t(`wiki.menuActions.showFollowers`),
        value: WikiMenuActionEnum.ShowFollowers,
        icon: AppIconsEnum.Users,
        svgIconPath: Users,
        disabled: false,
      },
      /*
      {
        title: t(`wiki.menuActions.compare`),
        value: WikiMenuActionEnum.CompareHistorical,
        icon: AppIconsEnum.Comparison,
        disabled: false,
      },
      */
      {
        title: t(`wiki.menuActions.move.title`),
        value: WikiMenuActionEnum.Move,
        icon: AppIconsEnum.Move,
        svgIconPath: Move,
        disabled: !wiki.access.includes(ActionAccessEnum.Move),
      },
      {
        title: t(`wiki.menuActions.showHistory`),
        value: WikiMenuActionEnum.ShowHistory,
        icon: AppIconsEnum.DocVersions,
        svgIconPath: DocVersions,
        disabled: false, //TODO: Should be disabled if existingWiki has no history wikiStore.hasHistory
      },
      {
        title: t(`wiki.menuActions.rollback.title`),
        value: WikiMenuActionEnum.RollbackVersion,
        icon: AppIconsEnum.History,
        svgIconPath: History,
        disabled: !wikiStore.historicalWiki && !wikiStore.isOutdated,
      },
      {
        title: t(`wiki.menuActions.delete`),
        value: WikiMenuActionEnum.Delete,
        icon: AppIconsEnum.Remove,
        svgIconPath: Remove,
        disabled: false,
      },
    ] as WikiActionsMenuModel[];
    return menuItems.filter(({ disabled }) => !disabled);
  };

  const getEditOptions = (mode: EditModeEnum): EditOptions[] => {
    const options = [
      {
        title: t('wiki.editOptions.title'),
        action: WikiEditOptionsEnum.EditTitle,
        value: editForm.value.name || t('titleChangeModal.placeholder.title'),
      },
      {
        title: t('wiki.editOptions.group'),
        // action: WikiEditOptionsEnum.EditGroup, //TODO: Implement group change
        action: WikiEditOptionsEnum.None,
        value: wikiStore.getGroupTitle,
      },
      {
        title: t('wiki.editOptions.folder'),
        // action: WikiEditOptionsEnum.EditFolder, //TODO: Implement folder change
        action: WikiEditOptionsEnum.None,
        value: wikiStore.getFolderTitle,
      },
      {
        title: t('wiki.editOptions.author'),
        // action: WikiEditOptionsEnum.ShowAuthor, //TODO: Implement author navigation
        action: WikiEditOptionsEnum.None,
        value: mode === EditModeEnum.Edit ? (wikiStore.getCreatedBy?.fullName ?? '') : '',
      },
      {
        title: t('wiki.editOptions.createdAt'),
        action: WikiEditOptionsEnum.None,
        value: mode === EditModeEnum.Edit ? formatDateHelper(wikiStore.getCreationDate, 'long') : '',
      },
      {
        title: t('wiki.editOptions.lastEditedBy'),
        // action: WikiEditOptionsEnum.ShowLastEditor, //TODO: Implement last editor navigation
        action: WikiEditOptionsEnum.None,
        value: mode === EditModeEnum.Edit ? (wikiStore.getLastEditor?.fullName ?? '') : '',
      },
      {
        title: t('wiki.editOptions.lastEditedAt'),
        action: WikiEditOptionsEnum.None,
        value: mode === EditModeEnum.Edit ? formatDateHelper(wikiStore.getLastEditDate, 'long') : '',
      },
      {
        title: t('wiki.editOptions.outdated'),
        action: WikiEditOptionsEnum.Outdated,
        // value: wikiStore.isOutdated ? t('wiki.editOptions.outdated') : '',
        value: mode === EditModeEnum.Edit ? (wikiStore.isOutdated ? t('wiki.editOptions.outdated') : '') : '',
      },
    ];

    return options.filter(({ action, value }) => action === WikiEditOptionsEnum.EditTitle || value !== '');
  };

  const editOptionsAction = async (action: WikiEditOptionsEnum): Promise<void> => {
    switch (action) {
      case WikiEditOptionsEnum.EditTitle:
        await _editTitle();
        break;
      case WikiEditOptionsEnum.EditGroup:
        await _showGroup();
        break;
      case WikiEditOptionsEnum.EditFolder:
        await _showFolder();
        break;
      case WikiEditOptionsEnum.ShowAuthor:
        await _showAuthor();
        break;
      case WikiEditOptionsEnum.ShowLastEditor:
        await _showLastEditor();
        break;
      case WikiEditOptionsEnum.None:
        break;
    }
  };

  const getEditControls = (mode: EditModeEnum): EditControls[] => {
    const controls = [
      {
        title: t('wiki.editControls.goToCurrentVersion'),
        icon: icons.eye,
        /**
         *
        disabled:
          mode === EditModeEnum.Edit
            ? draftWiki.value?.realWikiId !== existingWiki.value?.id
            : true,
        */
        disabled: true, //TODO: Temporary disabled
        value: WikiEditControlsEnum.GoToCurrentVersion,
        isDropdown: false,
      },
      //NOTE: This control is not used in the app
      /* {
        title: t('wiki.editControls.updateDraft'),
        icon: icons.save,
        disabled: !draftWiki.value,
        value: WikiEditControlsEnum.UpdateDraft,
        isDropdown: false,
      }, */
      {
        title: t('wiki.editControls.deleteDraft'),
        icon: icons.doc,
        disabled: !draftWiki.value,
        value: WikiEditControlsEnum.DeleteDraft,
        isDropdown: false,
      },
      {
        title: t('wiki.editControls.deleteNote'),
        icon: icons.trash,
        disabled: mode !== EditModeEnum.Edit && !existingId.value,
        value: WikiEditControlsEnum.Delete,
        isDropdown: false,
      },
      {
        title: t('wiki.templates.title'),
        icon: icons.templates,
        disabled: !isAdvancedModeOn.value,
        value: WikiEditControlsEnum.TemplatesMenu,
        isDropdown: true,
      },
      //TODO: lockEdit
      //TODO: unlockEdit
    ];

    return controls.filter(({ disabled }) => !disabled);
  };

  const editControlsAction = async (
    action: BasicEditControlsEnum | WikiEditControlsEnum,
    ev?: Event
  ): Promise<void> => {
    switch (action) {
      case BasicEditControlsEnum.Cancel:
        await leaveEditPage();
        break;
      case BasicEditControlsEnum.Default:
        await _saveWiki(WikiSaveModeEnum.Major);
        break;
      case BasicEditControlsEnum.Optional:
        await _saveWiki(WikiSaveModeEnum.Minor);
        break;
      case WikiEditControlsEnum.GoToCurrentVersion:
        await _goToCurrentVersion();
        break;
      case WikiEditControlsEnum.SaveAsTemplate:
        await _saveAsTemplate();
        break;
      case WikiEditControlsEnum.UpdateTemplate:
        await _updateTemplate();
        break;
      case WikiEditControlsEnum.UpdateDraft:
        debouncedUpdateDraft();
        break;
      case WikiEditControlsEnum.DeleteDraft:
        await _deleteDraft();
        break;
      case WikiEditControlsEnum.Delete:
        await deleteWiki(existingWiki.value ?? undefined);
        break;
      case WikiEditControlsEnum.TemplatesMenu:
        {
          const items = getWikiTemplatesMenuItems();
          const result = items.length > 1 ? await wikiTemplatesMenuPopover(ev, items) : { data: items[0].value };
          if (result.data) {
            switch (result.data) {
              case WikiEditControlsEnum.SaveAsTemplate:
                await _saveAsTemplate();
                break;

              case WikiEditControlsEnum.ChooseTemplate:
                await _chooseTemplate();
                break;

              case WikiEditControlsEnum.UpdateTemplate:
                await _updateTemplate();
                break;

              default:
                break;
            }
          }
        }
        break;
    }
  };

  const openWiki = async (id: number, versionId?: number): Promise<void> => {
    await shouldPreventWikiEdit();
    await router.push({
      name: ROUTES_NAME.WIKI_BY_ID,
      params: { id: id, versionId: versionId },
    });
  };

  const compareHistorical = async (sourceId?: number, ids?: number[], fromComparePage = false): Promise<void> => {
    if (!sourceId && !ids) {
      console.error('Failed to compare historical: sourceId or ids are not defined');
      return;
    }

    let tId = 0;
    let sId = 0;

    if (ids) {
      tId = ids[0];
      sId = ids[1];
      wikiStore.$patch((state) => {
        state.targetVersion = wikiStore.getHistoryWikiById(tId);
        state.sourceVersion = wikiStore.getHistoryWikiById(sId);
        state.comparableWikiName = state.existingWiki?.name ?? '';
      });
    } else if (sourceId) {
      tId = wikiStore.history[0].id;
      sId = sourceId;
      wikiStore.$patch((state) => {
        state.targetVersion = wikiStore.getHistoryWikiById(tId);
        state.sourceVersion = wikiStore.getHistoryWikiById(sId);
        state.comparableWikiName = state.existingWiki?.name ?? '';
      });
    }

    if (!tId || !sId) {
      console.error('Failed to compare historical: tId or sId is not defined');
      return;
    }

    if (!fromComparePage) {
      await router.push({
        name: ROUTES_NAME.WIKI_COMPARE,
        params: { id: existingWiki.value?.id },
        query: {
          targetId: tId,
          sourceId: sId,
        },
      });
    }
  };

  //TODO: Make a modal with checkboxes for delete options
  const deleteWiki = async (wiki?: WikiModel, id?: number): Promise<void> => {
    if (!wiki?.id && !id) {
      console.error('Wiki id is not defined');
      return;
    }

    try {
      if (!wiki?.id && id) {
        await _confirmDelete(undefined, id);
      } else if (wiki?.id) {
        const optionsAlert = await alertController.create({
          message: `${t('documents.popup.deleteWiki')} <strong>${wiki?.name ?? id}</strong>?`,
          buttons: [
            {
              text: t('documents.popup.deleteWithAllRelations'),
              cssClass: 'custom-alert-buttons',
              handler: async () => {
                await _confirmDelete(wiki.id, undefined);
              },
            },
            {
              text: t('documents.popup.deleteWithReplace'),
              cssClass: 'custom-alert-buttons',
              handler: async () => {
                try {
                  await _replace(wiki);
                  showSonnerToast(t('documents.popup.deleteSuccess'), true);
                  await alertController.dismiss();
                } catch (error) {
                  console.error('Failed to delete wiki', error);
                  showSonnerToast(t('documents.popup.deleteError'), false);
                }
              },
            },
          ],
        });

        await optionsAlert.present();
      }
    } catch (error) {
      console.error('Failed to delete wiki', error);
    }
  };

  const whichActionToMake = async (payload: WhichActionToMakePayload, fromDocBrowser?: boolean): Promise<void> => {
    console.log('Action:', payload); //! DEBUG

    const finalId = payload.id || payload.wiki?.id || payload.versionId;
    if (!finalId) {
      console.error('Action cannot be performed: finalId resolved to null');
      return;
    }
    console.log('Final id:', finalId); //! DEBUG

    if (payload.action !== WikiMenuActionEnum.MarkOfficial) {
      try {
        const topModal = await modalController.getTop();
        if (topModal && topModal.id === 'wiki_actions') {
          await modalController.dismiss(null, 'end', 'wiki_actions');
        }
      } catch (error) {
        console.error('Error dismissing modal:', error);
      }
    }

    try {
      switch (payload.action) {
        case WikiMenuActionEnum.Open:
          await openWiki(finalId);
          break;
        case WikiMenuActionEnum.ShowHistory:
          await showHistory(finalId, fromDocBrowser, false);
          break;
        case WikiMenuActionEnum.RollbackVersion:
          await _rollbackVersion(payload.wiki?.id, payload.versionId);
          break;
        case WikiMenuActionEnum.CompareHistorical:
          await compareHistorical(finalId);
          break;
        case WikiMenuActionEnum.Edit:
          await _edit(payload.wiki);
          break;
        case WikiMenuActionEnum.Move:
          await _move(finalId);
          break;
        case WikiMenuActionEnum.MarkOfficial:
          await _markOfficial(finalId);
          break;
        case WikiMenuActionEnum.ShowRelations:
          await _showRelations(finalId);
          break;
        case WikiMenuActionEnum.LockEdit:
          //TODO: await _lockEdit(existingWiki.value);
          break;
        case WikiMenuActionEnum.UnlockEdit:
          //TODO: await _unlockEdit(existingWiki.value);
          break;
        case WikiDownloadOptionsEnum.DownloadAsPDF:
          await _download(payload.wiki, DocumentExtensionEnum.PDF);
          break;
        case WikiDownloadOptionsEnum.DownloadAsDOCX:
          await _download(payload.wiki, DocumentExtensionEnum.DOCX);
          break;
        case WikiMenuActionEnum.Delete:
          await deleteWiki(payload.wiki, finalId);
          break;
        case WikiMenuActionEnum.ShowFollowers:
          await _showFollowers(finalId);
          break;
        case WikiMenuActionEnum.Follow:
          await _follow(finalId);
          break;
        case WikiMenuActionEnum.Unfollow:
          await _unfollow(finalId);
          break;
        default:
          console.error('Unknown action:', payload.action);
          return;
      }
    } catch (error) {
      console.error('Failed to perform action:', payload.action, error);
    }
  };

  const openActionsMenu = async (wiki: WikiModel): Promise<void> => {
    if (isNativeMobile) {
      const result = await docBrowserContextMenuSheet({
        documentType: DocumentTypeEnum.Wiki,
        data: wiki,
      });
      if (result.data !== undefined) {
        await whichActionToMake({
          action: result.data,
          wiki: wiki,
        });
      }
    } else {
      await wikiActionsMenu(wiki);
    }
  };

  const createWiki = async (): Promise<void> => {
    try {
      await shouldPreventWikiEdit();

      await router.push({
        name: ROUTES_NAME.WIKI_CREATE,
      });
    } catch (error) {
      console.error('Failed to go to wiki edit page', error);
    }
  };

  //! addRelation

  //! removeRelation

  //! getHistoryById

  //! getHistoryByDate

  //! updateHistoricalWiki

  const getHistoricalButtons = (version: WikiHistoryModel, mode: DataViewMode): HistoricalButtons => {
    return {
      left: {
        title: t('wiki.menuActions.rollback.title'),
        action: WikiMenuActionEnum.RollbackVersion,
        type: 'main',
        icon: AppIconsEnum.History,
        showIcon: mode === DataViewMode.List,
        showTooltip: mode === DataViewMode.List,
        showTitle: false,
      },
      right: {
        title: t('wiki.menuActions.compare'),
        action: WikiMenuActionEnum.CompareHistorical,
        type: 'main',
        icon: AppIconsEnum.Comparison,
        showIcon: mode === DataViewMode.List,
        showTooltip: mode === DataViewMode.List,
        showTitle: false,
      },
    };
  };

  const getHistoricalModifiedByAvatar = (wiki: WikiHistoryModel): MediaModel | null => {
    if (wiki.modifyUserId) {
      const uId = wiki.modifyUserId;
      const user = userStore.getUserProfile(uId);
      return user?.avatar;
    }
    return null;
  };

  const goToHistoricalWiki = async (id: number, date: string): Promise<void> => {
    try {
      const closestToDate = await wikiStore.getHistoricalWikiByDate(id, date, WikiMajorFilterEnum.All);

      if (!closestToDate) {
        console.error('Failed to get historical wiki by date');
        return;
      }

      await wikiStore.getWikiById(id);
      wikiStore.setHistoricalWiki(closestToDate);

      wikiStore.$patch((state) => {
        state.versionId = closestToDate.id;
      });

      if (routeName.value !== ROUTES_NAME.WIKI_BY_ID) {
        await router.push({
          name: ROUTES_NAME.WIKI_BY_ID,
          // params: { id: id, versionId: closestToDate.id },
          params: { id: id },
        });
      }
    } catch (error) {
      console.error('An error occurred while navigating to historical wiki:', error);
    }
  };

  async function shouldPreventWikiEdit(): Promise<boolean> {
    const id: number = existingWiki.value?.id || existingId.value || 0;

    if (!id) {
      logErr('No wiki found. Proceeding...', new Error('No wiki found while trying to prevent wiki edit'));
      return false;
    }

    // Check for lock
    const lockStatus = await wikiStore.lockEdit(id);
    if (!lockStatus) {
      //TODO: Pass currentEditor?.firstLastName to the alert
      // const name = currentEditor?.firstLastName || 'Someone';
      const name = 'Someone';
      const isLocked = await alertController.create({
        header: t('wiki.preventAlert.lock.title'),
        message: t('wiki.preventAlert.lock.message', name),
        buttons: [
          {
            text: 'Ok',
            role: 'confirm',
            cssClass: 'custom-alert-buttons',
            handler: async () => {
              await alertController.dismiss();
            },
          },
        ],
      });

      await isLocked.present();
      return true;
    }

    // Check for isAdvanced on/off
    if (!existingWiki.value && existingId.value) {
      await wikiStore.getWikiById(existingId.value);
    }

    const chosenWikiIsAdvanced = existingWiki.value?.version === WikiVersionEnum.V2;
    if (!isAdvancedModeOn.value && chosenWikiIsAdvanced) {
      const isAdvanced = await alertController.create({
        header: t('wiki.preventAlert.advanced.title'),
        message: t('wiki.preventAlert.advanced.message'),
        buttons: [
          {
            text: 'Ok',
            role: 'confirm',
            cssClass: 'custom-alert-buttons',
            handler: async () => {
              await alertController.dismiss();
            },
          },
        ],
      });

      await isAdvanced.present();
      return true;
    }

    return false;
  }

  function preventSave(): string | false {
    const simpleFields = [editForm.value.name, editForm.value.wikiText];
    const simpleWikiErrorCase = !isAdvancedModeOn.value && isSimple.value && simpleFields.some((f) => !f);
    logInfo(`SimpleWikiErrorCase: ${simpleWikiErrorCase}`); //! DEBUG

    const advancedFields = [
      editForm.value.wikiContent.head?.name,
      editForm.value.wikiContent.head?.text,
      editForm.value.wikiContent.content?.name,
      // editForm.value.advancedWikiModel.content?.text,
      ...editForm.value.wikiContent.body.map((item) => item.name || item.text),
      editForm.value.wikiContent.participants?.name,
    ];
    const advancedWikiErrorCase = isAdvancedModeOn.value && isAdvanced.value && advancedFields.some((f) => !f);
    logInfo(`AdvancedWikiErrorCase: ${advancedWikiErrorCase}`); //! DEBUG

    const advancedObj = {
      headName: editForm.value.wikiContent.head?.name,
      headText: editForm.value.wikiContent.head?.text,
      contentName: editForm.value.wikiContent.content?.name,
      // contentText: editForm.value.advancedWikiModel.content?.text,
      bodyNames: editForm.value.wikiContent.body.map((item) => item.name),
      bodyTexts: editForm.value.wikiContent.body.map((item) => item.text),
      participantsName: editForm.value.wikiContent.participants?.name,
    };

    /* NOTE: Log errors */
    if (simpleWikiErrorCase) {
      console.warn(`
        Error during saving simple wiki:\n
        name: ${editForm.value.name.length > 0 ? '✅' : '❌'}
        text: ${editForm.value.wikiText.length > 0 ? '✅' : '❌'}
        `);
    } else if (advancedWikiErrorCase) {
      console.warn(`
        Error during saving advanced wiki:\n
        headName: ${advancedObj.headName.length > 0 ? '✅' : '❌'}
        headText: ${advancedObj.headText.length > 0 ? '✅' : '❌'}
        contentName: ${advancedObj.contentName.length > 0 ? '✅' : '❌'}
        bodyNames: ${advancedObj.bodyNames.every((n) => n.length > 0) ? '✅' : '❌'}
        bodyTexts: ${advancedObj.bodyTexts.every((t) => t.length > 0) ? '✅' : '❌'}
        participantsName: ${advancedObj.participantsName.length > 0 ? '✅' : '❌'}
        `);
      // contentText: ${advancedObj.contentText.length > 0 ? '✅' : '❌'} //TODO: It should be checked later
    }

    /* NOTE: Error if name is empty */
    if (!editForm.value.name) {
      return `Name is required: ${editForm.value.name}`;
    }

    /* NOTE: Error if isSimple AND wikiText is empty */
    if (simpleWikiErrorCase && !editForm.value.wikiText) {
      return `Text is required: ${editForm.value.wikiText}`;
    }

    /* NOTE: Error if isAdvanced AND any of the advanced fields is empty */
    if (advancedWikiErrorCase) {
      switch (false) {
        case advancedObj.headName.length > 0:
          return 'Head title is required';
        case advancedObj.headText.length > 0:
          return 'Head text is required';
        case advancedObj.contentName.length > 0:
          return 'Content title is required';
        // case advancedObj.contentText.length > 0:
        //   return 'Content text is required: ${advancedObj.contentText}`;
        case advancedObj.bodyNames.every((n) => n.length > 0):
          return 'Body title is required';
        case advancedObj.bodyTexts.every((t) => t.length > 0):
          return 'Body text is required';
        case advancedObj.participantsName.length > 0:
          return 'Participants title is required';
      }
    }

    return false;
  }

  async function leaveEditPage(id?: number): Promise<void> {
    try {
      if (isModalMode.value) {
        await modalController.dismiss(id);
        wikiStore.$patch((state) => {
          state.isModalMode = false;
        });
        return;
      }

      if (!existingId.value && !newId.value) {
        router.back();
        return;
      }

      await router.push({
        name: ROUTES_NAME.WIKI_BY_ID,
        params: { id: existingId.value ?? newId.value },
      });
    } catch (error) {
      console.error('Failed to leave edit page:', error);
    } finally {
      wikiStore.resetEditForm();
      wikiStore.resetWikiFromAi();
    }
  }

  const debouncedUpdateDraft = debounce(async (): Promise<boolean> => {
    return _updateDraft();
  }, appDebounce * 5);

  async function checkIfOutdated(wiki: WikiModel, date?: string): Promise<boolean> {
    let result = false;

    try {
      const latest = await wikiStore.getHistoricalWikiByDate(
        wiki.id,
        new Date().toISOString(),
        WikiMajorFilterEnum.All
      );

      if (!latest) {
        console.error('Error during checking if outdated: no latest wiki');
        return result;
      }

      const checkDate = new Date(date ?? wiki.editedAt).getTime();
      const latestEditDate = new Date(latest.editedAt).getTime();

      result = checkDate < latestEditDate;

      if (historicalWiki.value) {
        wikiStore.$patch((state) => {
          state.isOutdated = result;
        });
      }
    } catch (error) {
      console.error('Error during checking if outdated:', error);
    }

    return result;
  }

  async function showHistory(
    id: number,
    fromDocBrowser?: boolean,
    fromComparePage?: boolean
  ): Promise<number | undefined> {
    if (!existingWiki.value || existingWiki.value.id !== id) {
      await wikiStore.getWikiById(id);
    }

    if (!wikiStore.history || !fromComparePage) {
      const result = await wikiStore.getHistory(id);
      if (!result) {
        console.error('Failed to get history');
        //TODO: showSonnerToast(t('wiki.history.error'), false, false, undefined, 'get_wiki_history_error');
        showSonnerToast('Failed to get updated history. Please refresh the page.', false);
        return;
      }
    }

    if (!existingWiki.value) {
      const latest = await wikiStore.getHistoricalWikiByDate(id, new Date().toISOString(), WikiMajorFilterEnum.All);
      if (!latest) {
        console.error('Failed to get latest historical wiki');
        //TODO: showSonnerToast(t('wiki.history.error'), false, false, undefined, 'get_wiki_history_error');
        return;
      }
    }
    return await componentWikiHistoryModal(fromDocBrowser, fromComparePage);
  }

  //TODO: compareHistorical;

  //TODO: addTag

  //TODO: removeTag

  //TODO: getContributionsByUserId

  const getWikiTemplatesTableHeader = (): {
    field: keyof WikiTemplateModel | null;
    editable: boolean;
    headerName: string;
    cellStyle: () => any;
    flex?: number;
    valueFormatter?: (params: { value: any }) => string;
    cellRenderer?: (params: { value: string; data: WikiTemplateModel }) => any;
    width?: number;
  }[] => {
    const appStore = useAppStore();
    const isSMWidth: ComputedRef<boolean> = computed(() => appStore.isSMWidth);
    const currentUserRoleId = (): UserRoleEnum => userStore.current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;

    const _cellStyle = {
      'line-height': 1,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    };

    const _nameRenderer = (params: { value: string; data: WikiTemplateModel }) => {
      return `<span style="display:block; font-size: 0.9rem; padding-bottom: 0.4rem; font-weight: 500;">${params.value}</span><span style="display:block;padding-bottom: 0.2rem;font-size: 0.8rem">${params.data.createdBy.fullName}</span><span style="font-size: 0.8rem"> ${formatDateHelper(params.data.createdAt, 'short')}</span>`;
    };

    const _deleteRenderer = (params: { value: string; data: WikiTemplateModel }) => {
      //TODO: Replace with svg-import
      const removeIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="100%" height="100%"><path stroke-width="1" fill="var(--ion-color-dark)" fill-rule="evenodd" clip-rule="evenodd" d="M5.75 3V1.5h4.5V3h-4.5zm-1.5 0V1a1 1 0 0 1 1-1h5.5a1 1 0 0 1 1 1v2h2.5a.75.75 0 0 1 0 1.5h-.365l-.743 9.653A2 2 0 0 1 11.148 16H4.852a2 2 0 0 1-1.994-1.847L2.115 4.5H1.75a.75.75 0 0 1 0-1.5h2.5zm-.63 1.5h8.76l-.734 9.538a.5.5 0 0 1-.498.462H4.852a.5.5 0 0 1-.498-.462L3.62 4.5z"/></svg>`;

      const button = document.createElement('button');
      button.className = 'table-action-button';
      button.innerHTML = removeIcon;
      button.onclick = (event: Event) => {
        event.stopPropagation();
        _deleteTemplate(params.data);
      };
      return button;
    };

    const _deleteColumn = {
      field: null,
      width: 64,
      editable: false,
      headerName: '',
      cellRenderer: (params: { value: string; data: WikiTemplateModel }) => _deleteRenderer(params),
      cellStyle: () => ({ ..._cellStyle, justifyContent: 'end' }),
    };

    const _mobileHeader = [
      {
        field: 'name' as keyof WikiTemplateModel,
        flex: 2,
        editable: false,
        headerName: t('wiki.templates.templateName'),
        cellStyle: () => _cellStyle,
        cellRenderer: (params: { value: string; data: WikiTemplateModel }) => _nameRenderer(params),
      },
      ...(currentUserRoleId() >= UserRoleEnum.Administrator ? [_deleteColumn] : []),
    ];

    const _desktopHeader = [
      {
        field: 'name' as keyof WikiTemplateModel,
        flex: 2,
        editable: false,
        headerName: t('wiki.templates.templateName'),
        cellStyle: () => _cellStyle,
      },
      {
        field: 'createdBy' as keyof WikiTemplateModel,
        flex: 1,
        editable: false,
        headerName: t('wiki.table.author'),
        valueFormatter: (params: { value: UserShortModel }) => params.value.fullName,
        cellStyle: () => _cellStyle,
      },
      {
        field: 'createdAt' as keyof WikiTemplateModel,
        flex: 1,
        editable: false,
        headerName: t('wiki.table.date'),
        valueFormatter: (params: { value: string }) => formatDateHelper(params.value, 'short'),
        cellStyle: () => _cellStyle,
      },
      ...(currentUserRoleId() >= UserRoleEnum.Administrator ? [_deleteColumn] : []),
    ];

    return isSMWidth.value ? _desktopHeader : _mobileHeader;
  };

  const getWikiTemplatesMenuItems = (): TabCategories<WikiEditControlsEnum>[] => {
    const currentUserRoleId = (): UserRoleEnum => userStore.current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;
    return [
      {
        value: WikiEditControlsEnum.ChooseTemplate,
        active: true,
        icon: icons.templates,
        title: t('wiki.templates.choose.title'),
      },
      {
        value: WikiEditControlsEnum.SaveAsTemplate,
        active: currentUserRoleId() >= UserRoleEnum.Administrator,
        icon: icons.save,
        title: t('wiki.templates.save.title'),
      },
      {
        value: WikiEditControlsEnum.UpdateTemplate,
        active: currentUserRoleId() >= UserRoleEnum.Administrator,
        icon: icons.refresh,
        title: t('wiki.templates.update.title'),
      },
    ].filter((item) => item.active);
  };

  const getWikiCreateAccess = (groupId: number | null): boolean => {
    const currentUserRoleId = (): UserRoleEnum => userStore.current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;
    const allowPostToFeed = (): boolean => networkStore.settings?.allowPostToFeed ?? false;

    if (!groupId) {
      //NOTE: Check if user has access to create wiki in a post to feed
      return allowPostToFeed() && currentUserRoleId() >= UserRoleEnum.User;
    } else {
      //NOTE: Check if user has access to create wiki in a group
      const group = groupStore.getGroupById(groupId);
      return group.accessType >= GroupsAccessEnum.Member;
    }
  };

  return {
    getActionsMenuItems,
    openActionsMenu,
    getEditOptions,
    editOptionsAction,
    getEditControls,
    editControlsAction,
    whichActionToMake,
    openWiki,
    createWiki,
    deleteWiki,
    //TODO: addRelation
    //TODO: removeRelation
    //TODO: getHistoryById
    //TODO: getHistoryByDate
    //TODO: updateHistoricalWiki
    getHistoricalButtons,
    getHistoricalModifiedByAvatar,
    compareHistorical,
    showHistory,
    //TODO: getTemplateById
    //TODO: getTemplates
    //TODO: createFromTemplateById
    //TODO: createTemplate
    //TODO: updateTemplateById
    //TODO: deleteTemplateById
    //TODO: addTag
    //TODO: removeTag
    //TODO: getContributionsByUserId
    shouldPreventWikiEdit,
    preventSave,
    leaveEditPage,
    debouncedUpdateDraft,
    checkIfOutdated,
    goToHistoricalWiki,
    getWikiCreateAccess,
    getWikiTemplatesTableHeader,
    getWikiTemplatesMenuItems,
  };
};
