import { Clipboard } from '@capacitor/clipboard';
import type { ToastOptions, ToastButton } from '@ionic/vue';
import { toastController } from '@ionic/vue';
import type { ErrorEvent } from '@sentry/types';
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
  checkmarkOutline,
  downloadOutline,
  linkOutline,
  warningOutline,
} from 'ionicons/icons';
import { toast } from 'vue-sonner';

import { isAnyMobile } from './helper';
import { logErr, logInfo } from './logger';

import { ThemeAppEnum } from '@/enums';
import { filesHybrid, useErrors } from '@/helpers';
import { useAppStore } from '@/store';
import type { ResponseErrorModel } from '@/types';

export type IToasts = {
  /**
   * The ID of the current toast
   *
   * @readonly
   */
  currentToastId: string | undefined;
  /**
   * Displays a toast message using vue-sonner with customizable actions
   * such as downloading error details and dismissing the toast.
   */
  showSonnerToast: (
    message: string,
    isSuccess: boolean,
    identifier?: string,
    sentryDetails?: ErrorEvent | null,
    serverDetails?: ResponseErrorModel | null
  ) => void;
  dismissSonnerToast: (id?: string) => void;
  /**
   *  Displays a toast message using toastController (old-style)
   *
   * @param text - The text to display in the toast
   * @param isSuccess - Whether the toast is successful or not
   * @param notAutoDismiss - Pass true to prevent the toast from being automatically dismissed
   * @param icon - The icon to display in the toast
   * @param id - The ID of the toast
   *
   * @deprecated
   * @use showSonnerToast instead
   */
  showToast: (
    text: string,
    isSuccess: boolean,
    notAutoDismiss?: boolean,
    icon?: any | undefined,
    id?: string
  ) => Promise<void>;
  /**
   *  Displays a toast message using toastController (old-style) with a dismiss button
   *
   * @param text - The text to display in the toast
   * @param btnText - The text to display on the dismiss button
   * @param isSuccess - Whether the toast is successful or not
   * @param cancelText - The text to display on the cancel button
   *
   * @deprecated
   * @use showSonnerToast instead
   */
  showDismissingToast: (
    text: string,
    btnText: string,
    isSuccess: boolean,
    cancelText?: string
  ) => Promise<HTMLIonToastElement>;
  /**
   * Displays a toast message using toastController from ionic
   * with customizable actions such as downloading error details and dismissing the toast.
   *
   * @deprecated
   * @use showSonnerToast instead
   */
  newShowToast: (
    id: string,
    message: string,
    isSuccess: boolean,
    sentryDetails?: ErrorEvent | null,
    serverDetails?: ResponseErrorModel | null
  ) => Promise<void>;
  /**
   * Dismisses the toast with the given id (toastController created with newShowToast)
   *
   * @deprecated
   * @use dismissSonnerToast instead
   */
  dismissNewShowToast: () => Promise<void>;
};

let instance: IToasts | null = null;
export function useToasts(): IToasts {
  if (instance) return instance;

  /**
   * --------------------------------------------------------------------------------
   * Stores
   * --------------------------------------------------------------------------------
   */

  const appStore = useAppStore();

  /**
   * --------------------------------------------------------------------------------
   * Variables
   * --------------------------------------------------------------------------------
   */

  let currentToastId: string | undefined;

  /**
   * --------------------------------------------------------------------------------
   * Private methods
   * --------------------------------------------------------------------------------
   */

  // ...private methods

  /**
   * --------------------------------------------------------------------------------
   * Public methods
   * --------------------------------------------------------------------------------
   */

  const showToast = async (
    text: string,
    isSuccess: boolean,
    notAutoDismiss?: boolean,
    icon?: any | undefined,
    id?: string
  ): Promise<void> => {
    const toastObj: ToastOptions = {
      message: text,
      duration: notAutoDismiss ? 0 : 3000,
      position: 'top' as 'top' | 'bottom' | 'middle',
      cssClass: ['custom_toast', isSuccess ? 'success' : 'danger'],
      icon: icon ? icon : isSuccess ? checkmarkCircleOutline : alertCircleOutline,
    };

    if (id) {
      const existingToast = await toastController.getTop();
      if (existingToast && existingToast.id === id) {
        return;
      }

      toastObj.id = id;
    }

    const toast = await toastController.create(toastObj);
    await toast.present();
  };

  const showDismissingToast = async (
    text: string,
    btnText: string,
    isSuccess: boolean,
    cancelText?: string
  ): Promise<HTMLIonToastElement> => {
    const toast = await toastController.create({
      message: text,
      position: 'top',
      cssClass: ['custom_toast', isSuccess ? 'success' : 'fail'],
      buttons: cancelText
        ? [
            {
              text: cancelText,
              role: 'cancel',
            },
            {
              text: btnText,
              role: 'retry',
            },
          ]
        : [
            {
              text: btnText,
              role: 'retry',
            },
          ],
      layout: 'stacked',
    });
    await toast.present();

    return toast;
  };

  const newShowToast = async (
    id: string,
    message: string,
    isSuccess: boolean,
    sentryDetails?: ErrorEvent | null,
    serverDetails?: ResponseErrorModel | null
  ): Promise<void> => {
    logInfo(`Showing newShowToast with id: ${id}...`);

    toastController.getTop().then((t) => {
      if (t) {
        if (t.id === id || t.message === message) {
          t.dismiss();
        }
      }
    });

    const toastButtons: ToastButton[] = [
      {
        text: '',
        icon: linkOutline,
        role: 'link',
        side: 'end',
        handler: async () => {
          const link = sentryDetails?.event_id ?? serverDetails?.traceId ?? new Date().toISOString();
          if (isAnyMobile) {
            await Clipboard.write({ string: link });
          } else {
            navigator.clipboard.writeText(link);
          }

          await toast.dismiss();
        },
      },
      {
        text: '',
        icon: downloadOutline,
        role: 'download',
        side: 'end',
        handler: async () => {
          const content = `[${JSON.stringify(sentryDetails, null, 2)},\n${JSON.stringify(serverDetails, null, 2)}]`;
          const blob = new Blob([content], { type: 'text/json' });

          if (!isAnyMobile) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${id}.json`;
            link.click();
          } else {
            await filesHybrid.saveFile(blob, `${id}.json`, `${id}.json`, 'application/json', 'application/json');
          }

          toast.dismiss(id);
        },
      },
      {
        text: '',
        icon: closeCircleOutline,
        role: 'close',
        side: 'end',
        handler: async () => {
          await toast.dismiss();
        },
      },
    ];

    const toast = await toastController.create({
      id: id,
      mode: 'md',
      message: message,
      duration: isSuccess ? 3000 : 0,
      animated: true,
      position: 'top',
      icon: isSuccess ? checkmarkOutline : warningOutline,
      cssClass: ['custom_toast', isSuccess ? 'success' : 'fail'],
      buttons: toastButtons,
    });

    toast.addEventListener('ionToastWillPresent', async () => {
      logInfo('Moving toast to top...');
    });

    toast.onDidDismiss().then(async () => {
      logInfo(`Dismissed new toast with id: ${id}...`);
      useErrors().resetCurrentError();
    });
    await toast.present();
  };

  const dismissNewShowToast = async (): Promise<void> => {
    try {
      const { currentError, resetCurrentError } = useErrors();

      const currentToast = await toastController.getTop();
      if (currentError.id && currentToast && currentToast.id === currentError.id) {
        if (currentError.id === currentToast.id) {
          logInfo(`Dismissing newShowToast with id: ${currentError.id}...`);
          await toastController.dismiss(currentToast.id);
          resetCurrentError();
        }
      }
    } catch (e) {
      logErr('Error while dismissing current error:', e);
    }
  };

  const showSonnerToast = (
    message: string,
    isSuccess: boolean,
    identifier?: string,
    sentryDetails?: ErrorEvent | null,
    serverDetails?: ResponseErrorModel | null
  ): void => {
    const id = identifier || new Date().toISOString();
    logInfo(`Showing sonner toast with id: ${id}...`);

    try {
      const { resetCurrentError } = useErrors();

      if (message.length > 100) {
        message = message.slice(0, 97) + '...';
      }

      const themeMap: Record<ThemeAppEnum, 'light' | 'dark' | 'system'> = {
        [ThemeAppEnum.Light]: 'light',
        [ThemeAppEnum.Dark]: 'dark',
        [ThemeAppEnum.System]: 'system',
      };

      const successStyle = {
        borderColor: 'var(--ion-color-success)',
        borderStyle: 'solid',
        borderWidth: '1px',
      };

      const errorStyle = {
        borderColor: 'var(--ion-color-warning-shade)',
        borderStyle: 'solid',
        borderWidth: '1px',
      };

      const action = {
        // label: '\u2B07',
        label: 'Download',
        onClick: async () => {
          const content = `[${JSON.stringify(sentryDetails, null, 2)},\n${JSON.stringify(serverDetails, null, 2)}]`;
          const blob = new Blob([content], { type: 'text/json' });

          if (!isAnyMobile) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${id}.json`;
            link.click();
          } else {
            await filesHybrid.saveFile(blob, `${id}.json`, `${id}.json`, 'application/json', 'application/json');
          }

          toast.dismiss(id);
          resetCurrentError();
        },
      };

      // Define the toast configuration
      const config = {
        id,
        theme: themeMap[appStore.getLocalTheme],
        position: 'bottom-left' as
          | 'top-left'
          | 'top-right'
          | 'bottom-left'
          | 'bottom-right'
          | 'top-center'
          | 'bottom-center',
        visibleToasts: 5,
        duration: isSuccess ? 3000 : 15000, //Infinity,
        closeButton: isSuccess ? false : true,
        richColors: true,
        style: {
          fontSize: '1rem',
          color: 'var(--ion-color-dark)',
          marginTop: 'calc(52px + (var(--app-lg-padding) / 2))',
          borderRadius: 'var(--app-md-radius)',
          background: 'var(--ion-color-light-background-contrast)',
          maxWidth: '750px',
          minWidth: '350px',
          maxHeight: '100px',
          whiteSpace: 'wrap',
          ...(isSuccess ? successStyle : errorStyle),
        },
        action: isSuccess ? undefined : action,
        onDismiss: () => {
          logInfo(`Dismissed sonner toast with id: ${id}...`);
          resetCurrentError();
        },
      };

      // Show a new toast with custom settings
      isSuccess ? toast.success(message, config) : toast.error(message, config);
      currentToastId = id;
    } catch (e) {
      logErr('Error showing sonner toast:', e);
    }
  };

  /**
   * Dismisses the toast with the given id (vue-sonner created with showSonnerToast)
   *
   * @param id
   */
  const dismissSonnerToast = (id?: string): void => {
    try {
      const { currentError, resetCurrentError } = useErrors();

      if (id && currentToastId && currentToastId === id) {
        logInfo(`Dismissing sonner toast with id: ${id}...`);
        toast.dismiss(id);

        const currentErrorId = currentError?.id;
        if (currentErrorId && currentErrorId === id) {
          resetCurrentError();
        }
      }
    } catch (e) {
      logErr('Error dismissing newShowToast:', e);
    }
  };

  instance = {
    get currentToastId(): string | undefined {
      return currentToastId;
    },
    showSonnerToast,
    showDismissingToast,
    newShowToast,
    dismissNewShowToast,
    showToast,
    dismissSonnerToast,
  };

  return instance;
}
