import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/vue';
import { HubConnectionState } from '@microsoft/signalr';
import { useCssVar } from '@vueuse/core/index';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';

import {
  FeedFilterTypeEnum,
  SendKeyEnum,
  ThemeAppEnum,
  InterfaceSizeAppEnum,
  TopicsFilterEnum,
  PagesBackgroundEnum,
  GroupsFilterEnum,
  UsersFilterEnum,
  PostsFilterEnum,
  DataViewMode,
  IdeaTypeEnum,
  PagesFilterEnum,
  UIKitFilterEnum,
  NotificationsFilterEnum,
} from '@/enums';
import { isWebMobile, isNativeMobile, useLayoutHelper } from '@/helpers';
import { logInfo } from '@/helpers/logger';
import { DEFAULT_LOCALE, SUPPORT_LOCALES } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { $api } from '@/services';
import { handleSessionTerminate } from '@/services/axios';
import type {
  ErrorMessageModel,
  ResponseAuthCoreTokenModel,
  ResponseErrorModel,
  ResponseNetworkResourcesModel,
  WebSocketModel,
  RequestAuthHomeUser,
  ResponseAuthUserTokenModel,
} from '@/types';

export type AppState = {
  urlCore: string;
  appVersion: string;
  appBuild: string;
  grantType: string;

  code: string;
  codeValid: string;
  homeRowId: string;

  accessToken: string;
  /** @deprecated The param should not be used */
  expiresIn: string;
  tokenValid: string;
  locale: string;

  sendKey: SendKeyEnum;
  interfaceSize: InterfaceSizeAppEnum;
  theme: ThemeAppEnum;
  errors: ErrorMessageModel[];
  isLoading: boolean;
  isWaitingForCompleteLogin: boolean;
  feedType: FeedFilterTypeEnum;
  ideaType: IdeaTypeEnum;
  feedFilter: PostsFilterEnum;
  notificationsFilter: NotificationsFilterEnum;
  localNotifications: boolean;
  coreId: string;
  companyRowId: string;
  userId: number | null;
  userRowId: string;
  webSocket: string;
  canPostOnBehalfMe: boolean;

  appHeaderHeight: number;
  appBottomMenuHeight: number;

  companyResources: object[];
  onlineUsers: number[];
  homePage: { name: string; id?: number };
  appBottomMenu: boolean;
  feedPostsCount: number;

  tagsType: TopicsFilterEnum;
  isKeyboardShown: boolean;
  pagesBackground: PagesBackgroundEnum;

  groupsFilter: GroupsFilterEnum;
  groupsViewMode: DataViewMode;
  usersFilter: UsersFilterEnum;
  usersViewMode: DataViewMode;
  networksViewMode: DataViewMode;
  pagesViewMode: DataViewMode;
  pagesFilter: PagesFilterEnum;

  anyLongPressIsActive: boolean;
  showLoaderOnHeader: boolean;

  isXSWidth: boolean;
  is2XSWidth: boolean;
  isSMWidth: boolean;
  isMDWidth: boolean;
  isLGWidth: boolean;
  isXLWidth: boolean;
  is2XLWidth: boolean;
  is3XLWidth: boolean;
  is4XLWidth: boolean;
  appGridPadding: string;
  maxItemsInHeaderMenu: number;

  searchText: string;
  searchHistory: string[];

  appColors: string[];
  isMiddleButtonClicked: boolean;
  UIKitFilter: UIKitFilterEnum;

  fromMobilePush: boolean;
  signalRConnectionId: string;
  signalRConnectionStatus: HubConnectionState;

  redirectUrl: string | null;
};

export const useAppStore = defineStore({
  id: 'app',
  state: (): AppState => ({
    urlCore: import.meta.env.VITE_APP_URL_CORE,
    appVersion: APP_VERSION,
    appBuild: APP_BUILD,
    grantType: '',
    code: '',
    codeValid: '',
    homeRowId: '',
    accessToken: '',
    expiresIn: '',
    tokenValid: '',
    coreId: '',
    companyRowId: '',
    userId: null,
    userRowId: '',
    canPostOnBehalfMe: false,
    webSocket: import.meta.env.VITE_APP_URL_SIGNALR,
    errors: [],
    isLoading: false,
    isWaitingForCompleteLogin: false,
    feedType: FeedFilterTypeEnum.Recommended,
    ideaType: IdeaTypeEnum.New,
    feedFilter: PostsFilterEnum.All,
    notificationsFilter: NotificationsFilterEnum.All,
    localNotifications: true,
    locale: SUPPORT_LOCALES.includes(window.navigator.language.substring(0, 2).toLowerCase())
      ? window.navigator.language.substring(0, 2).toLowerCase()
      : DEFAULT_LOCALE,
    sendKey: SendKeyEnum.CtrlEnter,
    interfaceSize: InterfaceSizeAppEnum.Middle,
    appHeaderHeight: 0,
    appBottomMenuHeight: 0,
    theme: window.matchMedia?.('(prefers-color-scheme: dark)').matches ? ThemeAppEnum.Dark : ThemeAppEnum.Light,
    companyResources: [],
    homePage: { name: ROUTES_NAME.FEED },
    groupsFilter: GroupsFilterEnum.MyGroups,
    groupsViewMode: DataViewMode.Grid,
    usersFilter: UsersFilterEnum.UsersPage,
    usersViewMode: DataViewMode.Grid,
    networksViewMode: DataViewMode.List,
    pagesViewMode: DataViewMode.List,
    pagesFilter: PagesFilterEnum.All,
    onlineUsers: [],
    appBottomMenu: isWebMobile || isNativeMobile,
    feedPostsCount: 20,
    tagsType: TopicsFilterEnum.Popular,
    isKeyboardShown: false,
    pagesBackground: PagesBackgroundEnum.CustomImage,
    anyLongPressIsActive: false,
    showLoaderOnHeader: false,

    isXSWidth: useLayoutHelper().isXSWidth(),
    is2XSWidth: useLayoutHelper().is2XSWidth(),
    isSMWidth: useLayoutHelper().isSMWidth(),
    isMDWidth: useLayoutHelper().isMDWidth(),
    isLGWidth: useLayoutHelper().isLGWidth(),
    isXLWidth: useLayoutHelper().isXLWidth(),
    is2XLWidth: useLayoutHelper().is2XLWidth(),
    is3XLWidth: useLayoutHelper().is3XLWidth(),
    is4XLWidth: useLayoutHelper().is4XLWidth(),
    appGridPadding: useCssVar('--app-grid-padding').value,
    maxItemsInHeaderMenu: 8,

    searchText: '',
    searchHistory: [],

    appColors: [],
    isMiddleButtonClicked: false,
    UIKitFilter: UIKitFilterEnum.Intra,

    fromMobilePush: false,
    signalRConnectionId: '',
    signalRConnectionStatus: HubConnectionState.Disconnected,

    redirectUrl: null,
  }),
  getters: {
    isDarkTheme:
      () =>
      (theme: ThemeAppEnum): boolean => {
        switch (theme) {
          case ThemeAppEnum.Light:
            return false;

          case ThemeAppEnum.Dark:
            return true;

          case ThemeAppEnum.System: {
            return window.matchMedia?.('(prefers-color-scheme: dark)').matches ? true : false;
          }
        }
      },
    isPhone(): boolean {
      const isNativePhone =
        Capacitor.isNativePlatform() && (isPlatform('iphone') || (isPlatform('android') && !isPlatform('tablet')));
      return isNativePhone || !this.isSMWidth;
    },
    getTheme: (state): ThemeAppEnum => state.theme,
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getWebSocketModel(state): WebSocketModel | null {
      if (state.urlCore === null || state.userId === null || state.webSocket.length === 0) {
        return null;
      }

      return {
        baseUrl: state.urlCore,
        userId: state.userId,
        coreId: state.coreId,
        companyRowId: state.companyRowId,
        userRowId: state.userRowId,
        webSocket: state.webSocket,
      };
    },
    getLocalSendKey(state): SendKeyEnum {
      return state.sendKey;
    },
    getLocalInterfaceSize(state): InterfaceSizeAppEnum {
      return state.interfaceSize;
    },
    getLocalTheme(state): ThemeAppEnum {
      return state.theme;
    },
    getLocalPagesBackground(state): PagesBackgroundEnum {
      return state.pagesBackground;
    },
    getAppLogoPath(state): string {
      const logo = !this.isDarkTheme(state.theme)
        ? import.meta.env.VITE_APP_LOGO_LIGHT
        : import.meta.env.VITE_APP_LOGO_DARK;
      return `/logo/${logo}`;
    },
    getAppLogoPathWithColor:
      () =>
      (color: ThemeAppEnum): string => {
        const appStore = useAppStore();
        const logo = !appStore.isDarkTheme(color)
          ? import.meta.env.VITE_APP_LOGO_LIGHT
          : import.meta.env.VITE_APP_LOGO_DARK;
        return `/logo/${logo}`;
      },
    getAppIconPath(state): string {
      const icon = !this.isDarkTheme(state.theme)
        ? import.meta.env.VITE_APP_ICON_LIGHT
        : import.meta.env.VITE_APP_ICON_DARK;
      return `/icon/${icon}`;
    },
    getAppIconPathWithColor:
      () =>
      (color: ThemeAppEnum): string => {
        const appStore = useAppStore();
        const icon = !appStore.isDarkTheme(color)
          ? import.meta.env.VITE_APP_ICON_DARK
          : import.meta.env.VITE_APP_ICON_LIGHT;
        return `/icon/${icon}`;
      },
    getBeginChatImagePath(): string {
      const appStore = useAppStore();
      const isSMWidth = appStore.isSMWidth;
      const image = isSMWidth ? import.meta.env.VITE_CHAT_IMAGE_WEB : import.meta.env.VITE_CHAT_IMAGE_MOBILE;
      return `/images/chat/${image}`;
    },
    getBeginChatBackgroundPath(state): string {
      const image = !this.isDarkTheme(state.theme)
        ? import.meta.env.VITE_CHAT_BACKGROUND_DARK
        : import.meta.env.VITE_CHAT_BACKGROUND_LIGHT;
      return `/images/chat/${image}`;
    },
    getAppPatternPath(state): string {
      const image = !this.isDarkTheme(state.theme)
        ? import.meta.env.VITE_APP_PATTERN_DARK
        : import.meta.env.VITE_APP_PATTERN_LIGHT;
      return `/images/${image}`;
    },
    getAppNotificationSoundPath(): string {
      return `/audio/${import.meta.env.VITE_APP_NOTIFICATION_SOUND}`;
    },
    getAppCallSoundPath(): string {
      return `/audio/${import.meta.env.VITE_APP_CALL_SOUND}`;
    },
    getLocalFeedType(state): FeedFilterTypeEnum {
      return state.feedType;
    },
    getLocalIdeaType(state): IdeaTypeEnum {
      return state.ideaType;
    },
  },
  actions: {
    isAuth(): boolean {
      const hasRequiredFields = (): boolean =>
        this.grantType.length > 0 &&
        this.code.length > 0 &&
        this.accessToken.length > 0 &&
        this.companyRowId.length > 0 &&
        this.homeRowId.length > 0 &&
        this.coreId.length > 0 &&
        this.userId !== null &&
        this.userId > 0;

      const isCoreUrlValid = (): boolean => this.urlCore !== import.meta.env.VITE_APP_URL_CORE;

      return hasRequiredFields() && !this.isCodeExpired() && !this.isTokenExpired() && isCoreUrlValid();
    },

    isCodeExpired(): boolean {
      return Date.parse(this.codeValid) < Date.now() || !this.code;
    },

    isTokenExpired(): boolean {
      return Date.parse(this.tokenValid) < Date.now() || !this.accessToken;
    },
    setServiceUrl(url: string) {
      this.urlCore = url;
    },
    setRedirectUrl(url: string | null) {
      this.redirectUrl = url;
    },
    setKeyboardShown(state: boolean) {
      this.isKeyboardShown = state;
    },
    setNetwork(url: string, companyRowId: string, webSocket: string, isWaitingForCompleteLogin: boolean) {
      this.urlCore = url;
      this.companyRowId = companyRowId;
      this.webSocket = webSocket;
      this.isWaitingForCompleteLogin = isWaitingForCompleteLogin;
    },
    setShowLoaderOnHeader(state: boolean) {
      this.showLoaderOnHeader = state;
    },
    toggleTheme(theme: ThemeAppEnum) {
      switch (theme) {
        case ThemeAppEnum.Light:
          {
            this.$patch({
              theme: ThemeAppEnum.Light,
            });
            document.body.classList.remove('dark');
          }
          break;

        case ThemeAppEnum.Dark:
          {
            this.$patch({
              theme: ThemeAppEnum.Dark,
            });
            document.body.classList.add('dark');
          }
          break;

        case ThemeAppEnum.System:
          {
            const preparedTheme = window.matchMedia?.('(prefers-color-scheme: dark)').matches
              ? ThemeAppEnum.Dark
              : ThemeAppEnum.Light;
            this.$patch({
              theme: ThemeAppEnum.System,
            });
            preparedTheme === ThemeAppEnum.Dark
              ? document.body.classList.add('dark')
              : document.body.classList.remove('dark');
          }
          break;

        default:
          break;
      }
    },
    toggleBackground(background: PagesBackgroundEnum) {
      switch (background) {
        case PagesBackgroundEnum.CustomImage:
          this.$patch({
            pagesBackground: PagesBackgroundEnum.CustomImage,
          });
          break;

        case PagesBackgroundEnum.Default:
          this.$patch({
            pagesBackground: PagesBackgroundEnum.Default,
          });
          break;

        default:
          break;
      }
    },
    usersOnline(data: number[]) {
      this.onlineUsers = data;
    },
    userConnected(userId: number) {
      const index = this.onlineUsers.findIndex((u: number) => u === userId);
      if (index < 0) {
        this.onlineUsers.push(userId);
      }
    },
    userDisconnected(userId: number) {
      this.onlineUsers = this.onlineUsers.filter((u: number) => u !== userId);
    },
    async getCompanyResources(): Promise<void> {
      const response = await $api.network.getResources();

      if (response.statusCode === 200) {
        const model = response as ResponseNetworkResourcesModel;
        this.companyResources = cloneDeep(model.resources);
        return model.resources;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return undefined;
    },
    async homeCode(data: RequestAuthHomeUser): Promise<boolean> {
      this.$patch({
        errors: [],
      });
      const response = await $api.auth.homeCode(data);

      if (response.statusCode === 200) {
        const model = response as ResponseAuthCoreTokenModel;
        const { accessToken, validUntil, coreId, companyRowId, userId, userRowId } = model.data;

        this.$patch((state) => {
          state.grantType = 'homeAuthorizationCode';
          state.code = accessToken; //NOTE: Long-lived access token
          state.codeValid = validUntil; //NOTE: Long-lived access token expiration date
          state.homeRowId = companyRowId; //NOTE: Network Row ID
          state.coreId = coreId; //NOTE: ???
          state.companyRowId = companyRowId; //NOTE: Network Row ID
          state.userId = userId; //NOTE: User ID
          state.userRowId = userRowId; //NOTE: User Row ID
        });

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async token(force = false, silent = false): Promise<void> {
      const clientSecret: string = isPlatform('ios')
        ? import.meta.env.VITE_AXIOS_CLIENT_SECRET_IOS
        : import.meta.env.VITE_AXIOS_CLIENT_SECRET_WEB;

      if (!this.isTokenExpired() && !force) {
        logInfo('token: accessToken is OK'); //! DEBUG
        return;
      }

      try {
        if (this.isCodeExpired()) {
          logInfo('token: code is NOT OK - is expired'); //! DEBUG
          throw new Error('Code is expired');
        }

        if (this.urlCore === import.meta.env.VITE_APP_URL_CORE) {
          logInfo('token: urlCore is NOT OK - is default = import.meta.env.VITE_APP_URL_CORE'); //! DEBUG
          throw new Error('urlCore is default');
        }

        logInfo('Fetching new token...'); //! DEBUG
        this.isLoading = true;

        //! Mocking the brocken urlCore
        // const mockUrlCore = this.urlCore + 'test';
        // logInfo('Mocking urlCore:', mockUrlCore); //! DEBUG
        // const mockUrl = `${mockUrlCore}${import.meta.env.VITE_APP_URL_API}/oauth/token`;
        //! Mocking the brocken urlCore

        const url = `${this.urlCore}${import.meta.env.VITE_APP_URL_API}/oauth/token`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            AppHost: window.location.hostname,
          },
          body: JSON.stringify({
            clientSecret: clientSecret,
            grantType: this.grantType,
            code: this.code,
            uid: this.homeRowId,
          }),
        });

        if (!response.ok) {
          const error = (await response.json()) as ResponseErrorModel;
          this.errors = error.errorMessages;
          throw new Error('Token request failed');
        }

        const responseToken = await response.json();
        const model = responseToken as ResponseAuthUserTokenModel;
        const { validUntil, accessToken, companyRowId } = model.data;

        //! Mocking validUntil = now + 1 minutes
        // const offset = 60 * 1000;
        // const mockValidUntil = new Date(Date.now() + offset).toISOString();
        // logInfo('Mocking validUntil:', mockValidUntil); //! DEBUG
        // this.tokenValid = mockValidUntil;
        // setTimeout(() => {
        //   logInfo('!!!Token expired'); //! DEBUG
        // }, offset);
        //! Mocking validUntil = now + 1 minutes

        this.tokenValid = validUntil;
        this.accessToken = accessToken;
        this.companyRowId = companyRowId;
      } catch (error) {
        await handleSessionTerminate(error, 'Error while fetching token', silent);
      } finally {
        this.isLoading = false;
      }
    },
  },
  persist: true,
});
