import type { OverlayEventDetail } from '@ionic/core';
import { modalController } from '@ionic/vue';
import type { Route } from '@sentry/vue/build/types/router';
import {
  personOutline,
  pricetagOutline,
  attachOutline,
  calendarOutline,
  timeOutline,
  arrowForward,
  copyOutline,
  lockClosedOutline,
  archiveOutline,
  shareSocialOutline,
  trashOutline,
  lockOpenOutline,
  expandOutline,
  listOutline,
  layersOutline,
  createOutline,
  swapVerticalOutline,
  searchOutline,
  textOutline,
  peopleOutline,
  medicalOutline,
  arrowUpOutline,
  arrowDownOutline,
  notificationsOutline,
  notificationsOffOutline,
  optionsOutline,
  refreshOutline,
  eyeOutline,
} from 'ionicons/icons';
import { filter } from 'lodash';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import type { Router } from 'vue-router';
import { useRoute } from 'vue-router';

import {
  componentPostCreateMobile,
  componentTaskManagementAttachmentsModal,
  componentTaskManagementMilestonesModal,
  componentTaskManagementProjectSwitchModal,
} from './modalComponents';
import {
  componentTaskManagementAttachmentsPopover,
  componentAppDatePickerPopover,
  componentTaskManagementMilestonesPopover,
  componentTaskManagementNewColumnPopover,
  componentTaskManagementProjectSwitchPopover,
  componentTaskManagementViewModePopover,
  userListPopover,
  componentTaskManagementMainHeaderSortingPopover,
  taskManagementAssignPopover,
  componentTaskManagementStatusFilterPopover,
} from './popoverComponents';

import {
  PostTypeActionEnum,
  TaskManagementMainHeaderMenuEnum,
  TaskManagementMainHeaderSearchModesEnum,
  TaskManagementMilestonesSortByEnum,
  TaskManagementMilestonesViewModeEnum,
  TaskManagementProjectsSortByEnum,
  TaskManagementTaskModalMenuCategoryEnum,
  TaskManagementTaskModalMenuItemActionEnum,
  TaskManagementTasksSortByEnum,
  TaskManagementViewModeEnum,
  TaskManagementActiveSortTypeEnum,
  TaskManagementSortDirectionEnum,
  taskManagementAccessLevelEnum,
  UserRoleEnum,
  TaskManagementTaskHistoryActionType,
  NotificationsPushActionsEnum,
  DefaultUserItemTypesEnum,
  TaskManagementTasksStatusFilterEnum,
} from '@/enums';
import { useTags, useToasts } from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useAppStore, useGroupsStore, useNetworkStore, useProjectsStore, useUserStore } from '@/store';
import type {
  TaskManagementTaskModel,
  TaskManagementMilestoneModel,
  TaskManagementModalMenuItemModel,
  TaskManagementTaskModalMenuModel,
  ProjectEntity,
} from '@/types';

export type IUseTaskManagement = {
  getTaskMainMenu(): TaskManagementTaskModalMenuModel[];
  getTaskMenuFeatures(): TaskManagementTaskModalMenuModel;
  getTaskMenuActions(
    withActions: boolean,
    taskIsClosed: boolean,
    taskIsArchived: boolean,
    taskNotify: boolean | null,
    userIsAssigned: boolean,
    projectId: number
  ): TaskManagementTaskModalMenuModel;
  getTaskModalMenuItems(
    withActions: boolean,
    taskIsClosed: boolean,
    taskIsArchived: boolean,
    taskNotify: boolean | null,
    userIsAssigned: boolean,
    projectId: number
  ): TaskManagementTaskModalMenuModel[];
  taskManagementMilestonesHelper(
    ev: Event,
    cardData: TaskManagementTaskModel | null,
    milestoneData: TaskManagementMilestoneModel | null,
    projectId: number,
    onlyCreate: boolean
  ): Promise<OverlayEventDetail<TaskManagementMilestoneModel | undefined>>;
  menuSelectionHandling(
    ev: Event,
    value: TaskManagementTaskModalMenuItemActionEnum,
    taskData: TaskManagementTaskModel,
    projectId: number
  ): Promise<boolean>;
  taskManagementProjectSwitchHelper(
    ev: Event,
    isPostCreation: boolean,
    withRouting: boolean,
    isOnlyProjectCreation: boolean,
    selectedGroupId?: number | undefined
  ): Promise<void>;
  getMainHeaderMenu(
    showProjectControls: boolean,
    showBoardControls: boolean,
    showMilestonesControls: boolean
  ): TaskManagementModalMenuItemModel[];
  mainHeaderMenuSelectionHandling(
    ev: Event,
    value: TaskManagementMainHeaderMenuEnum,
    router: Router,
    projectId: number
  ): Promise<boolean>;
  getMainHeaderSearchModes(): TaskManagementModalMenuItemModel[];
  getTasksSortingModes(): TaskManagementModalMenuItemModel[];
  getMilestonesSortingModes(): TaskManagementModalMenuItemModel[];
  getProjectsSortingModes(): TaskManagementModalMenuItemModel[];
  taskManagementMainHeaderSearchHelper(searchQuery: string, projectId: number, route: Route): Promise<void>;
  accessToCreateProject(groupId: number): boolean;
  accessToCreateMilestone(projectId: number): boolean;
  accessToDeleteTask(projectId: number): boolean;
  getSortTitle(
    sort: TaskManagementTasksSortByEnum | TaskManagementMilestonesSortByEnum | TaskManagementProjectsSortByEnum
  ): string;
  getSelectedSort():
    | TaskManagementTasksSortByEnum
    | TaskManagementMilestonesSortByEnum
    | TaskManagementProjectsSortByEnum;
  getSelectedSortDirection(): TaskManagementSortDirectionEnum;
  getAccessToTaskManagement(): boolean;
  loadProject(projectId: number): Promise<void>;
  loadTasks(projectId: number): Promise<void>;
  refresh(projectId: number): Promise<void>;
  setSortDirection(): void;
  getHistoryTitle(action: TaskManagementTaskHistoryActionType | NotificationsPushActionsEnum): string;
  getAccessToCreateTask(): boolean;
  getSelectedStatusFilter(): TaskManagementTasksStatusFilterEnum | TaskManagementMilestonesViewModeEnum;
  getSelectedStatusFilterTitle(
    status: TaskManagementTasksStatusFilterEnum | TaskManagementMilestonesViewModeEnum
  ): string;
  getTasksStatusFilters(): TaskManagementModalMenuItemModel[];
  getViewModeIcon(viewMode: TaskManagementViewModeEnum): string;
};

export function useTaskManagement(): IUseTaskManagement {
  const icons = {
    assignee: personOutline,
    participants: peopleOutline,
    tags: pricetagOutline,
    attach: attachOutline,
    calendar: calendarOutline,
    time: timeOutline,
    move: arrowForward,
    copy: copyOutline,
    lockClosed: lockClosedOutline,
    lockOpen: lockOpenOutline,
    archive: archiveOutline,
    share: shareSocialOutline,
    trash: trashOutline,
    expand: expandOutline,
    list: listOutline,
    layers: layersOutline,
    create: createOutline,
    swap: swapVerticalOutline,
    search: searchOutline,
    name: textOutline,
    medical: medicalOutline,
    arrowUp: arrowUpOutline,
    arrowDown: arrowDownOutline,
    notifications: notificationsOutline,
    notificationsOff: notificationsOffOutline,
    options: optionsOutline,
    refresh: refreshOutline,
    eye: eyeOutline,
  };

  const getTaskMainMenu = (): TaskManagementTaskModalMenuModel[] => {
    const { t } = useI18n();

    const data = [
      {
        title: t('taskManagement.tasks.addToTask'),
        value: TaskManagementTaskModalMenuCategoryEnum.Features,
        data: [],
      },
      {
        title: t('actions'),
        value: TaskManagementTaskModalMenuCategoryEnum.Actions,
        data: [],
      },
    ] as TaskManagementTaskModalMenuModel[];

    return data;
  };

  const getTaskMenuFeatures = (): TaskManagementTaskModalMenuModel => {
    const { t } = useI18n();

    const data: TaskManagementModalMenuItemModel[] = [
      {
        title: t('appPopoverMenu.taskManagementMenu.assign'),
        icon: icons.assignee,
        enabled: true,
        value: TaskManagementTaskModalMenuItemActionEnum.Assigne,
      },
      {
        title: t('taskManagement.participants'),
        icon: icons.participants,
        enabled: true,
        value: TaskManagementTaskModalMenuItemActionEnum.Participants,
      },
      {
        title: t('taskManagement.tags.title'),
        icon: icons.tags,
        enabled: true,
        value: TaskManagementTaskModalMenuItemActionEnum.Tag,
      },
      {
        title: t('appPopoverMenu.taskManagementMenu.dueDate'),
        icon: icons.time,
        enabled: true,
        value: TaskManagementTaskModalMenuItemActionEnum.Date,
      },
      {
        title: t('appPopoverMenu.taskManagementMenu.attachments'),
        icon: icons.attach,
        enabled: true,
        value: TaskManagementTaskModalMenuItemActionEnum.Attachments,
      },
      {
        title: t('taskManagement.milestones.title'),
        icon: icons.calendar,
        enabled: true,
        value: TaskManagementTaskModalMenuItemActionEnum.Milestone,
      },
    ];

    const features = {
      title: t('taskManagement.tasks.addToTask'),
      value: TaskManagementTaskModalMenuCategoryEnum.Features,
      data: filter(data, (n) => n.enabled),
    } as TaskManagementTaskModalMenuModel;

    return features;
  };

  const getTaskMenuActions = (
    withActions: boolean,
    taskIsClosed: boolean,
    taskIsArchived: boolean,
    taskNotify: boolean | null,
    userIsAssigned: boolean,
    projectId: number
  ) => {
    const { t } = useI18n();
    const data: TaskManagementModalMenuItemModel[] = [
      /* {
        title: t('appPopoverMenu.move.title'),
        icon: icons.move,
        enabled: true,
        value: TaskManagementTaskModalMenuItemActionEnum.Move,
      }, */
      /* {
        title: t('appPopoverMenu.copy.title'),
        icon: icons.copy,
        enabled: true,
        value: TaskManagementTaskModalMenuItemActionEnum.Copy,
      }, */

      /*NOTE: Now located in the TaskManagementAssignPopover component
      {
        title: t('taskManagement.tasks.removeAssignee'),
        icon: icons.assignee,
        enabled: userIsAssigned,
        value: TaskManagementTaskModalMenuItemActionEnum.RemoveAssignee,
      }, */
      {
        title: taskIsClosed
          ? t('appPopoverMenu.taskManagementMenu.open')
          : t('appPopoverMenu.taskManagementMenu.close'),
        icon: taskIsClosed ? icons.lockOpen : icons.lockClosed,
        enabled: true,
        value: TaskManagementTaskModalMenuItemActionEnum.Close,
      },
      //NOTE: #1352 Temporary disabled
      /* {
        title: taskIsArchived
          ? t('taskManagement.tasks.dearchive')
          : t('taskManagement.tasks.archive'),
        icon: icons.archive,
        enabled: true,
        value: TaskManagementTaskModalMenuItemActionEnum.Archive,
      }, */
      {
        title: taskNotify
          ? t('taskManagement.tasks.notificationsEnabled')
          : t('taskManagement.tasks.notificationsDisabled'),
        icon: taskNotify ? icons.notifications : icons.notificationsOff,
        enabled: taskNotify !== null,
        value: TaskManagementTaskModalMenuItemActionEnum.Notifications,
      },
      /* {
        title: t('appPopoverMenu.share.title'),
        icon: icons.share,
        disabled: false,
        value: TaskManagementTaskModalMenuItemActionEnum.Share,
      }, */
      {
        title: t('appPopoverMenu.delete.title'),
        icon: icons.trash,
        enabled: accessToDeleteTask(projectId),
        value: TaskManagementTaskModalMenuItemActionEnum.Delete,
      },
    ];

    const actions = {
      title: t('actions'),
      value: TaskManagementTaskModalMenuCategoryEnum.Actions,
      data: filter(data, (n) => n.enabled),
    } as TaskManagementTaskModalMenuModel;

    return actions;
  };

  const getTaskModalMenuItems = (
    withActions: boolean,
    taskIsClosed: boolean,
    taskIsArchived: boolean,
    taskNotify: boolean | null,
    userIsAssigned: boolean,
    projectId: number
  ): TaskManagementTaskModalMenuModel[] => {
    const features = getTaskMenuFeatures();

    if (withActions) {
      const actions = getTaskMenuActions(
        withActions,
        taskIsClosed,
        taskIsArchived,
        taskNotify,
        userIsAssigned,
        projectId
      );
      return [features, actions];
    } else {
      return [features];
    }
  };

  const menuSelectionHandling = async (
    ev: Event,
    value: TaskManagementTaskModalMenuItemActionEnum,
    taskData: TaskManagementTaskModel,
    projectId: number
  ): Promise<boolean> => {
    const { t } = useI18n();
    const { showSonnerToast } = useToasts();

    const projectsStore = useProjectsStore();
    const taskManagementHelper = useTaskManagement();
    const tagsHelper = useTags();
    switch (value) {
      case TaskManagementTaskModalMenuItemActionEnum.Assigne:
        {
          const project = projectsStore.getProjectById(projectId);
          if (project?.group.id) {
            const result = await taskManagementAssignPopover(ev, project.group.id, taskData);
            console.log(result?.data);
            if (result?.data === null) {
              await projectsStore.taskRemoveAssignee(taskData.id);
            } else if (result.data) {
              await projectsStore.taskSetAssignee(taskData.id, result.data);
            }
          }
        }
        break;

      case TaskManagementTaskModalMenuItemActionEnum.Participants:
        {
          const project = projectsStore.getProjectById(projectId);
          await userListPopover(ev, [], true, project?.group.id, taskData);
        }
        break;

      case TaskManagementTaskModalMenuItemActionEnum.Tag:
        {
          const result = await tagsHelper.tagsPicker(ev, taskData.tags.data, true, true);
          if (result) {
            const addedTags = result.filter(
              (tag) => !taskData.tags.data.some((existingTag) => existingTag.id === tag.id)
            );
            const removedTags = taskData.tags.data.filter(
              (existingTag) => !result.some((tag) => tag.id === existingTag.id)
            );

            console.log('Added Tags:', addedTags);
            console.log('Removed Tags:', removedTags);

            if (addedTags.length > 0) {
              await projectsStore.addTagToTask(taskData.id, addedTags);
            }

            if (removedTags.length > 0) {
              await projectsStore.removeTagFromTask(taskData.id, removedTags);
            }
          }
        }
        break;

      case TaskManagementTaskModalMenuItemActionEnum.Date:
        {
          const result = await componentAppDatePickerPopover(ev, taskData.dateDue, true);
          if (result.data !== undefined) {
            result.data
              ? await projectsStore.updateTaskDueDate(taskData.id, result.data)
              : await projectsStore.updateTaskDueDate(taskData.id, null);
          }
        }
        break;

      case TaskManagementTaskModalMenuItemActionEnum.Attachments:
        await taskManagementAttachmentsHelper(ev, taskData);
        break;

      case TaskManagementTaskModalMenuItemActionEnum.Milestone:
        await taskManagementMilestonesHelper(ev, taskData, null, projectId, false);
        break;

      case TaskManagementTaskModalMenuItemActionEnum.Move:
        break;

      case TaskManagementTaskModalMenuItemActionEnum.Copy:
        break;

      /* case TaskManagementTaskModalMenuItemActionEnum.RemoveAssignee:
        await projectsStore.taskRemoveAssignee(taskData.id);
        break; */

      case TaskManagementTaskModalMenuItemActionEnum.Close:
        await projectsStore.updateTaskStatus(taskData.id);
        await modalController.getTop().then((modal) => {
          return modal ? modalController.dismiss() : null;
        });
        await taskManagementHelper.refresh(Number(projectId));
        break;

      case TaskManagementTaskModalMenuItemActionEnum.Archive:
        {
          if (taskData.isArchived) {
            const result = await projectsStore.taskDearchive(taskData.id);
            if (result) {
              showSonnerToast(t('taskManagement.history.actions.dearchived'), true);
            }
          } else {
            const result = await projectsStore.taskArchive(taskData.id);
            if (result) {
              showSonnerToast(t('taskManagement.history.actions.archived'), true);
            }
          }
        }
        break;

      case TaskManagementTaskModalMenuItemActionEnum.Notifications:
        {
          if (taskData?.notify !== null) {
            taskData.notify
              ? await projectsStore.taskDisableNotifications(taskData.id)
              : await projectsStore.taskEnableNotifications(taskData.id);
          }
        }
        break;

      case TaskManagementTaskModalMenuItemActionEnum.Share:
        break;

      case TaskManagementTaskModalMenuItemActionEnum.Delete:
        {
          /* await router.replace({ query: undefined }); */
          await modalController.getTop().then((modal) => {
            return modal ? modalController.dismiss() : null;
          });
          await projectsStore.deleteTask(taskData.id);
        }

        break;

      default:
        break;
    }
    return true;
  };

  const taskManagementMilestonesHelper = async (
    ev: Event,
    cardData: TaskManagementTaskModel | null,
    milestoneData: TaskManagementMilestoneModel | null,
    projectId: number,
    onlyCreate: boolean
  ): Promise<OverlayEventDetail<TaskManagementMilestoneModel | undefined>> => {
    const appStore = useAppStore();
    if (appStore.isMDWidth) {
      return await componentTaskManagementMilestonesPopover(ev, cardData, milestoneData, projectId, onlyCreate);
    } else {
      return await componentTaskManagementMilestonesModal(cardData, milestoneData, projectId, onlyCreate);
    }
  };

  const taskManagementAttachmentsHelper = async (ev: Event, taskData: TaskManagementTaskModel): Promise<void> => {
    const appStore = useAppStore();
    if (appStore.isMDWidth) {
      return await componentTaskManagementAttachmentsPopover(ev, taskData.id);
    } else {
      return await componentTaskManagementAttachmentsModal(taskData.id);
    }
  };

  const taskManagementProjectSwitchHelper = async (
    ev: Event,
    isPostCreation: boolean,
    withRouting: boolean,
    isOnlyProjectCreation: boolean,
    selectedGroupId?: number | undefined
  ): Promise<void> => {
    const appStore = useAppStore();
    if (appStore.isMDWidth) {
      await componentTaskManagementProjectSwitchPopover(
        ev,
        isPostCreation,
        isOnlyProjectCreation,
        withRouting,
        selectedGroupId
      );
    } else {
      await componentTaskManagementProjectSwitchModal(
        isPostCreation,
        isOnlyProjectCreation,
        withRouting,
        selectedGroupId
      );
    }
  };

  const getViewModeIcon = (viewMode: TaskManagementViewModeEnum): string => {
    return viewMode === TaskManagementViewModeEnum.Board ? icons.layers : icons.list;
  };

  const getMainHeaderMenu = (
    showProjectControls: boolean,
    showBoardControls: boolean,
    showMilestonesControls: boolean
  ): TaskManagementModalMenuItemModel[] => {
    const { t } = useI18n();
    const route = useRoute();
    const projectsStore = useProjectsStore();
    const appStore = useAppStore();
    const viewMode: ComputedRef<TaskManagementViewModeEnum> = computed(() => projectsStore.viewMode);
    const milestonesViewMode: ComputedRef<TaskManagementMilestonesViewModeEnum> = computed(
      () => projectsStore.statusFilter.milestones
    );
    const isMDWidth: ComputedRef<boolean> = computed(() => appStore.isMDWidth);
    const items = [] as TaskManagementModalMenuItemModel[];

    if (showProjectControls) {
      items.push({
        title: t('taskManagement.projects.selectProject'),
        icon: icons.swap,
        enabled: true,
        value: TaskManagementMainHeaderMenuEnum.SwitchProjects,
      });
    }

    if (showBoardControls) {
      items.push(
        {
          title: t('taskManagement.projects.switchProjectViewMode'),
          icon: getViewModeIcon(viewMode.value),
          enabled: !isMDWidth.value,
          value: TaskManagementMainHeaderMenuEnum.SwitchViewMode,
        },
        {
          title: t('taskManagement.projects.expand'),
          icon: icons.expand,
          enabled: route.name !== ROUTES_NAME.PROJECT_BY_ID,
          value: TaskManagementMainHeaderMenuEnum.Expand,
        },
        {
          title: t('taskManagement.columns.create'),
          icon: icons.create,
          enabled: viewMode.value === TaskManagementViewModeEnum.Board,
          value: TaskManagementMainHeaderMenuEnum.CreateColumn,
        },
        {
          title: t('taskManagement.tasks.create'),
          icon: icons.create,
          enabled: getAccessToCreateTask(),
          value: TaskManagementMainHeaderMenuEnum.CreateTask,
        },
        {
          title: t('taskManagement.status.title'),
          icon: icons.eye,
          enabled: viewMode.value !== TaskManagementViewModeEnum.Board,
          value: TaskManagementMainHeaderMenuEnum.ChangeStatusFilter,
          subtitle: getSelectedStatusFilterTitle(getSelectedStatusFilter()),
        },
        {
          title: getSortTitle(getSelectedSort()),

          icon: icons.options,
          enabled: viewMode.value !== TaskManagementViewModeEnum.Board && !isMDWidth.value,
          value: TaskManagementMainHeaderMenuEnum.OpenSortingMenu,
        },
        {
          title:
            getSelectedSortDirection() === TaskManagementSortDirectionEnum.Asc
              ? t('sorting.direction.asc')
              : t('sorting.direction.desc'),
          icon: getSelectedSortDirection() === TaskManagementSortDirectionEnum.Asc ? icons.arrowUp : icons.arrowDown,
          enabled: viewMode.value !== TaskManagementViewModeEnum.Board && !isMDWidth.value,
          value: TaskManagementMainHeaderMenuEnum.SetSort,
        },
        {
          title: t('taskManagement.tasks.refresh'),
          icon: icons.refresh,
          enabled: true,
          value: TaskManagementMainHeaderMenuEnum.Refresh,
        }

        /* {
          title: t('taskManagement.board.edit'),
          icon: icons.create,
          disabled: viewMode.value !== TaskManagementViewModeEnum.Board,
          value: TaskManagementMainHeaderMenuEnum.EditBoard,
        } */
      );
    }

    if (showMilestonesControls) {
      items.push(
        {
          title:
            milestonesViewMode.value === TaskManagementMilestonesViewModeEnum.Closed
              ? t('taskManagement.status.showOnlyOpened')
              : t('taskManagement.status.showOnlyClosed'),
          icon:
            milestonesViewMode.value === TaskManagementMilestonesViewModeEnum.Closed
              ? icons.lockOpen
              : icons.lockClosed,
          enabled: true,
          value: TaskManagementMainHeaderMenuEnum.SwitchMilestonesViewMode,
        },
        {
          title: t('taskManagement.milestones.create'),
          icon: icons.create,
          enabled: true,
          value: TaskManagementMainHeaderMenuEnum.CreateMilestone,
        }
      );
    }

    return filter(items, (item) => item.enabled);
  };

  const mainHeaderMenuSelectionHandling = async (
    ev: Event,
    value: TaskManagementMainHeaderMenuEnum,
    router: Router,
    projectId: number
  ): Promise<boolean> => {
    const projectsStore = useProjectsStore();
    const milestonesViewMode: ComputedRef<TaskManagementMilestonesViewModeEnum> = computed(
      () => projectsStore.statusFilter.milestones
    );
    const currentProject: ComputedRef<ProjectEntity> = computed(() => projectsStore.getCurrentProject);
    switch (value) {
      case TaskManagementMainHeaderMenuEnum.SwitchProjects:
        taskManagementProjectSwitchHelper(ev, false, true, false, undefined);
        break;

      case TaskManagementMainHeaderMenuEnum.SwitchViewMode:
        await componentTaskManagementViewModePopover(ev);
        break;

      case TaskManagementMainHeaderMenuEnum.Expand:
        {
          await router.push({
            name: ROUTES_NAME.PROJECT_BY_ID,
            params: { projectId: currentProject.value.id },
          });
        }
        break;

      case TaskManagementMainHeaderMenuEnum.CreateColumn:
        await componentTaskManagementNewColumnPopover(ev, currentProject.value);
        break;

      case TaskManagementMainHeaderMenuEnum.CreateTask:
        await componentPostCreateMobile(PostTypeActionEnum.Task);
        break;

      case TaskManagementMainHeaderMenuEnum.Refresh:
        await refresh(Number(projectId));
        break;

      case TaskManagementMainHeaderMenuEnum.OpenSortingMenu:
        await componentTaskManagementMainHeaderSortingPopover(ev);
        break;

      case TaskManagementMainHeaderMenuEnum.EditBoard:
        break;

      case TaskManagementMainHeaderMenuEnum.SwitchMilestonesViewMode:
        await changeMilestonesViewMode(
          ev,
          milestonesViewMode.value === TaskManagementMilestonesViewModeEnum.Opened
            ? TaskManagementMilestonesViewModeEnum.Closed
            : TaskManagementMilestonesViewModeEnum.Opened
        );
        break;

      case TaskManagementMainHeaderMenuEnum.CreateMilestone:
        await taskManagementMilestonesHelper(ev, null, null, projectId, true);
        break;

      case TaskManagementMainHeaderMenuEnum.SetSort:
        setSortDirection();
        break;

      case TaskManagementMainHeaderMenuEnum.ChangeStatusFilter:
        await componentTaskManagementStatusFilterPopover(ev);
        break;

      default:
        break;
    }
    return true;
  };

  const changeMilestonesViewMode = async (ev: Event, mode: TaskManagementMilestonesViewModeEnum) => {
    const projectsStore = useProjectsStore();
    projectsStore.$patch((state) => {
      state.statusFilter.milestones = mode;
    });
  };

  const getMainHeaderSearchModes = (): TaskManagementModalMenuItemModel[] => {
    const { t } = useI18n();
    const data = [
      /* {
        title: t('files.table.title'),
        icon: icons.name,
        enabled: true,
        value: TaskManagementMainHeaderSearchModesEnum.Name,
      }, */
      {
        title: t('appPopoverMenu.taskManagementMenu.assignee'),
        icon: icons.assignee,
        enabled: true,
        value: TaskManagementMainHeaderSearchModesEnum.Assigne,
      },
      {
        title: t('taskManagement.author'),
        icon: icons.assignee,
        enabled: true,
        value: TaskManagementMainHeaderSearchModesEnum.Author,
      },
      {
        title: t('taskManagement.tags.title'),
        icon: icons.tags,
        enabled: true,
        value: TaskManagementMainHeaderSearchModesEnum.Tag,
      },
      {
        title: t('taskManagement.milestones.title'),
        icon: icons.calendar,
        enabled: true,
        value: TaskManagementMainHeaderSearchModesEnum.Milestone,
      },
    ] as TaskManagementModalMenuItemModel[];
    return data;
  };

  const getTasksSortingModes = (): TaskManagementModalMenuItemModel[] => {
    const data = [
      {
        title: getSortTitle(TaskManagementTasksSortByEnum.Identifier),
        icon: '',
        enabled: true,
        value: TaskManagementTasksSortByEnum.Identifier,
      },
      {
        title: getSortTitle(TaskManagementTasksSortByEnum.Title),
        icon: '',
        enabled: true,
        value: TaskManagementTasksSortByEnum.Title,
      },
      {
        title: getSortTitle(TaskManagementTasksSortByEnum.DateCreated),
        icon: '',
        enabled: true,
        value: TaskManagementTasksSortByEnum.DateCreated,
      },
      /* {
        title: getSortTitle(TaskManagementTasksSortByEnum.DateStart),
        icon: '',
        disabled: false,
        value: TaskManagementTasksSortByEnum.DateStart,
      }, */
      {
        title: getSortTitle(TaskManagementTasksSortByEnum.DateDue),
        icon: '',
        enabled: true,
        value: TaskManagementTasksSortByEnum.DateDue,
      },
      {
        title: getSortTitle(TaskManagementTasksSortByEnum.DateLastUpdated),
        icon: '',
        enabled: true,
        value: TaskManagementTasksSortByEnum.DateLastUpdated,
      },
      {
        title: getSortTitle(TaskManagementTasksSortByEnum.MilestoneDateDue),
        icon: '',
        enabled: true,
        value: TaskManagementTasksSortByEnum.MilestoneDateDue,
      },
    ] as TaskManagementModalMenuItemModel[];
    return data;
  };

  const getMilestonesSortingModes = (): TaskManagementModalMenuItemModel[] => {
    const data = [
      {
        title: getSortTitle(TaskManagementMilestonesSortByEnum.Title),
        icon: '',
        enabled: true,
        value: TaskManagementMilestonesSortByEnum.Title,
      },
      {
        title: getSortTitle(TaskManagementMilestonesSortByEnum.DateCreated),
        icon: '',
        enabled: true,
        value: TaskManagementMilestonesSortByEnum.DateCreated,
      },
      {
        title: getSortTitle(TaskManagementMilestonesSortByEnum.DateStart),
        icon: '',
        enabled: true,
        value: TaskManagementMilestonesSortByEnum.DateStart,
      },
      {
        title: getSortTitle(TaskManagementMilestonesSortByEnum.DateDue),
        icon: '',
        enabled: true,
        value: TaskManagementMilestonesSortByEnum.DateDue,
      },
    ] as TaskManagementModalMenuItemModel[];
    return data;
  };

  const getProjectsSortingModes = (): TaskManagementModalMenuItemModel[] => {
    const data = [
      {
        title: getSortTitle(TaskManagementProjectsSortByEnum.Title),
        icon: '',
        enabled: true,
        value: TaskManagementProjectsSortByEnum.Title,
      },
      {
        title: getSortTitle(TaskManagementProjectsSortByEnum.DateCreated),
        icon: '',
        enabled: true,
        value: TaskManagementProjectsSortByEnum.DateCreated,
      },
      {
        title: getSortTitle(TaskManagementProjectsSortByEnum.DateDue),
        icon: '',
        enabled: true,
        value: TaskManagementProjectsSortByEnum.DateDue,
      },
      {
        title: getSortTitle(TaskManagementProjectsSortByEnum.GroupTitle),
        icon: '',
        enabled: true,
        value: TaskManagementProjectsSortByEnum.GroupTitle,
      },
    ] as TaskManagementModalMenuItemModel[];
    return data;
  };

  const getSortTitle = (
    sort: TaskManagementTasksSortByEnum | TaskManagementMilestonesSortByEnum | TaskManagementProjectsSortByEnum
  ) => {
    const { t } = useI18n();
    switch (sort) {
      case TaskManagementTasksSortByEnum.Identifier:
        return t('sorting.byIdentifier');

      case TaskManagementTasksSortByEnum.Title:
      case TaskManagementProjectsSortByEnum.Title:
      case TaskManagementMilestonesSortByEnum.Title:
        return t('sorting.byTitle');

      case TaskManagementTasksSortByEnum.DateCreated:
      case TaskManagementProjectsSortByEnum.DateCreated:
      case TaskManagementMilestonesSortByEnum.DateCreated:
        return t('sorting.byDateCreated');

      case TaskManagementTasksSortByEnum.DateStart:
      case TaskManagementMilestonesSortByEnum.DateStart:
        return t('sorting.byDateStart');

      case TaskManagementTasksSortByEnum.DateDue:
      case TaskManagementProjectsSortByEnum.DateDue:
      case TaskManagementMilestonesSortByEnum.DateDue:
        return t('sorting.byDateDue');

      case TaskManagementTasksSortByEnum.DateLastUpdated:
        return t('sorting.byDateUpdate');

      case TaskManagementTasksSortByEnum.MilestoneDateDue:
        return t('sorting.byMilestoneDateDue');

      case TaskManagementProjectsSortByEnum.GroupTitle:
        return t('sorting.byGroupTitle');

      default:
        return t('sorting.byIdentifier');
    }
  };

  const taskManagementMainHeaderSearchHelper = async (
    searchQuery: string,
    projectId: number,
    route: Route
  ): Promise<void> => {
    const projectsStore = useProjectsStore();
    const viewMode = projectsStore.viewMode;
    if (
      route.name === ROUTES_NAME.PROJECT_BY_ID ||
      route.name === ROUTES_NAME.GROUP_BY_ID ||
      route.name === ROUTES_NAME.TASKS
    ) {
      if (viewMode === TaskManagementViewModeEnum.Board) {
        projectsStore.tasksSearchByAllColumns(searchQuery);
      } else {
        projectsStore.searchByTask(searchQuery, projectId);
      }
    }

    if (route.name === ROUTES_NAME.MILESTONES) {
      projectsStore.searchByMilestone(searchQuery, projectId);
    }
  };

  // Разрешено ли пользователю управление проектом
  // Можно только если он админ группы, в которой находится проект
  const accessToCreateProject = (groupId: number): boolean => {
    const userStore = useUserStore();
    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(groupId);
    const currentUserId = userStore.current?.id ?? 0;

    return group ? group.adminIds.includes(currentUserId) : false;
  };

  // Разрешено ли пользователю управление майлстоунами проекта
  // Можно только если он админ группы, в которой находится проект
  const accessToCreateMilestone = (projectId: number): boolean => {
    const userStore = useUserStore();
    const groupStore = useGroupsStore();
    const projectsStore = useProjectsStore();

    const project = projectsStore.getProjectById(projectId);
    if (project) {
      const currentUserId = userStore.current?.id ?? 0;
      const group = groupStore.getGroupById(project.group.id);
      return group ? group.adminIds.includes(currentUserId) : false;
    }

    return false;
  };

  // Разрешено ли пользователю удалить задачу
  // Можно только если это создатель проекта
  const accessToDeleteTask = (projectId: number): boolean => {
    const projectsStore = useProjectsStore();
    const userStore = useUserStore();
    const project = projectsStore.getProjectById(projectId);
    const currentUserId = userStore.current?.id ?? 0;
    return project?.author?.id === currentUserId || false;
  };

  // Разрешено ли создавать задачи в сети в целом
  const getAccessToCreateTask = (): boolean => {
    const networkStore = useNetworkStore();
    return (
      getAccessToTaskManagement() &&
      (networkStore.settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Task) ?? false)
    );
  };

  const getSelectedSort = ():
    | TaskManagementTasksSortByEnum
    | TaskManagementMilestonesSortByEnum
    | TaskManagementProjectsSortByEnum => {
    const projectsStore = useProjectsStore();
    const activeSortType = projectsStore.sorting.activeSortType;

    switch (activeSortType) {
      case TaskManagementActiveSortTypeEnum.Tasks:
        return projectsStore.sorting.tasks.sortBy;

      case TaskManagementActiveSortTypeEnum.Milestones:
        return projectsStore.sorting.milestones.sortBy;

      case TaskManagementActiveSortTypeEnum.Projects:
        return projectsStore.sorting.projects.sortBy;

      default:
        return projectsStore.sorting.tasks.sortBy;
    }
  };

  const getSelectedSortDirection = (): TaskManagementSortDirectionEnum => {
    const projectsStore = useProjectsStore();
    const activeSortType = projectsStore.sorting.activeSortType;

    switch (activeSortType) {
      case TaskManagementActiveSortTypeEnum.Tasks:
        return projectsStore.sorting.tasks.direction;

      case TaskManagementActiveSortTypeEnum.Milestones:
        return projectsStore.sorting.milestones.direction;

      case TaskManagementActiveSortTypeEnum.Projects:
        return projectsStore.sorting.projects.direction;

      default:
        return projectsStore.sorting.tasks.direction;
    }
  };

  const setSortDirection = (): void => {
    const projectsStore = useProjectsStore();

    const activeSortType = projectsStore.sorting.activeSortType;
    const selectedSortDirection = getSelectedSortDirection();

    // Tasks
    if (activeSortType === TaskManagementActiveSortTypeEnum.Tasks) {
      projectsStore.$patch((state) => {
        state.sorting.tasks.direction =
          selectedSortDirection === TaskManagementSortDirectionEnum.Asc
            ? TaskManagementSortDirectionEnum.Desc
            : TaskManagementSortDirectionEnum.Asc;
      });
    }

    // Milestones
    if (activeSortType === TaskManagementActiveSortTypeEnum.Milestones) {
      projectsStore.$patch((state) => {
        state.sorting.milestones.direction =
          selectedSortDirection === TaskManagementSortDirectionEnum.Asc
            ? TaskManagementSortDirectionEnum.Desc
            : TaskManagementSortDirectionEnum.Asc;
      });
    }

    // Projects
    if (activeSortType === TaskManagementActiveSortTypeEnum.Projects) {
      projectsStore.$patch((state) => {
        state.sorting.projects.direction =
          selectedSortDirection === TaskManagementSortDirectionEnum.Asc
            ? TaskManagementSortDirectionEnum.Desc
            : TaskManagementSortDirectionEnum.Asc;
      });
    }
  };

  /* Включен ли taskManagement */
  const getAccessToTaskManagement = (): boolean => {
    const networkStore = useNetworkStore();
    const userStore = useUserStore();
    const currentUserRoleId: UserRoleEnum = userStore.current?.roleId ?? 0;

    const taskManagementAccessLevel =
      networkStore.settings?.taskManagementAccessLevel ?? taskManagementAccessLevelEnum.None;

    switch (taskManagementAccessLevel) {
      case taskManagementAccessLevelEnum.None:
        return false;

      case taskManagementAccessLevelEnum.User:
        return true;

      case taskManagementAccessLevelEnum.Administrator:
        return currentUserRoleId >= UserRoleEnum.Administrator ? true : false;

      default:
        return false;
    }
  };

  const loadProject = async (projectId: number): Promise<void> => {
    const projectsStore = useProjectsStore();
    const currentProject = projectsStore.getCurrentProject;

    await projectsStore.projectById(projectId);
    if (currentProject.id !== projectId) await projectsStore.switchProject(projectId);
  };

  const loadTasks = async (projectId: number): Promise<void> => {
    const projectsStore = useProjectsStore();

    const viewMode = projectsStore.viewMode;
    const searchQuery = projectsStore.getTasksSearchQuery;

    viewMode !== TaskManagementViewModeEnum.Board && searchQuery.length === 0
      ? await projectsStore.tasksByProjectId(projectId)
      : false;
  };

  const refresh = async (projectId: number): Promise<void> => {
    const projectsStore = useProjectsStore();
    const viewMode = projectsStore.viewMode;

    await loadProject(projectId);
    await loadTasks(projectId);

    if (viewMode === TaskManagementViewModeEnum.Board) {
      await projectsStore.tasksByAllColumns();
    }
  };

  const getHistoryTitle = (action: TaskManagementTaskHistoryActionType | NotificationsPushActionsEnum): string => {
    const { t } = useI18n();
    switch (action) {
      case TaskManagementTaskHistoryActionType.Created:
      case NotificationsPushActionsEnum.TaskCreated:
        return t('taskManagement.history.actions.created');
      case TaskManagementTaskHistoryActionType.Closed:
      case NotificationsPushActionsEnum.TaskClosed:
        return t('taskManagement.history.actions.closed');
      case TaskManagementTaskHistoryActionType.Reopened:
      case NotificationsPushActionsEnum.TaskReopened:
        return t('taskManagement.history.actions.reopened');
      case TaskManagementTaskHistoryActionType.Archived:
      case NotificationsPushActionsEnum.TaskArchived:
        return t('taskManagement.history.actions.archived');
      case TaskManagementTaskHistoryActionType.Dearchived:
      case NotificationsPushActionsEnum.TaskDeArchived:
        return t('taskManagement.history.actions.dearchived');
      case TaskManagementTaskHistoryActionType.TitleUpdated:
      case NotificationsPushActionsEnum.TaskTitleUpdated:
        return t('taskManagement.history.actions.titleUpdated');
      case TaskManagementTaskHistoryActionType.DescriptionUpdated:
      case NotificationsPushActionsEnum.TaskDescriptionUpdated:
        return t('description.updated');
      case TaskManagementTaskHistoryActionType.AssigneeChanged:
      case NotificationsPushActionsEnum.TaskAssigneeChanged:
        return t('taskManagement.history.actions.assigneeChanged');
      case TaskManagementTaskHistoryActionType.MilestoneChanged:
      case NotificationsPushActionsEnum.TaskMilestoneChanged:
        return t('taskManagement.history.actions.milestoneChanged');
      case TaskManagementTaskHistoryActionType.DateStartUpdated:
      case NotificationsPushActionsEnum.TaskDateStartUpdated:
        return t('taskManagement.history.actions.dateStartUpdated');
      case TaskManagementTaskHistoryActionType.DateDueUpdated:
      case NotificationsPushActionsEnum.TaskDateDueUpdated:
        return t('taskManagement.history.actions.dateDueUpdated');
      case TaskManagementTaskHistoryActionType.TagsAdded:
      case NotificationsPushActionsEnum.TaskTagsAdded:
        return t('taskManagement.history.actions.tagsAdded');
      case TaskManagementTaskHistoryActionType.TagsRemoved:
      case NotificationsPushActionsEnum.TaskTagsRemoved:
        return t('taskManagement.history.actions.tagsRemoved');
      case TaskManagementTaskHistoryActionType.FilesAdded:
      case NotificationsPushActionsEnum.TaskFilesAdded:
        return t('taskManagement.history.actions.filesAdded');
      case TaskManagementTaskHistoryActionType.FilesRemoved:
      case NotificationsPushActionsEnum.TaskFilesRemoved:
        return t('taskManagement.history.actions.filesRemoved');
      case TaskManagementTaskHistoryActionType.WikiesAdded:
      case NotificationsPushActionsEnum.TaskWikisAdded:
        return t('taskManagement.history.actions.wikiesAdded');
      case TaskManagementTaskHistoryActionType.WikiesRemoved:
      case NotificationsPushActionsEnum.TaskWikisRemoved:
        return t('taskManagement.history.actions.wikiesRemoved');
      case TaskManagementTaskHistoryActionType.LinksAdded:
      case NotificationsPushActionsEnum.TaskLinksAdded:
        return t('taskManagement.history.actions.linksAdded');
      case TaskManagementTaskHistoryActionType.LinksRemoved:
      case NotificationsPushActionsEnum.TaskLinksRemoved:
        return t('taskManagement.history.actions.linksRemoved');
      case TaskManagementTaskHistoryActionType.ParticipantsAdded:
      case NotificationsPushActionsEnum.TaskParticipantsAdded:
        return t('taskManagement.history.actions.participantsAdded');
      case TaskManagementTaskHistoryActionType.ParticipantsRemoved:
      case NotificationsPushActionsEnum.TaskParticipantsRemoved:
        return t('taskManagement.history.actions.participantsRemoved');
      case TaskManagementTaskHistoryActionType.CommentCreated:
      case NotificationsPushActionsEnum.TaskCommentCreated:
        return t('taskManagement.history.actions.commentCreated');
      case TaskManagementTaskHistoryActionType.CommentUpdated:
      case NotificationsPushActionsEnum.TaskCommentUpdated:
        return t('taskManagement.history.actions.commentUpdated');
      case TaskManagementTaskHistoryActionType.CommentDeleted:
      case NotificationsPushActionsEnum.TaskCommentDeleted:
        return t('taskManagement.history.actions.commentDeleted');
      case TaskManagementTaskHistoryActionType.ReporterChanged:
      case NotificationsPushActionsEnum.TaskReporterChanged:
        return t('taskManagement.history.actions.reporterChanged');
      default:
        return '';
    }
  };

  const getSelectedStatusFilter = (): TaskManagementTasksStatusFilterEnum | TaskManagementMilestonesViewModeEnum => {
    const projectsStore = useProjectsStore();
    const activeType = projectsStore.statusFilter.activeType;

    switch (activeType) {
      case TaskManagementActiveSortTypeEnum.Tasks:
        return projectsStore.statusFilter.tasks;

      case TaskManagementActiveSortTypeEnum.Milestones:
        return projectsStore.statusFilter.milestones;

      default:
        return projectsStore.statusFilter.tasks;
    }
  };

  const getSelectedStatusFilterTitle = (
    status: TaskManagementTasksStatusFilterEnum | TaskManagementMilestonesViewModeEnum
  ): string => {
    const { t } = useI18n();
    switch (status) {
      case TaskManagementTasksStatusFilterEnum.All:
        return t('taskManagement.status.showAll');

      case TaskManagementTasksStatusFilterEnum.Open:
      case TaskManagementMilestonesViewModeEnum.Opened:
        return t('taskManagement.status.showOnlyOpened');

      case TaskManagementTasksStatusFilterEnum.Closed:
      case TaskManagementMilestonesViewModeEnum.Closed:
        return t('taskManagement.status.showOnlyClosed');
    }
  };

  const getTasksStatusFilters = (): TaskManagementModalMenuItemModel[] => {
    const data = [
      {
        title: getSelectedStatusFilterTitle(TaskManagementTasksStatusFilterEnum.All),
        icon: '',
        enabled: true,
        value: TaskManagementTasksStatusFilterEnum.All,
      },
      {
        title: getSelectedStatusFilterTitle(TaskManagementTasksStatusFilterEnum.Open),
        icon: '',
        enabled: true,
        value: TaskManagementTasksStatusFilterEnum.Open,
      },
      {
        title: getSelectedStatusFilterTitle(TaskManagementTasksStatusFilterEnum.Closed),
        icon: '',
        enabled: true,
        value: TaskManagementTasksStatusFilterEnum.Closed,
      },
    ] as TaskManagementModalMenuItemModel[];
    return data;
  };

  return {
    getTaskMainMenu,
    getTaskMenuFeatures,
    getTaskMenuActions,
    getTaskModalMenuItems,
    taskManagementMilestonesHelper,
    menuSelectionHandling,
    taskManagementProjectSwitchHelper,
    getMainHeaderMenu,
    mainHeaderMenuSelectionHandling,
    getMainHeaderSearchModes,
    getTasksSortingModes,
    getMilestonesSortingModes,
    getProjectsSortingModes,
    taskManagementMainHeaderSearchHelper,
    accessToCreateProject,
    accessToDeleteTask,
    getSortTitle,
    getSelectedSort,
    getSelectedSortDirection,
    getAccessToTaskManagement,
    loadProject,
    loadTasks,
    refresh,
    setSortDirection,
    getHistoryTitle,
    getAccessToCreateTask,
    accessToCreateMilestone,
    getSelectedStatusFilter,
    getSelectedStatusFilterTitle,
    getTasksStatusFilters,
    getViewModeIcon,
  };
}
