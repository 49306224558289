<template>
  <ion-button mode="md" size="default" fill="clear" class="menu" @click.stop="openCommentMenu($event)">
    <icons-provider slot="icon-only" :icon-props="{ width: '16', height: '16' }" name="menuDots" />
  </ion-button>
</template>

<script lang="ts" setup>
import { Clipboard } from '@capacitor/clipboard';
import { IonButton } from '@ionic/vue';
import { computed } from 'vue';
import type { PropType, ComputedRef } from 'vue';
import 'swiper/css';

import { IconsProvider } from '@/components';
import { FeedFlagEnum, CommentActionEnum, FeedFilterTypeEnum } from '@/enums';
import {
  isNativeMobile,
  htmlToText,
  commentContextMenu,
  isAnyMobile,
  commentContextMenuSheet,
  useToasts,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { usePostStore, useProjectsStore } from '@/store';
import type { CommentsDataModel, TaskManagementTaskCommentDataModel } from '@/types';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// Helpers
const { t } = useI18n();
const { showSonnerToast } = useToasts();

// Store
const postStore = usePostStore();
const projectsStore = useProjectsStore();

const props = defineProps({
  postCommentData: {
    type: Object as PropType<CommentsDataModel | undefined>,
    default: undefined,
  },
  taskCommentData: {
    type: Object as PropType<TaskManagementTaskCommentDataModel | undefined>,
    default: undefined,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    default: FeedFlagEnum.FeedPage,
  },
  feedType: {
    type: String as PropType<FeedFilterTypeEnum | null>,
    default: FeedFilterTypeEnum.AllPublic,
  },
  currentUserId: {
    type: Number,
    required: true,
  },
  postId: {
    type: Number,
    default: 0,
  },
  taskId: {
    type: Number,
    default: 0,
  },
});

const clipboard = Clipboard;

// Если пост
const postCommentData: ComputedRef<CommentsDataModel | undefined> = computed(() => props.postCommentData);

//Если задача
const taskCommentData: ComputedRef<TaskManagementTaskCommentDataModel | undefined> = computed(
  () => props.taskCommentData
);

const openCommentMenu = async (ev: Event) => {
  let result;

  if (postCommentData.value) {
    if (isAnyMobile) {
      result = await commentContextMenuSheet(
        postCommentData.value.author.id,
        props.currentUserId,
        postCommentData.value.isEditable
      );
    } else {
      result = await commentContextMenu(
        postCommentData.value.author.id,
        props.currentUserId,
        postCommentData.value.isEditable,
        ev
      );
    }
  } else if (taskCommentData.value) {
    if (isAnyMobile) {
      result = await commentContextMenuSheet(taskCommentData.value.author.id, props.currentUserId, true);
    } else {
      result = await commentContextMenu(taskCommentData.value.author.id, props.currentUserId, true, ev);
    }
  } else {
    result = undefined;
  }

  if (result) {
    switch (result.data) {
      case CommentActionEnum.Reply:
        break;

      case CommentActionEnum.Delete: {
        const commentDelete = postCommentData.value
          ? await postStore.commentDelete(postCommentData.value.id, props.postId)
          : taskCommentData.value
            ? await projectsStore.taskCommentDelete(taskCommentData.value)
            : false;
        if (!commentDelete) {
          showSonnerToast(t('appPopoverMenu.commentMenu.commentNotDeleted'), false);
        }
        break;
      }

      case CommentActionEnum.CopyText: {
        const text = htmlToText(
          postCommentData.value
            ? postCommentData.value.bodyHtml
            : taskCommentData.value
              ? taskCommentData.value.text
              : ''
        );
        if (isNativeMobile) {
          await clipboard.write({
            string: text,
          });
        } else {
          await navigator.clipboard.writeText(text);
        }

        showSonnerToast(t('appPopoverMenu.copy.textCopied'), true);
        break;
      }

      default:
        break;
    }
  }
};
</script>

<style scoped lang="scss">
ion-button.menu {
  @include resetStyleFromIonicButton;
  --color: var(--ion-color-medium);
  --background-hover: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --ripple-color: transparent;
  --padding-end: 0;
  --padding-start: 0;
  position: absolute;
  margin: 0;
  right: 0;
  top: 0;
  z-index: 1;

  ion-icon {
    font-size: 1.1rem;
  }

  &:hover {
    ion-icon {
      opacity: 0.7;
    }
  }
}
</style>
