import { isPlatform } from '@ionic/vue';

import type { NetworkSettingsTypeEnum } from '@/enums';
import axios from '@/services/axios';
import type {
  ResponseErrorModel,
  ResponseNetworkModel,
  ResponseNetworkFullSettingsModel,
  ResponseNetworkResourcesModel,
  NetworkLocateModel,
  ResponseNetworkLocateModel,
  NetworkUploadLogoResponseModel,
  NetworkSettingsModel,
  NetworkMobileAppsModel,
  NetworkBrandingModel,
  ResponseNetworkMobileAppsModel,
  ResponseNetworkBrandingModel,
  ResponseNetworkDesignModel,
  NetworkDesignModel,
  NetworkUploadFaviconResponseModel,
  NetworkEmailFooterModel,
  ResponseNetworkEmailFooterModel,
  ResponseAllowedEmailSuffixesModel,
} from '@/types';

export class NetworksApiService {
  clientSecret: string = isPlatform('ios')
    ? import.meta.env.VITE_AXIOS_CLIENT_SECRET_IOS
    : import.meta.env.VITE_AXIOS_CLIENT_SECRET_WEB;

  api = import.meta.env.VITE_APP_URL_API;
  env = import.meta.env.VITE_APP_ENV; // qa / beta / prod
  /**
   * @todo - add version to the request
   * const version = import.meta.env.VITE_APP_API;
   */

  async getLocate(uri: string): Promise<NetworkLocateModel | undefined> {
    let settings: NetworkLocateModel | undefined = undefined;
    if (!uri) {
      console.error('uri is required');
      return settings;
    }

    /**
     * @todo - add version to the request
     * const url = `${uri}${this.api}/networks/locate/?clientSecret=${this.clientSecret}&env=${this.env}&version=${version}`;
     */
    const url = `${uri}${this.api}/networks/locate/?clientSecret=${this.clientSecret}&env=${this.env}`;
    await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        AppHost: window.location.hostname,
      },
    })
      .then(async (response: Response) => {
        if (response.ok) {
          await response.json().then((responseToken: ResponseNetworkLocateModel) => {
            settings = responseToken.data;
          });
        } else {
          console.log(response.status);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });

    return settings;
  }

  async settings(): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/?clientSecret=${this.clientSecret}`);
  }

  async current(): Promise<ResponseNetworkModel | ResponseErrorModel> {
    return axios.get(`/networks/current/?clientSecret=${this.clientSecret}`);
  }

  async getResources(): Promise<ResponseNetworkResourcesModel | ResponseErrorModel> {
    return axios.get(`/networks/resources?clientSecret=${this.clientSecret}&mobile=true`);
  }

  async uploadLogo(key: string, type: string): Promise<NetworkUploadLogoResponseModel | ResponseErrorModel> {
    return axios.post('/networks/logo', {
      key: key,
      type: type,
    });
  }

  async uploadFavicon(key: string, type: string): Promise<NetworkUploadFaviconResponseModel | ResponseErrorModel> {
    return axios.post('/networks/favicon', {
      key: key,
      type: type,
    });
  }

  async saveNetworkSettings(
    data: NetworkSettingsModel | NetworkBrandingModel | NetworkMobileAppsModel | NetworkDesignModel,
    type: NetworkSettingsTypeEnum
  ): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
    return axios.post(`/networks/settings/${type}`, data);
  }

  /* async currentNetworkSettings(): Promise<
    ResponseNetworkFullSettingsModel | ResponseErrorModel
  > {
    return axios.get(`/networks/settings/all`);
  } */

  async networkSettings(): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/all`);
  }

  async mobileAppsSettings(): Promise<ResponseNetworkMobileAppsModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/mobileapps`);
  }

  async brandingSettings(): Promise<ResponseNetworkBrandingModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/branding`);
  }

  async designSettings(): Promise<ResponseNetworkDesignModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/style`);
  }

  async emailFooterSettings(): Promise<ResponseNetworkEmailFooterModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/emailFooter`);
  }

  async saveEmailFooter(
    body: NetworkEmailFooterModel['emailFooter']
  ): Promise<ResponseNetworkEmailFooterModel | ResponseErrorModel> {
    return axios.post(`/networks/settings/emailFooter`, { body });
  }

  async allowedEmailSuffixes(): Promise<ResponseAllowedEmailSuffixesModel | ResponseErrorModel> {
    return axios.get(`/networks/allowedEmailSuffixes`);
  }
}
